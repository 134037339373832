export const DateParser = {
  ISO8601ToMMDDYY(v) {
    if (!v) return '';
    const d = v.substr(0, 10).split('-');
    return `${d[1]} / ${d[2]} / ${d[0].substr(2)}`;
  },
};

let roundNumber = function (number,decimalPlaces) {
  // console.log(`function RoundNumber: ${number}`);
  const factor = Math.pow(10, decimalPlaces);
  let v = Math.round(number * factor) / factor;
  // console.log(`RoundNumber:  ${number}   -> ${v}`);
  return v;
}

export const RoundNumber = roundNumber

export const Formatter = {
  numberWithComma(v,decimals = 2) {
    let v2 = roundNumber(v,decimals)
    // console.log(v2);
    v2 = v2.toString().replace(/\B(?=(\d{3})+(?!\d))/g,',');
    return v2;
  },
};