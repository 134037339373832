import Products from '@/views/Product/Products.vue';
import CreateProduct from '@/views/Product/CreateProduct.vue';
import ViewEditProduct from '@/views/Product/ViewEditProduct.vue';
import ProductByCompany from '@/views/Product/ProductsByCompany.vue';

const routes = [
  {
    path: '/product/company/:id',
    name: 'ProductsByCompany',
    component: ProductByCompany,
    meta: { title: '产品' },
  },
  {
    path: '/product',
    name: 'Products',
    component: Products,
    meta: { title: '产品' },
  },
  {
    path: '/product/new',
    name: 'CreateProduct',
    component: CreateProduct,
    meta: { title: '新产品' },
  },
  { path: '/product/:id', name: 'ViewProduct', component: ViewEditProduct },

];

export default routes;
