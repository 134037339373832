<template>
  <div>
    <v-card flat>
      <v-card-title>
        未连接OMS产品
        <v-spacer />
        
          <v-btn color="primary"
           :disabled="quickLinkInProgress"
           @click="quickLink">自动连接</v-btn>
      </v-card-title>
    </v-card>
    
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      items-per-page="200"
      class="elevation-1 mt-4"
    >
      <template v-slot:no-data> 无 </template>
      <template v-slot:[`item.sku`]="{ item }">
        <a :href="'/product/' + item._id">{{ item.sku }}</a>
      </template>
      <template v-slot:[`item.price`]="{ item }"> ¥ {{ item.price }} </template>
      <template v-slot:[`item.qtyPerCase`]="{ item }">
        {{ item.qtyPerCase }} {{ item.unit }}
      </template>
      <template v-slot:[`item.mainImage`]="{ item }">
        <v-img
          v-if="!!item.mainImage"
          :src="'/api/file/image/thumbnail/' + item.mainImage"
          lazy-src="/img/placeholder.png"
          max-height="100"
          max-width="100"
          contain
        />
      </template>
    </v-data-table>
  </div>
</template>
<script>
import bus from '@/eventBus';

export default {
  data() {
    return {
      items: [],
      quickLinkInProgress: false,
      loading: false,
      headers: [
        { text: '产品序号', align: 'center', value: 'sku' },
        { text: '图片', value: 'mainImage', sortable: false },
        { text: '名字', value: 'name', sortable: false },
        { text: '单价', value: 'price' },
        { text: '装箱', value: 'qtyPerCase', sortable: false },
        { text: '欠收', value: 'openPO', sortable: false },
        { text: '未出', value: 'inStock', sortable: false },
        { text: '路上', value: 'enRoute', sortable: false },
        { text: '公司', value: 'company.name', sortable: false },
      ],
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.loading = true;
      this.$http
        .get('/product/unlinked')
        .then((response) => {
          this.items = response.data.docs;
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    quickLink(){
      bus.$emit('success', '自动关联产品中...')
      this.autoLinkInProgress = true;
      this.$http
        .post('/product/autoLink')
        .then((response) => {
          bus.$emit('success', `成功自动关联 ${response.data.count} 个产品`)
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        }).finally(() => {
          this.autoLinkInProgress = false;
        });
    }
  },
};
</script>
