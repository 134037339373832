<template>
  <div>
    <v-speed-dial
      v-model="fab"
      bottom
      right
      direction="top"
      absolute
      fixed
      transition="slide-y-reverse-transition"
    >
      <template v-slot:activator>
        <v-btn v-model="fab" color="blue darken-2" dark fab>
          <v-icon v-if="fab"> mdi-close </v-icon>
          <v-icon v-else> mdi-dots-horizontal </v-icon>
        </v-btn>
      </template>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            small
            v-bind="attrs"
            v-on="on"
            color="indigo"
            @click="$emit('receive')"
          >
            <v-icon>mdi-cube</v-icon>
          </v-btn>
        </template>
        <span>收货</span>
      </v-tooltip>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            small
            v-bind="attrs"
            v-on="on"
            color="green"
            @click="$emit('edit')"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>修改</span>
      </v-tooltip>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            small
            v-bind="attrs"
            v-on="on"
            color="red"
            @click="emitDeleteEvent"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
        <span>删除</span>
      </v-tooltip>
    </v-speed-dial>
    <ConfirmDelete :text="deleteConfirmValue" @delete="$emit('delete')" />
  </div>
</template>
<style>
</style>

<script>
import ConfirmDelete from '@/components/ConfirmDelete.vue';
import bus from '@/eventBus';

export default {
  props: { deleteConfirmValue: { type: String, default: '确认' } },
  components: { ConfirmDelete },
  data: () => ({
    fab: false,
  }),
  methods: {
    emitDeleteEvent() {
      bus.$emit('confirm-delete');
    },
  },
};
</script>
