<template>
  <div>
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          outlined
          append-icon="mdi-magnify"
          label="搜索"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
    </v-card>
    <v-data-table
      :headers="getHeaders()"
      :items="filterItems"
      :loading="loading"
      hide-default-footer
      :items-per-page="pagination.rowsPerPage"
      class="elevation-1"
    >
      <template v-slot:[`item.product.sku`]="{ item }">
        <a :href="'/product/' + item.product._id + '?&tab=4'">
          {{ item.product.sku }}</a
        >
      </template>
      <template v-slot:[`item.price`]="{ item }"> ¥ {{ item.price }} </template>
      <template v-slot:[`item.product.qtyPerCase`]="{ item }">
        {{ item.qtyPerCase }} {{ item.unit }}
      </template>
      <template v-slot:[`item.image`]="{ item }">
        <v-img
          v-if="!!item.product.mainImage"
          :src="'/api/file/image/thumbnail/' + item.product.mainImage"
          lazy-src="/img/placeholder.png"
          max-height="100"
          max-width="100"
          contain
        />
      </template>
    </v-data-table>

    <v-pagination
      :value="pagination.page"
      :length="pages"
      :total-visible="300"
      circle
      @input="paginationChangeHandler"
    />
  </div>
</template>
<style>
</style>

<script>
import bus from '@/eventBus';

export default {
  props: { id: String },
  data: () => ({
    loading: false,
    search: '',
    items: [],
    pagination: { totalItems: 0, rowsPerPage: 300, page: 1 },
    headers: [
      { text: '厂家序号', align: 'center', value: 'sku' },
      { text: '产品序号', align: 'center', value: 'product.sku' },
      { text: '图片', value: 'image', sortable: false },
      { text: '名字', value: 'product.name', sortable: false },
      { text: '单价', value: 'price' },
      { text: '装箱', value: 'product.qtyPerCase', sortable: false },
      { text: '欠收', value: 'product.openPO', sortable: false },
      { text: '未出', value: 'product.inStock', sortable: false },
      { text: '路上', value: 'product.enRoute', sortable: false },
      {
        text: '预测库存',
        value: 'product.projectedQty',
        sortable: false,
      },
    ],
  }),
  created() {
    this.fetch();
  },
  methods: {
    paginationChangeHandler(pageNumber) {
      if (this.pagination.page === pageNumber) return;
      this.pagination.page = pageNumber;
      this.fetch();
    },
    fetch() {
      this.loading = true;
      const limit = this.pagination.rowsPerPage;
      let offset = (this.pagination.page - 1) * limit;
      if (offset < 0) offset = 0;
      this.$http
        .get(`/vendorProduct/vendor/${this.id}?limit=${limit}&offset=${offset}`)
        .then((response) => {
          this.items = response.data.docs;
          this.pagination.totalItems = response.data.totalDocs;
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getHeaders() {
      const { isAdmin } = this.$user.data;
      if (isAdmin) return this.headers;
      const headers = [];
      this.headers.forEach((e) => {
        if (!e.adminOnly) {
          headers.push(e);
        }
      });
      return headers;
    },
  },
  computed: {
    pages() {
      if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null) {
        return 0;
      }

      return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage);
    },
    filterItems() {
      if (!this.search) return this.items;
      const items = [];
      for (let i = 0; i < this.items.length; i += 1) {
        const item = this.items[i];
        if (item.product.name.includes(this.search)) {
          items.push(item);
        } else if (item.sku.toUpperCase().includes(this.search.toUpperCase())) {
          items.push(item);
        }
      }
      return items;
    },
  },
};
</script>
