<template>
  <div>
    <div v-if="!hasValidSqlId">
      <OmsProductSearch @link="linkProduct" />
    </div>
    <div v-else>
      <v-container>
        <div>SKU: {{ omsdata.sku }}</div>
        <div>名字: {{ omsdata.descrip }}</div>
        <div>价格: $ {{ omsdata.whole_prs }} / {{ omsdata.unit_nm }}</div>
        <div>装箱: {{ omsdata.qtyPerCase }} {{ omsdata.unit_nm }}</div>
        <div>实际库存: {{ omsdata.in_stock / omsdata.qtyPerCase }} 箱</div>
        <div>订单数量: {{ omsdata.so_qty / omsdata.qtyPerCase }} 箱</div>
        <div>
          预计库存:
          {{ (omsdata.in_stock - omsdata.so_qty) / omsdata.qtyPerCase }} 箱
        </div>
      </v-container>
    </div>
  </div>
</template>
<style>
</style>

<script>
// import bus from '@/eventBus';
import OmsProductSearch from '@/components/Product/OmsProductSearch.vue';

export default {
  components: {
    OmsProductSearch,
  },
  props: {
    id: Number, // sql id
  },
  data: () => ({
    omsdata: {
      sku: '',
      descrip: '',
      in_stock: 0,
      so_qty: 0,
      unit_nm: '',
      qtyPerCase: 1,
    },
  }),
  created() {
    this.fetch();
  },
  methods: {
    linkProduct(sqlId) {
      this.$emit('link', sqlId);
    },
    fetch() {
      if (!this.id) return;
      if (this.id <= 0) return;
      this.$http.get(`/product/${this.id}/oms`).then((response) => {
        this.omsdata = response.data;
      });
    },
  },
  computed: {
    hasValidSqlId() {
      if (!this.id) return false;
      if (Number.isNaN(this.id)) return false;
      if (this.id > 0) return true;
      return false;
    },
  },
  watch: {
    id() {
      this.fetch();
    },
  },
};
</script>
