import Shipments from '@/views/Shipment/Shipments.vue';
import CreateShipment from '@/views/Shipment/CreateShipment.vue';
import ViewEditShipment from '@/views/Shipment/ViewEditShipment.vue';
import PrintShipment from '@/views/Shipment/PrintShipment.vue';

const routes = [
  {
    path: '/shipment', name: 'Shipments', component: Shipments, meta: { title: '集装箱' },
  },
  {
    path: '/shipment/new', name: 'CreateShipment', component: CreateShipment, meta: { title: '新集装箱' },
  },
  {
    path: '/shipment/:id/print', name: 'PrintShipment', component: PrintShipment, meta: { title: '打印集装箱' },
  },
  { path: '/shipment/:id', name: 'ViewEditShipment', component: ViewEditShipment },
];

export default routes;
