<template>
  <div>
    <v-card flat>
      <v-card-title>
        散货
        <v-spacer />
        <router-link to="/shipment/new">
          <v-btn color="primary"> <v-icon>mdi-plus</v-icon>新建 </v-btn>
        </router-link>
      </v-card-title>
    </v-card>
    <v-card>
      <v-card-title>
        
        <!-- <v-spacer /> -->
        <v-row
          ><v-col cols="8" md="4" class="mb-n7">
            <v-select
              v-model="state"
              :items="states"
              label="滤选"
              item-text="text"
              item-value="value"
              outlined
              dense
            ></v-select></v-col
        ></v-row>
        <!-- <OrderSearchAutoComplete :input.sync="search" /> -->
      </v-card-title>
    </v-card>
    <v-data-table
      :headers="getHeaders()"
      :items="items"
      :loading="loading"
      hide-default-footer
      :items-per-page="pagination.rowsPerPage"
      class="elevation-1"
      :sort-desc.sync="sortDesc"
      :sort-by.sync="sortBy"
      @update:sort-by="sortTable"
      @update:sort-desc="sortTable"
    >
      <template v-slot:[`item.num`]="{ item }">
        <a :href="'/shipment/' + item._id">{{ item.num }}</a>
      </template>
      <template v-slot:[`item.subtotal`]="{ item }">
        ¥ {{ numberWithComma(item.subtotal,2) }}
      </template>
      <template v-slot:[`item.shipping2`]="{ item }">
        ¥ {{ numberWithComma(item.shipping2,2) }}
      </template>
      <template v-slot:[`item.totalWeight`]="{ item }">
        {{ numberWithComma(item.totalWeight,2) }} kg
      </template>
      -->
      <template v-slot:[`item.shipDate`]="{ item }">
        {{ formatDate(item.shipDate) }}
      </template>
      <template v-slot:[`item.arrivalDate`]="{ item }">
        {{ formatDate(item.arrivalDate) }}
      </template>
      <template v-slot:[`item.receivedDate`]="{ item }">
        {{ formatDate(item.receivedDate) }}
      </template>
      <template v-slot:[`item.totalVolume`]="{ item }">
        {{ (item.totalVolume / 1000000).toFixed(2) }} m<sup>3</sup>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <OrderStatusIcon
          :locked="item.locked"
          :state="item.state"
        />
      </template>
    </v-data-table>

    <v-pagination
      :value="pagination.page"
      :length="pages"
      :total-visible="12"
      circle
      @input="paginationChangeHandler"
    />
  </div>
</template>

<style scoed>
tr:nth-of-type(even) {
  background-color: rgba(92, 83, 206, 0.05);
}
</style>

<script>
import bus from '@/eventBus';
import OrderStatusIcon from '@/components/Order/OrderStatusIcon.vue';
// import OrderSearchAutoComplete from '@/components/OrderSearchAutoComplete.vue';
import { Formatter } from '@/library/utility';

export default {
  components: { OrderStatusIcon/* , OrderSearchAutoComplete */ },
  data() {
    return {
      items: [],
      sortDesc: true,
      sortBy: '',
      state: 0,
      states: [
        { text: '所有', value: -1 },
        { text: '未到', value: 0 },
        { text: '已到', value: 1 },
      ],
      search: '',
      loading: false,
      pagination: { totalItems: 0, rowsPerPage: 25, page: 1 },
      headers: [
        { text: '号码', align: 'center', value: 'num' },
        { text: '名称', value: 'name', sortable: false },
        { text: '产品数量', value: 'numOfProducts', sortable: false },
        { text: '箱数', value: 'totalNumCase', sortable: false },
        { text: '运输方式', value: 'shippingMethod', sortable: false },
        { text: '运输日期', value: 'shipDate', sortable: false },
        { text: '预计到达日期', value: 'arrivalDate', sortable: false },
        { text: '进仓日期', value: 'receivedDate', sortable: false },
        {
          text: '产品总价',
          value: 'subtotal',
          sortable: false,
        },
        {
          text: '状态',
          value: 'status',
          sortable: false,
          align: 'center',
        },
        { text: '总体积', value: 'totalVolume', sortable: false },
        {
          text: '公司',
          value: 'company.name',
          sortable: false,
          adminOnly: true,
        },
      ],
    };
  },
  computed: {
    pages() {
      if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null) {
        return 0;
      }

      return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage);
    },
  },
  created() {
    const pageNumber = parseInt(this.$route.query.page, 10);
    this.pagination.page = Number.isNaN(pageNumber) ? 0 : pageNumber;
    this.state = parseInt(this.$route.query.state, 10) || 0;
    this.sortBy = this.$route.query.sortBy;
    this.sortDesc = this.$route.query.sortDesc === 'true';
    this.fetch();
  },
  methods: {
    fetch() {
      this.loading = true;
      const limit = this.pagination.rowsPerPage;
      let offset = (this.pagination.page - 1) * limit;
      if (offset < 0) offset = 0;
      let link = `/shipment?limit=${limit}&offset=${offset}&state=${this.state}`;
      if (this.sortBy) {
        link += `&sortBy=${this.sortBy}&sortDesc=${!!this.sortDesc}`;
      }
      this.$http
        .get(link)
        .then((response) => {
          this.items = response.data.docs;
          this.pagination.totalItems = response.data.totalDocs;
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    sortTable() {
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.fetch();
      }, 500); /* 500ms throttle */
    },
    paginationChangeHandler(pageNumber) {
      if (this.pagination.page === pageNumber) return;
      this.pagination.page = pageNumber;
      this.$router.push(`/shipment?page=${pageNumber}`);
      this.fetch();
    },
    updateRoute() {
      this.$router
        .replace({
          path: '/shipment',
          query: {
            page: this.pagination.page,
            state: this.state,
            sortBy: this.sortBy,
            sortDesc: this.sortDesc,
          },
        })
        .catch((err) => {}); // don't care about duplicate navigation error here
    },
    getHeaders() {
      const { isAdmin } = this.$user.data;
      const headers = [];
      if (isAdmin) {
        this.headers.forEach((e) => {
          if (!e.userOnly) {
            headers.push(e);
          }
        });
        return headers;
      }

      this.headers.forEach((e) => {
        if (!e.adminOnly) {
          headers.push(e);
        }
      });
      return headers;
    },
    formatDate(v) {
      if (!v) return '';
      const d = v.substr(0, 10).split('-');
      return `${d[1]}/${d[2]}/${d[0].substr(2)}`;
    },
    ...Formatter,
  },
  watch: {
    search(val) {
      this.$router.push(`/shipment/${val}`);
    },
    state(val) {
      this.pagination.page = 0;
      this.updateRoute();
      this.fetch();
    },
    sortBy(val) {
      this.pagination.page = 0;
      this.updateRoute();
    },
    sortDesc(val) {
      this.pagination.page = 0;
      this.updateRoute();
    },
  },
};
</script>
