import LazyLoad from '@/router/lazyload';

const routes = [
  {
    path: '/category',
    name: 'Categories',
    component: LazyLoad('Category/Categories'),
    meta: { title: '分类' },
  },
  { path: '/category/:id', name: 'ViewEditCategory', component: LazyLoad('Category/ViewEditCategory') },
];

export default routes;
