<template>
  <div>
    <v-card flat>
      <v-tabs v-model="tab">
        <v-tab href="#1">资料</v-tab>
        <v-tab href="#2">附件</v-tab>
      </v-tabs>
      <v-card-title class="mb-2"> 订单 - {{ item.num }} </v-card-title>
      <v-spacer />
      <v-divider class="mt-n5" />
      <v-tabs-items v-model="tab">
        <v-tab-item value="1">
          <v-container>
            <v-row>
              <v-col cols="12" md="4">
                <v-card height="280">
                  <v-card-title>图片</v-card-title>
                  <v-img
                    v-if="item.product.mainImage"
                    :src="'/api/file/image/thumbnail/' + item.product.mainImage"
                    max-height="200"
                    contain
                  />
                </v-card>
              </v-col>
              <v-col cols="12" md="8">
                <v-card min-height="280">
                  <v-card-title>
                    <v-icon large left> mdi-tag </v-icon>
                    <span class="title font-weight-light">产品资料</span>
                  </v-card-title>
                  <v-card-text>
                    <v-row class="mt-n2">
                      <v-col
                        ><span class="text-subtitle-1">名字: </span>
                        <span class="text-h6 font-weight-bold">{{
                          item.product.name
                        }}</span>
                      </v-col>
                    </v-row>
                    <v-row class="mt-n1">
                      <v-col cols="6">
                        <span class="text-subtitle-1">SKU: </span>
                        <span class="text-h6 font-weight-bold">
                          <a :href="'/product/' + item.product._id">
                            {{ item.product.sku }}</a
                          >
                        </span>
                      </v-col>
                      <v-col cols="6">
                        <span class="text-subtitle-1">供应商SKU: </span>
                        <span class="text-h6 font-weight-bold">
                          {{ item.vendorSKU }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row class="mt-1">
                      <v-col cols="6">
                        <span class="text-subtitle-1">装箱: </span>
                        <span class="text-h6 font-weight-bold">
                          {{ item.qtyPerCase }}
                        </span>
                      </v-col>
                      <v-col cols="12" md="6">
                        <span class="text-subtitle-1">UPC: </span>
                        <span class="text-h6 font-weight-bold">
                          {{ item.product.upc }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row class="mt-1">
                      <v-col cols="12" md="6">
                        <span class="text-subtitle-1">箱规: </span>
                        <span class="text-h6 font-weight-bold">
                          {{ dimensionString(item) }}
                        </span>
                      </v-col>
                      <v-col cols="6">
                        <span class="text-subtitle-1">毛量: </span>
                        <span class="text-h6 font-weight-bold"
                          >{{ item.product.weight }} kg</span
                        ></v-col
                      >
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-card
                  ><v-card-title>订单资料</v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col col="6" md="4">
                        <span class="text-subtitle-1">供应商: </span>
                        <span class="text-h6 font-weight-bold">
                          <a :href="`/vendor/` + item.vendor._id">
                            {{ item.vendor.name }}</a
                          >
                        </span></v-col
                      >
                      <v-col col="6" md="4">
                        <span class="text-subtitle-1">签订日期: </span>
                        <span class="text-h6 font-weight-bold">
                          {{ formatDate(item.startDate) }}
                        </span></v-col
                      >
                      <v-col col="6" md="4">
                        <span class="text-subtitle-1">交货日期: </span>
                        <span class="text-h6 font-weight-bold">
                          {{ formatDate(item.endDate) }}
                        </span></v-col
                      >
                    </v-row>
                    <v-row>
                      <v-col cols="6" md="3">
                        <span class="text-subtitle-1">单价: </span>
                        <span
                          class="text-h6 font-weight-bold blue--text darken-3"
                        >
                          ¥ {{ item.price }}
                        </span></v-col
                      >
                      <v-col col="6" md="3">
                        <span class="text-subtitle-1">件数: </span>
                        <span class="text-h6 font-weight-bold">
                          {{ item.qty }}
                        </span></v-col
                      >
                      <v-col col="6" md="3">
                        <span class="text-subtitle-1">已收: </span>
                        <span class="text-h6 font-weight-bold">
                          {{ item.qtyReceived }}
                        </span></v-col
                      >
                      <v-col col="6" md="3">
                        <span class="text-subtitle-1">欠收: </span>
                        <span class="text-h6 font-weight-bold">
                          {{ item.qty - item.qtyReceived }}
                        </span></v-col
                      >
                    </v-row>
                    <v-row>
                      <v-col col="4">
                        <span class="text-subtitle-1">PO号码: </span>
                        <span class="text-h6 font-weight-bold">
                          {{ item.poNum }}
                        </span></v-col
                      >
                      <v-col col="4">
                        <span class="text-subtitle-1">总额: </span>
                        <span class="text-h6 font-weight-bold">
                          ¥ {{ getTotal }}
                        </span></v-col
                      >
                    </v-row>
                    <v-row v-if="item.note">
                      <v-col>
                        <span class="text-subtitle-1">备注: </span>
                        <span class="text-h6 font-weight-bold">
                          {{ item.note }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-data-table
              :headers="headers"
              :items="item.receiveHistory"
              hide-default-footer
              class="elevation-1 mt-5"
            >
              <template v-slot:top>
                <v-toolbar flat color="blue-grey lighten-2 ">
                  <v-toolbar-title>收货记录</v-toolbar-title>
                </v-toolbar>
              </template>
              <template v-slot:no-data> 无记录 </template>
              <template v-slot:[`item.price`]="{ item }">
                ¥ {{ item.price }}
              </template>
              <template v-slot:[`item.date`]="{ item }">
                {{ formatDate(item.date) }}
              </template>
            </v-data-table>
          </v-container>
        </v-tab-item>
        <v-tab-item value="2">
          <v-container> <Attachments /> </v-container>
        </v-tab-item>
      </v-tabs-items>
      <FloatingButton
        @edit="openEditDialog"
        @receive="openReceiveDialog"
        @delete="deleteOrder"
        :deleteConfirmValue="item.num.toString()"
      />
      <v-dialog v-model="dialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">修改</span>
          </v-card-title>
          <v-card-text>
            <v-form v-model="validEdit" @submit.prevent="saveEdit()">
              <!-- <v-row justify="center" align="center">
                <v-col cols="12">
                  <VendorSearchAutoComplete :input.sync="editFields.vendor"
                    :default="this.item.vendor._id"/></v-col>
              </v-row> -->
              <v-row justify="center" align="center">
                <v-col cols="6">
                  <v-text-field
                    v-model.number="editFields.price"
                    label="价格"
                    name="price"
                    prefix="¥"
                    :rules="rule.positiveFloat"
                    type="number"
                    outlined
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model.number="editFields.qty"
                    label="箱数"
                    name="qty"
                    type="number"
                    step="1"
                    :rules="rule.positiveInteger"
                    outlined
                  />
                </v-col>
              </v-row>
              <v-row justify="center" align="center" class="mt-n5">
                <v-col cols="6">
                  <v-text-field
                    v-model="editFields.poNum"
                    label="PO号码"
                    name="poNum"
                    type="number"
                    step="1"
                    :rules="rule.integer"
                    outlined
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model.number="editFields.vendorSKU"
                    label="供应商SKU"
                    name="vendorSKU"
                    :rules="rule.notEmpty"
                    outlined
                  />
                </v-col>
              </v-row>
              <v-row class="mt-n6">
                <v-col cols="6">
                  <DatePicker
                    label="签订日期"
                    required
                    :date="editFields.startDate"
                    @update="(v) => (editFields.startDate = v)"
                  />
                </v-col>
                <v-col cols="6">
                  <DatePicker
                    label="交货日期"
                    required
                    :date="editFields.endDate"
                    @update="(v) => (editFields.endDate = v)"
                  />
                </v-col>
              </v-row>
              <v-row justify="center" align="center" class="mt-n5">
                <v-col>
                  <v-textarea
                    v-model="editFields.note"
                    name="note"
                    outlined
                    rows="3"
                    label="备注"
                    auto-grow
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="dialog = false">
              取消
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :disabled="!validEdit"
              @click="saveEdit"
            >
              保存
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="receivalDialog" max-width="500px">
        <v-card>
          <v-card-title class="mb-5">
            <span class="headline">收货</span>
          </v-card-title>
          <v-card-text>
            <v-form v-model="validReceive" @submit.prevent="saveReceival()">
              <v-row justify="center" align="center">
                <v-col cols="12">
                  <v-text-field
                    v-model.number="receiveEditFields.qty"
                    label="箱数"
                    name="qty"
                    :rules="rule.integer"
                    type="number"
                    step="1"
                    outlined
                  />
                </v-col>
              </v-row>
              <v-row class="mt-n6">
                <v-col>
                  <DatePicker
                    label="收货日期"
                    required
                    :date="receiveEditFields.date"
                    @update="(v) => (receiveEditFields.date = v)"
                  />
                </v-col>
              </v-row>
              <v-row justify="center" align="center" class="mt-n5">
                <v-col>
                  <v-textarea
                    v-model="receiveEditFields.note"
                    name="note"
                    outlined
                    rows="3"
                    label="备注"
                    auto-grow
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="receivalDialog = false">
              取消
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :disabled="!validReceive"
              @click="saveReceival"
            >
              保存
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <br />
  </div>
</template>
<style>
v-list-item-subtitle {
  display: inline;
}
</style>
<script>
import bus from '@/eventBus';
import rule from '@/library/rule';
import Attachments from '@/components/Attachments.vue';
import FloatingButton from '@/components/POFloatingButton.vue';
import DatePicker from '@/components/DatePicker.vue';
// import VendorSearchAutoComplete from '@/components/VendorSearchAutoComplete.vue';

export default {
  components: {
    Attachments,
    FloatingButton,
    DatePicker,
    //  VendorSearchAutoComplete,
  },
  data: () => ({
    initializing: true,
    tab: null,
    date: undefined,
    dialog: false,
    receivalDialog: false,
    saving: false,
    validEdit: false,
    validReceive: false,
    loading: false,
    rule,
    showMenu: false,
    editFields: {
      fee: 0,
      vendorSKU: '',
      price: 0,
      qty: 0,
      poNum: '',
      note: '',
      startDate: '',
      endDate: '',
      // vendor: '',
    },
    receiveEditFields: {
      qty: 50,
      note: '',
      date: new Date().toISOString().substr(0, 10),
    },
    item: {
      qtyReceived: 0,
      state: 0,
      _id: '',
      price: 0,
      qty: 0,
      poNum: '',
      startDate: undefined,
      endDate: undefined,
      addedBy: '',
      product: {
        mainImage: '',
        _id: '',
        sku: '',
        name: '',
        length: 0,
        wdith: 0,
        height: 0,
        weight: 0,
      },
      company: '',
      vendor: {
        _id: '',
        name: '',
      },
      qtyPerCase: 0,
      createdDate: '',
      lastModified: '',
      receiveHistory: [],
      delays: [],
      num: 0,
    },
    headers: [
      { text: '日期', value: 'date', sortable: false },
      { text: '件数', value: 'qty' },
      { text: '备注', value: 'note', sortable: false },
    ],
    orderHistory: [],
    orderHistoryLoading: false,
  }),
  computed: {
    showEditToggle() {
      return this.tab === '1' || this.tab === '4';
    },
    getTotal() {
      const p = this.item;
      const result = p.price * p.qty * p.qtyPerCase;
      return result.toFixed(2);
    },
  },
  created() {
    this.tab = this.$route.query.tab;
    this.fetch();
    this.initializing = false;
  },
  methods: {
    saveEdit() {
      this.saving = true;
      this.$http
        .put(`/po/${this.$route.params.id}`, this.editFields)
        .then((response) => {
          this.item = response.data;
          bus.$emit('success', '修改成功');
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        })
        .finally(() => {
          this.saving = false;
          this.dialog = false;
        });
    },
    saveReceival() {
      const { qty } = this.receiveEditFields;
      if (qty > this.item.qty - this.item.qtyReceived) {
        bus.$emit('error', '接受件数超过剩余！ 请先修改订单！');
        return;
      }
      if (qty < 0 && Math.abs(qty) > this.item.qtyReceived) {
        bus.$emit('error', '超出已收数量！');
        return;
      }
      this.saving = true;
      this.$http
        .put(`/po/${this.$route.params.id}/receive`, this.receiveEditFields)
        .then((response) => {
          this.item = response.data;
          bus.$emit('success', '成功');
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        })
        .finally(() => {
          this.saving = false;
          this.receivalDialog = false;
        });
    },
    fetch() {
      this.loading = true;
      this.$http
        .get(`/po/${this.$route.params.id}`)
        .then((response) => {
          this.item = response.data;
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        })
        .finally(() => {
          this.loading = false;
          document.title = `订单 - ${this.item.num}`;
        });
    },
    updateRoute() {
      if (this.initializing) return;
      const path = `${this.$route.path}?tab=${this.tab}`;
      this.$router.replace(path).catch((err) => {});
    },
    dimensionString(item) {
      const { length, width, height } = item.product;
      return `${length} x ${width} x ${height} cm`;
    },
    formatDate(v) {
      if (!v) return '';
      const d = v.substr(0, 10).split('-');
      return `${d[1]} / ${d[2]} / ${d[0].substr(2)}`;
    },
    openEditDialog() {
      Object.keys(this.editFields).forEach((e) => {
        this.editFields[e] = this.item[e];
      });
      // this.editFields.vendor = this.item.vendor._id;
      this.editFields.startDate = this.item.startDate.substr(0, 10);
      this.editFields.endDate = this.item.endDate.substr(0, 10);
      this.dialog = true;
    },
    openReceiveDialog() {
      this.receiveEditFields.qty = this.item.qty - this.item.qtyReceived;
      this.receivalDialog = true;
    },
    deleteOrder() {
      this.$http
        .delete(`/po/${this.$route.params.id}`)
        .then((response) => {
          bus.$emit('success', '删除成功');
          this.$router.replace('/po');
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        });
    },
  },
  watch: {
    tab() {
      if (this.initializing) return;
      this.updateRoute();
    },
  },
};
</script>
