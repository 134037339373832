<template>
  <v-autocomplete
    v-model="selected"
    :items="items"
    :loading="isLoading"
    :search-input.sync="search"
    clearable
    hide-details
    hide-no-data
    return-object
    prepend-inner-icon="mdi-database-search"
    item-text="combined"
    item-value="_id"
    label="搜索集装箱"
    solo
  >
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title>
          输入<strong>集装箱号码或序号</strong>进行搜索
        </v-list-item-title>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
import bus from '@/eventBus';

export default {
  data() {
    return {
      isLoading: false,
      selected: null,
      search: null,
      items: [],
    };
  },
  methods: {
    clearEntries() {
      this.items = [];
    },
    fetchEntriesDebounced() {
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.fetchEntries();
      }, 500); /* 500ms throttle */
    },
    fetchEntries() {
      this.$http
        .get(`/order/search?q=${this.search}`)
        .then((response) => {
          const list = response.data;
          list.forEach((e) => {
            e.combined = `${e.num}-${e.cid}`;
            delete e.num;
            delete e.cid;
          });
          this.items = list;
        })
        .catch((err) => {
          bus.$emit('error', this.$error.getMsg(err));
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  watch: {
    selected(val) {
      let payload = null;
      if (val) payload = val._id;
      this.$emit('update:input', payload);
    },
    search(val) {
      this.clearEntries();
      // only search when search string length is >3
      if (!this.search || this.search.length <= 3) return;
      this.isLoading = true;
      this.fetchEntriesDebounced();
    },
  },
};
</script>
