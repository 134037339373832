export default {
  state: () => ({ vendors: [] }),
  getters: {
    vendorList(state) {
      return state.vendors;
    },
  },
  mutations: {
    updateVendorList(state, newVendorList) {
      state.vendors = newVendorList;
    },
    addVendor(state, newVendor) {
      if (state.vendors.length === 0) return;
      const object = { _id: newVendor._id, name: newVendor.name };
      state.vendors.push(object);
    },
    updateVendor(state, updatedVendor) {
      if (state.vendors.length === 0) return;
      for (let i = 0; i < state.vendors.length; i += 1) {
        if (state.vendors[i]._id === updatedVendor._id) {
          state.vendors[i].name = updatedVendor.name;
        }
      }
    },
  },
  actions: {
    initializeVendors({ state, commit }, vue) {
      if (state.vendors.length > 0) return;
      vue.$http.get('/vendor/names').then((response) => {
        commit('updateVendorList', response.data);
      });
    },
  },

};
