<template>
  <nav>
    <v-app-bar app color="blue " dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <a href="/"> <v-icon right> mdi-home </v-icon></a>
      <v-spacer />
      <v-btn text @click="logOut()">
        <span>退出</span>
        <v-icon right> mdi-logout </v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      color="blue darken-2"
      mini-variant-width="60"
      hide-overlay
      app
      dark
    >
      <v-list nav class="py-0" tile>
        <v-list-item two-line>
          <v-list-item-avatar>
            <img src="/img/profile.png" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ this.$user.data.name }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ this.$user.data.username }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <!-- menu items -->
        <div v-for="link in links" :key="link.title">
          <div v-if="isAdmin() || !link.adminOnly">
            <!-- template for no sub-menu link-->
            <v-list-item v-if="!link.subLinks" :to="link.route">
              <v-list-item-icon>
                <v-icon>{{ link.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ link.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <!-- template for sub-menu link-->
            <v-list-group
              v-else
              subgroup
              :prepend-icon="link.icon"
              color="white"
            >
              <template v-slot:activator>
                <v-list-tem-title>{{ link.title }}</v-list-tem-title>
              </template>
              <v-list-item
                v-for="(subLink, i) in link.subLinks"
                :key="i"
                :to="subLink.route"
                color="white"
                class="submenu-text"
              >
                <v-list-item-icon>
                  <v-icon>mdi-minus</v-icon>
                </v-list-item-icon>
                <v-list-tem-title v-text="subLink.title"></v-list-tem-title>
              </v-list-item>
            </v-list-group>
          </div>
        </div>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      drawer: null,
      links: [
        {
          icon: 'mdi-domain',
          title: '公司',
          route: '/company',
          adminOnly: true,
        },
        {
          icon: 'mdi-tag',
          title: '产品',
          route: '/product',
          adminOnly: false,
        },
        {
          icon: 'mdi-ferry',
          title: '集装箱',
          route: '/order',
          adminOnly: false,
        },
        {
          icon: 'mdi-checkbox-blank',
          title: '散货',
          route: '/shipment',
          adminOnly: false,
        },
        {
          icon: 'mdi-folder-arrow-left-right',
          title: '分类',
          route: '/category',
          adminOnly: false,
        },
        {
          icon: 'mdi-factory',
          title: '供应商',
          route: '/vendor',
          adminOnly: false,
        },
        {
          icon: 'mdi-cart',
          title: '订单',
          route: '/po',
          adminOnly: false,
        },
        {
          icon: 'mdi-hammer-screwdriver',
          title: '工具',
          route: '/utility',
          adminOnly: true,
        },
        {
          icon: 'mdi-account-supervisor',
          title: '用户',
          route: '/user',
          adminOnly: true,
        },
        {
          icon: 'mdi-cog',
          title: '设置',
          route: '/setting',
          adminOnly: false,
        },
      ],
    };
  },
  created() {
    const settings = localStorage.getItem('showNav');
    if (settings) {
      this.drawer = JSON.parse(settings).showNav;
    }
  },
  methods: {
    logOut() {
      this.$http
        .post('/user/logout')
        .then(() => {})
        .catch(() => {})
        .finally(() => {
          this.$user.logOut();
          this.$router.push('/login');
        });
    },
    isAdmin() {
      return this.$user.data.isAdmin;
    },
  },
  watch: {
    drawer(val) {
      localStorage.setItem('showNav', JSON.stringify({ showNav: val }));
    },
  },
};
</script>

<style>
.submenu-text {
  font-size: 80%;
}
</style>
