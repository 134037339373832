const ErrorHandler = {
  getMsg(error) {
    if (error.response) {
      /* The request was made and the server responded with a
         * status code that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);  */
      const { data } = error.response;
      let message = '';
      if (error.response.status === 404) {
        message = data.message ? data.message : '资源不存在';
      } else if (error.response.status === 500) {
        message = data.message ? data.message : '服务器出错!';
      } else if (error.response.status === 422) {
        if (Array.isArray(data.errors)) {
          for (let i = 0; i < data.errors.length; i += 1) {
            message += Object.values(data.errors[i]);
            message += ' - ';
          }
        }
      } else {
        message = data.message ? data.message : message;
      }
      return message;
    }
    if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      return '服务器无响应';
    }
    return error.message;
    // Something happened in setting up the request and triggered an Error
    // return '无法发送请求';
  },
};

export default ErrorHandler;
