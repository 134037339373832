<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      class="elevation-1"
      disable-pagination
      hide-default-footer
    >
      <template v-slot:top>
        <v-toolbar flat color="blue-grey lighten-2 ">
          <v-toolbar-title>供应商</v-toolbar-title>
          <v-divider class="mx-4" inset vertical />
          <v-spacer />
          <v-btn
            fab
            small
            color="blue darken-2"
            dark
            v-if="editMode"
            @click="resetAndOpenDiaglog"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-dialog v-model="dialog" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-form v-model="valid">
                    <v-row v-if="!isEditMode">
                      <v-col align="center" justify="center">
                        <VendorSearch :input.sync="editFields.vendor" />
                      </v-col>
                    </v-row>
                    <v-row justify="center" align="center">
                      <v-col>
                        <v-text-field
                          v-model.trim="editFields.sku"
                          label="供应商SKU"
                          name="sku"
                          :rules="rule.notEmpty"
                          outlined
                        />
                      </v-col>
                    </v-row>
                    <v-row justify="center" align="center" class="mt-n7">
                      <v-col cols="6">
                        <v-text-field
                          v-model.number="editFields.price"
                          label="价格"
                          name="price"
                          :rules="rule.positiveFloat"
                          type="number"
                          prefix="¥"
                          outlined
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model.number="editFields.vat"
                          label="税点"
                          name="vat"
                          suffix="%"
                          :rules="rule.positiveFloat"
                          type="number"
                          outlined
                        />
                      </v-col>
                    </v-row>
                    <v-row justify="center" align="center" class="mt-n7">
                      <v-col cols="12">
                        <v-textarea
                          v-model="editFields.note"
                          name="note"
                          outlined
                          label="备注"
                          auto-grow
                        />
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="blue darken-1" text @click="dialog = false">
                  取消
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  type="submit"
                  :disabled="!valid"
                  @click.prevent="save"
                >
                  保存
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="poDialog" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="headline"> 添加订单 </span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <div class="h2">供应商名字: {{ vendorName }}</div>
                  <div>供应商SKU: {{ vendorSKU }}</div>
                  <div>价格: ￥{{ vendorPrice }} / {{ product.unit }}</div>
                  <div>装箱量: {{ product.qtyPerCase }} {{ product.unit }}</div>
                  <v-form v-model="validPO" class="mt-4">
                    <v-row justify="center" align="center">
                      <v-col cols="12">
                        <v-text-field
                          v-model.number="poEditFields.price"
                          label="价格"
                          name="price"
                          :rules="rule.positiveFloat"
                          type="number"
                          prefix="¥"
                          outlined
                        />
                      </v-col>
                    </v-row>
                    <v-row justify="center" align="center" class="mt-n7">
                      <v-col md="6">
                        <v-text-field
                          v-model.number="poEditFields.qty"
                          label="箱数"
                          name="qty"
                          :rules="rule.positiveInteger"
                          type="number"
                          step="1"
                          dense
                          outlined
                        />
                      </v-col>
                      <v-col md="6">
                        <v-text-field
                          v-model.number="poEditFields.poNum"
                          label="PO号码"
                          type="number"
                          dense
                          outlined
                        />
                      </v-col>
                    </v-row>
                    <v-row justify="center" align="center" class="mt-n7">
                      <v-col md="6">
                        <DatePicker
                          label="签订日期"
                          required
                          :date="poEditFields.startDate"
                          @update="(v) => (poEditFields.startDate = v)"
                        />
                      </v-col>
                      <v-col md="6">
                        <DatePicker
                          label="交货日期"
                          required
                          :date="poEditFields.endDate"
                          @update="(v) => (poEditFields.endDate = v)"
                        />
                      </v-col>
                    </v-row>
                    <v-row justify="center" align="center" class="mt-n7">
                      <v-col cols="12">
                        <v-textarea
                          v-model="poEditFields.note"
                          name="note"
                          outlined
                          label="备注"
                          auto-grow
                        />
                      </v-col>
                    </v-row>
                  </v-form>
                  <div></div>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="blue darken-1" text @click="poDialog = false">
                  取消
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  type="submit"
                  :disabled="!validPO"
                  @click.prevent="createPO"
                >
                  添加
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <a :href="getVendorLink(item)">{{ item.vendor.name }} </a>
      </template>
      <template v-slot:[`item.price`]="{ item }"> ¥ {{ item.price }} </template>
      <template v-slot:[`item.vat`]="{ item }"> {{ item.vat }}% </template>
      <template v-slot:[`item.action`]="{ item }">
        <div v-if="editMode">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="confirmDeleteItem(item)"> mdi-delete </v-icon>
        </div>
        <div v-else>
          <v-btn color="primary" class="mr-2" @click="openOrderDialog(item)">
            生成订单
          </v-btn>
        </div>
      </template>
      <template v-slot:[`body.append`]="{ headers }">
        <tr class="blue-grey lighten-4">
          <td :colspan="headers.length" align="center">
            <v-btn
              fab
              small
              color="blue darken-2"
              dark
              v-if="editMode"
              @click="resetAndOpenDiaglog"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
      <template v-slot:no-data> 无供应商 </template>
    </v-data-table>
  </div>
</template>

<script>
import VendorSearch from '@/components/VendorSearchAutoComplete.vue';
import DatePicker from '@/components/DatePicker.vue';
import bus from '@/eventBus';
import rule from '@/library/rule';

export default {
  components: { VendorSearch, DatePicker },
  // editMode is from the switch toggle
  props: { editMode: Boolean, product: Object },
  data: () => ({
    items: [],
    arrive: undefined,
    valid: false,
    validPO: false,
    dialog: false,
    poDialog: false,
    pricePrefix: '￥',
    rule,
    headers: [
      { text: '供应商名字', align: 'center', value: 'name' },
      { text: '供应商SKU', value: 'sku' },
      { text: '价格', value: 'price' },
      { text: '税点', value: 'vat' },
      // { text: '状态', value: 'status' },
      { text: '备注', value: 'note' },
      { text: '', value: 'action', sortable: false },
    ],
    editedIndex: -1,
    editFields: {
      sku: '',
      price: 0,
      note: '',
      vendor: '',
      vat: '',
    },
    defaultFields: {
      sku: '',
      price: 0,
      vat: 0,
      note: '',
      vendor: '',
    },
    poEditFields: {
      startDate: new Date().toISOString().substr(0, 10),
      endDate: undefined,
      qty: 0,
      note: '',
      poNum: 0,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? '增加' : '修改';
    },
    isEditMode() {
      return this.editedIndex >= 0;
    },
    vendorSKU() {
      if (this.editedIndex < 0) return '';
      return this.items[this.editedIndex].sku;
    },
    vendorName() {
      if (this.editedIndex < 0) return '';
      return this.items[this.editedIndex].vendor.name;
    },
    vendorPrice() {
      if (this.editedIndex < 0) return '';
      return this.items[this.editedIndex].price;
    },
    vendorVAT() {
      if (this.editedIndex < 0) return '';
      return this.items[this.editedIndex].vat;
    },
  },

  watch: {},
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.$http
        .get(`/vendorProduct/${this.$route.params.id}`)
        .then((response) => {
          this.items = response.data;
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        });
    },
    // transfer item data to edit dialog
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      Object.keys(this.editFields).forEach((e) => {
        this.editFields[e] = item[e];
      });
      this.dialog = true;
    },

    confirmDeleteItem(item) {
      /* eslint-disable */
      window.confirm('确定要删除此行?') && this.deleteItem(item);
      /* eslint-enable */
    },

    deleteItem(item) {
      this.$http
        .delete(`/vendorProduct/${item._id}`)
        .then((response) => {
          const index = this.items.indexOf(item);
          this.items.splice(index, 1);
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        });
    },
    resetAndOpenDiaglog() {
      this.resetDialog();
      this.dialog = true;
    },
    resetDialog() {
      this.editFields = this.defaultFields;
      this.editedIndex = -1;
    },
    // called when item is edited/created based on editedIndex
    save() {
      if (this.editedIndex > -1) {
        const id = this.items[this.editedIndex]._id;
        this.$http
          .put(`/vendorProduct/${id}`, this.editFields)
          .then((response) => {
            this.items.splice(this.editedIndex, 1, response.data);
          })
          .catch((error) => {
            bus.$emit('error', this.$error.getMsg(error));
          });
        // trigger edit event
      } else {
        // trigger add event
        this.$http
          .post(`/vendorProduct/${this.$route.params.id}`, this.editFields)
          .then((response) => {
            this.items.push(response.data);
          })
          .catch((error) => {
            bus.$emit('error', this.$error.getMsg(error));
          });
      }
      this.dialog = false;
    },
    getVendorLink(item) {
      const id = item.vendor._id;
      return `/vendor/${id}`;
    },
    openOrderDialog(item) {
      this.editedIndex = this.items.indexOf(item);
      this.poEditFields.price = item.price;
      this.poDialog = true;
    },
    createPO() {
      const item = this.items[this.editedIndex];
      const payload = {
        ...this.poEditFields,
        price: item.price,
        vat: item.vat,
      };
      this.$http
        .post(`/po/${item._id}`, payload)
        .then((response) => {
          this.$router.push(`/po/${response.data._id}`);
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        });
    },
  },
};
</script>
