<template>
  <div class="mt-7 ml-7">
    <a href="/utility/unlinked-products">未Link产品</a>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>
