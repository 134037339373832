<template>
  <div>
    <v-card flat>
      <v-card-title>
        新产品
        <v-spacer />

        <v-btn
          color="primary"
          type="submit"
          form="createForm"
          :disabled="!valid"
        >
          创建
        </v-btn>
      </v-card-title>
    </v-card>
    <v-container>
      <v-divider class="mb-4" />

      <v-form
        v-model="valid"
        id="createForm"
        form="createForm"
        ref="createForm"
        @submit.prevent="createProduct()"
      >
        <v-row>
          <v-col cols="6" class="mb-n5">
            <v-text-field
              v-model.trim="product.sku"
              label="产品序号*"
              name="sku"
              type="text"
              :rules="rule.sku"
              outlined
            />
          </v-col>
          <v-col cols="6" class="mb-n5">
            <v-text-field
              v-model.trim="product.name"
              label="产品名*"
              name="name"
              type="text"
              :rules="rule.notEmpty"
              outlined
            />
          </v-col>
          <v-col cols="4" class="mb-n5">
            <v-text-field
              v-model.number="product.price"
              label="产品单价"
              name="price"
              type="number"
              step="0.01"
              prefix="¥"
              outlined
          /></v-col>
          <v-col cols="4" class="mb-n5">
            <v-text-field
              v-model.number="product.unit"
              :rules="rule.unit"
              label="单位"
              name="unit"
              outlined
            />
          </v-col>
          <v-col cols="4" class="mb-n5">
            <v-text-field
              v-model.number="product.qtyPerCase"
              label="装箱量*"
              name="qtyPerCase"
              type="number"
              step="1"
              :rules="rule.positiveInteger"
              outlined
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" class="mb-n6">
            <v-text-field
              v-model.number="product.length"
              label="箱装长度"
              name="length"
              type="number"
              step="0.01"
              suffix="cm"
              outlined
            />
          </v-col>
          <v-col cols="4" class="mb-n6">
            <v-text-field
              v-model.number="product.width"
              label="箱装宽度"
              name="width"
              type="number"
              step="0.01"
              suffix="cm"
              outlined
            />
          </v-col>
          <v-col cols="4" class="mb-n6">
            <v-text-field
              v-model.number="product.height"
              label="箱装高度"
              name="height"
              type="number"
              step="0.01"
              suffix="cm"
              outlined
            />
          </v-col>
          <v-col cols="6" md="4" class="mb-n6">
            <v-text-field
              v-model="product.weight"
              label="箱装重量"
              name="weight"
              type="number"
              step="0.01"
              suffix="kg"
              :rules="rule.positiveFloat"
              outlined
            />
          </v-col>

          <v-col cols="6" md="4" class="mb-n6">
            <v-text-field
              v-model.trim="product.upc"
              label="UPC*"
              name="upc"
              :rules="rule.upc"
              outlined
              prepend-inner-icon="mdi-barcode"
            />
          </v-col>
          <v-col cols="4" class="mb-n6">
            <v-text-field
              v-model.number="product.customTax"
              label="关税"
              name="height"
              type="number"
              step="0.01"
              suffix="%"
              outlined
            />
          </v-col>
          <v-col col="6">
            体积：{{ getVolume }} m<sup>3</sup> / {{ getVolumeSF }} f<sup
              >3</sup
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              v-model="product.note"
              name="note"
              outlined
              label="备注"
              auto-grow
              prepend-inner-icon="mdi-note"
            />
          </v-col>
        </v-row>
        <v-row v-if="$user.data.isAdmin"><v-col>
          <CompanySelection
                    :value.sync="product.company"
                    :rules="rule.company" /></v-col></v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import CompanySelection from '@/components/CompanySelection.vue';
import bus from '@/eventBus';
import rule from '@/library/rule';

export default {
  components: { CompanySelection },
  data: () => ({
    valid: false,
    loading: false,
    companyItems: [],
    product: {
      sku: '',
      name: '',
      upc: '',
      unit: 'EA',
      price: 0,
      length: 0,
      width: 0,
      height: 0,
      weight: 0,
      qtyPerCase: 0,
      note: '',
      customTax: 0,
    },
    rule,
  }),
  computed: {
    getVolume() {
      const { length, width, height } = this.product;
      return ((length * width * height) / 1000000).toFixed(3);
    },
    getVolumeSF() {
      const { length, width, height } = this.product;
      return ((length * width * height * 10.76391041671) / 1000000).toFixed(3);
    },
  },
  created() {
  },
  mounted() {
    this.$refs.createForm.validate();
  },
  methods: {
    createProduct() {
      this.$http
        .post('/product', this.product)
        .then((response) => {
          bus.$emit('success', '创造成功');
          this.$router.push(`/product/${response.data._id}`);
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        });
    },
  },
};
</script>
