import LazyLoad from '@/router/lazyload';

const routes = [
  {
    path: '/user',
    name: 'Users',
    component: LazyLoad('User/Users'),
    meta: { title: '用户' },
  },
  {
    path: '/user/new',
    name: 'CreateUser',
    component: LazyLoad('User/CreateUser'),
    meta: { title: '新用户' },
  },
  { path: '/user/:id', name: 'ViewEditUser', component: LazyLoad('User/ViewEditUser') },
];

export default routes;
