<template>
  <div>
    <v-card flat>
      <v-card-title>
        {{ this.shipment.summary.num }} -
        <span class="red--text ms-2 mr-2">{{ this.shipment.summary.cid }} </span>
        ({{ this.shipment.summary.totalNumCase }} cs) - Seal:
        {{ this.shipment.summary.seal }}
      </v-card-title>
      <v-divider class="mt-n3" />
    </v-card>
    <v-container fluid>
      <span>
        <v-row class="mt-n4">
          <v-col col="4">
            <span class="text-subtitle-1">Ship Date: </span>
            <span class="text-h6 font-weight-bold">
              {{ this.shipment.summary.shipDate }}
            </span></v-col
          >
          <v-col col="4">
            <span class="text-subtitle-1">Arrive Date : </span>
            <span class="text-h6 font-weight-bold">
              {{ this.shipment.summary.arrivalDate }}
            </span></v-col
          >
        </v-row>
        <v-row class="mt-n4">
          <v-col v-if="shipment.summary.note">
            <span class="text-subtitle-1">备注: </span>
            <span class="text-h6 font-weight-bold">{{
              this.shipment.summary.note
            }}</span>
          </v-col>
        </v-row>
      </span>
      <v-data-table
        :headers="getHeaders()"
        :items="shipment.shipmentItems"
        class="elevation-1 mt-5 ml-5"
        disable-pagination
        hide-default-footer
      >
        <template v-slot:top>
          <v-toolbar flat color="">
            <v-toolbar-title>Products</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-divider />
        </template>
        <template v-slot:[`item.img`]="{ item }">
          <img
            v-if="!!item.mainImage"
            :src="'/api/file/image/thumbnail/' + item.mainImage"
            @load="imageLoaded"
            @error="imageLoaded"
          />
        </template>
        <template v-slot:[`item.sku`]="{ item }">
          {{ item.sku }}
        </template>
        <!-- <template v-slot:[`item.qtyPerCase`]="{ item }">
          {{ item.qtyPerCase }} {{ item.unit }}
        </template> -->
        <template v-slot:[`item.vat`]="{ item }"> {{ item.vat }}% </template>
        <template v-slot:[`item.weight`]="{ item }">
          {{ item.weight }} kg
        </template>
        <template v-slot:[`item.price`]="{ item }">
          {{ randomInt() }}{{ item.price }}
        </template>
        <template v-slot:[`item.priceUSD`]="{ item }">
          <span>{{ randomInt() }}{{ getItemPriceUSD(item) }}</span>
        </template>
        <template v-slot:[`item.total`]="{ item }">
          {{ randomInt() }}{{ getItemTotal(item) }}
        </template>
        <template v-slot:[`item.dimension`]="{ item }">
          {{ dimensionString(item) }}
        </template>
        <template v-slot:[`item.OMSInstockCase`]="{ item }">
            {{ item.OMSInstockCase }}
            <span v-if="item.OMSInstockCase>item.numCase">★</span>
          </template>
      </v-data-table>
      <div class="mt-4 ms-2" v-if="displaySummary">
        <v-row>
          <v-col cols="6">
            <span class="text-subtitle-1">海运费: </span>
            <span class="text-h6 font-weight-bold">
              $ {{ shipment.summary.shipping2 }}</span
            ></v-col
          >
          <v-col cols="6">
            <span class="text-subtitle-1">内陆费: </span>
            <span class="text-h6 font-weight-bold">
              ¥ {{ shipment.summary.shipping }}</span
            ></v-col
          >
        </v-row>
        <v-row
          ><v-col cols="6">
            <span class="text-subtitle-1">总编码: </span>
            <span class="text-h6 font-weight-bold">
              {{ randomInt() }}{{ shipment.summary.subtotal }}</span
            ></v-col
          ></v-row
        >
      </div>
      <!-- <ShipmentSummary
        style="font-size: 70%"
        v-if="displaySummary2"
        :shipment="shipment.summary"
        :shipmentItems="shipment.shipmentItems"
      /> -->
    </v-container>
  </div>
</template>
<style scoped>
.bfont {
  font-size: 150%;
}
img {
  max-height: 60px;
  width: auto;
}
</style>
<script>
import bus from '@/eventBus';
// import ShipmentSummary from '@/components/Shipment/ShipmentSummary.vue';

export default {
  // components: { ShipmentSummary },
  data: () => ({
    initializing: true,
    pricePrefix: '￥',
    numberOfImgagesLoaded: 0,
    numberOfImagesLoadNeeded: 0,
    headers: [
      { text: 'SKU', align: 'center', value: 'sku' },
      { text: 'Picture', value: 'img', sortable: false },
      { text: 'Name', value: 'name' },
      { text: 'Qty', value: 'numCase' },
      { text: '编码', value: 'price' },
      { text: '其他', value: 'fee' },
      { text: '总编码', value: 'total' },
      { text: 'Dimen(cm)', value: 'dimension' },
      { text: 'Weight', value: 'weight' },
      { text: '税点', value: 'vat' },
      { text: 'UPC', value: 'upc' },
      { text: 'Qty/CS', value: 'qtyPerCase' },
      { text: 'PO', value: 'poNum' },
      { text: '', value: 'priceUSD' },
      { text: 'Instock', value: 'OMSInstockCase' },
      { text: 'Current Loc', value: 'loc' },
      { text: 'New Loc', value: 'newloc' },
    ],
    toDisplay: [],
    menu: false,
    displaySummary: false,
    displaySummary2: false,
    shipment: {
      summary: {
        cid: '',
        name: '',
        shippingMethod: '',
        subtotal: 0,
        totalWeight: 0,
        locked: false,
        shipDate: undefined,
        shipping: 0,
        custom: 0,
        _id: 0,
        company: undefined,
        totalVolume: 0,
        totalNumCase: 0,
        numOfProducts: 0,
        shippingRate: 1,
        exchangeRate: 1,
        note: '',
        createdDate: undefined,
        __v: 0,
      },
      shipmentItems: [],
    },
  }),
  computed: {
    totalCost() {
      const subtotal = parseFloat(this.subtotal);
      const total = subtotal
        + this.shipment.summary.shipping
        + this.shipment.summary.shipping2
        + this.shipment.summary.tax
        + this.shipment.summary.otherfee;
      return total.toFixed(2);
    },
    totalNumCase() {
      let total = 0;
      this.shipment.shipmentItems.forEach((e) => {
        total += e.numCase;
      });
      return total;
    },
    subtotal() {
      let total = 0;
      this.shipment.shipmentItems.forEach((e) => {
        total += e.numCase * e.qtyPerCase * e.price + e.fee;
      });
      return total.toFixed(2);
    },
    totalWeight() {
      let total = 0;
      this.shipment.shipmentItems.forEach((e) => {
        total += e.numCase * e.weight;
      });
      return total.toFixed(3);
    },
    totalVolume() {
      let total = 0;
      this.shipment.shipmentItems.forEach((e) => {
        total += e.numCase * e.length * e.width * e.height;
      });
      return total.toFixed(3);
    },
  },

  created() {
    window.onafterprint = window.close;
    this.fetch();
    let fields = this.$route.query.f;
    fields = fields || '';
    this.fields = fields.toLowerCase().split(',');
    this.displaySummary = this.$route.query.s === '1';
    this.displaySummary2 = this.$route.query.s2 === '1';
    this.tab = this.$route.query.tab;
    this.initializing = false;
  },
  methods: {
    refresh() {
      this.refreshing = true;
      /* eslint-disable */
      window.confirm('确定要刷新?') && this.fetch();
      /* eslint-enable */
    },

    fetch() {
      this.loading = true;
      this.$http
        .get(`/shipment/${this.$route.params.id}`)
        .then((response) => {
          this.shipment = response.data;
          this.processImages();
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    processImages() {
      this.shipment.shipmentItems.forEach((e) => {
        if (e.mainImage) this.numberOfImagesLoadNeeded += 1;
      });
    },
    imageLoaded(e) {
      this.numberOfImgagesLoaded += 1;
      if (this.numberOfImgagesLoaded >= this.numberOfImagesLoadNeeded) {
        window.print();
      }
    },
    getHeaders() {
      const headers = [];
      this.headers.forEach((e) => {
        if (this.fields.indexOf(e.value.toLowerCase()) >= 0) {
          headers.push(e);
        }
      });
      return headers;
    },
    formatDate(v) {
      if (!v) return '';
      const d = v.substr(0, 10).split('-');
      return `${d[1]} / ${d[2]} / ${d[0].substr(2)}`;
    },
    dimensionString(item) {
      const { length, width, height } = item;
      return `${length}*${width}*${height}`;
    },
    randomInt() {
      return this.getRndInteger(1, 9);
    },
    getRndInteger(min, max) {
      return Math.floor(Math.random() * (max - min)) + min;
    },
    getItemTotal(item) {
      let total = item.price * item.qtyPerCase * item.numCase;
      total *= 1 + item.vat / 100;
      total += item.fee;
      return total.toFixed(2);
    },
    getItemPriceUSD(item) {
      const volumeInCubicMeter = (item.length * item.width * item.height) / 1000000;
      const { exchangeRate, shippingRate } = this.shipment.summary;
      const itemPrice = item.price / exchangeRate;
      const shippingCost = (volumeInCubicMeter * shippingRate) / item.qtyPerCase;
      const otherfee = item.fee / item.numCase / exchangeRate / item.qtyPerCase;
      const costUSD = itemPrice + shippingCost + otherfee;
      if (Number.isNaN(costUSD)) return 0;
      return costUSD.toFixed(2);
    },
  },
};
</script>
