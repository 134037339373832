<template>
  <div>
    <v-card flat>
      <v-tabs v-model="tab">
        <v-tab href="#1">资料</v-tab>
        <v-tab href="#2">产品</v-tab>
        <!-- <v-tab href="#3">图片({{ item.images.length }})</v-tab> -->
      </v-tabs>
      <v-card-title>
        供应商 - {{ item.name }}
        <v-spacer />
        <v-btn
          v-if="editMode && tab == '1'"
          color="primary"
          class="mr-6"
          :loading="saving"
          type="submit"
          form="editForm"
          :disabled="!valid"
        >
          <v-icon>mdi-content-save</v-icon>保存
        </v-btn>
        <v-switch
          v-if="tab == '1'"
          v-model="editMode"
          label="修改模式"
          class="mr-5"
          @change="updateRoute"
        />
      </v-card-title>
      <v-divider class="mt-n5" />
      <v-tabs-items v-model="tab">
        <v-tab-item value="1">
          <v-container>
            <div>
              <v-form
                id="editForm"
                v-model="valid"
                @submit.prevent="saveEdit()"
              >
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="item.name"
                      label="名字"
                      name="name"
                      type="text"
                      :rules="rule.name"
                      :readonly="!editMode"
                      :background-color="textInputBgColor"
                      outlined
                    />
                  </v-col>
                </v-row>
                <v-row class="mt-n7">
                  <v-col md="6">
                    <v-text-field
                      v-model.trim="item.address"
                      label="地址"
                      name="address"
                      :readonly="!editMode"
                      :background-color="textInputBgColor"
                      outlined
                  /></v-col>
                  <v-col md="6">
                    <v-text-field
                      v-model.trim="item.address2"
                      label="地址2"
                      name="address"
                      :readonly="!editMode"
                      :background-color="textInputBgColor"
                      outlined
                  /></v-col>
                </v-row>
                <v-row class="mt-n7">
                  <v-col md="6">
                    <v-text-field
                      v-model.trim="item.email"
                      label="邮件"
                      name="email"
                      :readonly="!editMode"
                      prepend-inner-icon="mdi-email"
                      :background-color="textInputBgColor"
                      outlined
                  /></v-col>
                  <v-col md="6">
                    <v-text-field
                      v-model.trim="item.wechat"
                      label="微信"
                      name="wechat"
                      :readonly="!editMode"
                      :background-color="textInputBgColor"
                      outlined
                  /></v-col>
                </v-row>
                <v-row class="mt-n7">
                  <v-col cols="6" md="4">
                    <v-text-field
                      v-model.trim="item.contactName"
                      label="联系人"
                      name="contact"
                      :readonly="!editMode"
                      :background-color="textInputBgColor"
                      outlined
                  /></v-col>
                  <v-col cols="6" md="4">
                    <v-text-field
                      v-model.trim="item.phone"
                      label="电话"
                      name="phone"
                      :readonly="!editMode"
                      :background-color="textInputBgColor"
                      outlined
                  /></v-col>
                  <v-col cols="6" md="4">
                    <v-text-field
                      v-model.trim="item.phone2"
                      label="电话2"
                      name="phone2"
                      :readonly="!editMode"
                      :background-color="textInputBgColor"
                      outlined
                  /></v-col>
                </v-row>
                <v-row class="mt-n7">
                  <v-col>
                    <v-textarea
                      v-model="item.note"
                      :rules="[
                        (v) => v.length <= 2000 || '备注不能超过2000字符',
                      ]"
                      name="note"
                      counter
                      outlined
                      label="备注"
                      :readonly="!editMode"
                      :background-color="textInputBgColor"
                      auto-grow
                      prepend-inner-icon="mdi-note"
                    />
                  </v-col>
                </v-row>
              </v-form>
              <v-data-table
                :headers="headers"
                :items="orderHistory"
                :loading="orderHistoryLoading"
                hide-default-footer
                :items-per-page="pagination.rowsPerPage"
                class="elevation-1 mt-5"
              >
                <template v-slot:top>
                  <v-toolbar flat color="blue-grey lighten-2 ">
                    <v-toolbar-title>订单历史</v-toolbar-title>
                  </v-toolbar>
                </template>
                <template v-slot:no-data> 无订单记录 </template>
                <template v-slot:[`item.price`]="{ item }">
                  ¥ {{ item.price }}
                </template>
                <template v-slot:[`item.startDate`]="{ item }">
                  {{ formatDate(item.startDate) }}
                </template>
                <template v-slot:[`item.endDate`]="{ item }">
                  {{ formatDate(item.endDate) }}
                </template>
                <template v-slot:[`item.qtyRemain`]="{ item }">
                  {{ item.qty - item.qtyReceived }}
                </template>
                <template v-slot:[`item.product.sku`]="{ item }">
                  <a :href="`/product/` + item.product._id">
                    {{ item.product.sku }}</a
                  >
                </template>
                <template v-slot:[`item.num`]="{ item }">
                  <a :href="`/po/` + item._id"> {{ item.num }}</a>
                </template>
                <template v-slot:[`item.image`]="{ item }">
                  <v-img
                    v-if="!!item.product.mainImage"
                    :src="'/api/file/image/thumbnail/' + item.product.mainImage"
                    lazy-src="/img/placeholder.png"
                    max-height="100"
                    max-width="100"
                    contain
                  />
                </template>
              </v-data-table>

              <v-pagination
                :value="pagination.page"
                :length="pages"
                :total-visible="12"
                circle
                @input="paginationChangeHandler"
              />
            </div>
          </v-container>
        </v-tab-item>
        <v-tab-item value="2">
          <v-container
            ><ProductsFromVendor :id="this.$route.params.id"
          /></v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
  </div>
</template>

<script>
import config from '@/plugins/config';
import bus from '@/eventBus';
import ProductsFromVendor from '@/components/Vendor/ProductsFromVendor.vue';
import rule from '@/library/rule';

export default {
  components: { ProductsFromVendor },
  data: () => ({
    initializing: true,
    tab: null,
    editMode: false,
    saving: false,
    rule,
    valid: false,
    loading: true,
    refreshing: false,
    file: null,
    item: {
      _id: '',
      name: '',
      address: '',
      address2: '',
      contactName: '',
      phone: '',
      phone2: '',
      note: '',
      email: '',
      wechat: '',
      company: 0,
    },
    pagination: { totalDocs: 0, rowsPerPage: 20, page: 1 },
    headers: [
      { text: '单号', value: 'num' },
      { text: 'SKU', value: 'product.sku' },
      { text: '图片', value: 'image' },
      { text: '名字', value: 'product.name' },
      { text: '供应商SKU', value: 'vendorSKU' },
      { text: '价格', value: 'price', sortable: false },
      { text: '箱数', value: 'qty', sortable: false },
      { text: '欠收', value: 'qtyRemain', sortable: false },
      { text: '签订日期', value: 'startDate' },
      { text: '到期日期', value: 'endDate' },
      { text: 'PO号码', value: 'poNum', sortable: false },
      { text: '备注', value: 'note', sortable: false },
    ],
    orderHistory: [],
    orderHistoryLoading: false,
    rules: {
      name: [(value) => value.trim().length > 0 || '名字不能为空'],
      file: [(value) => !value || value.size < 20000000 || '图片大小必须小于 20 MB!'],
    },
  }),
  computed: {
    textInputBgColor() {
      return this.editMode ? config.colors.editedInputBgColor : 'white';
    },
    pages() {
      if (this.pagination.rowsPerPage == null || this.pagination.totalDocs == null) {
        return 0;
      }

      return Math.ceil(this.pagination.totalDocs / this.pagination.rowsPerPage);
    },
  },
  created() {
    const pageNumber = parseInt(this.$route.query.page, 10);
    this.editMode = this.$route.query.emode;
    this.tab = this.$route.query.tab;
    this.pagination.page = Number.isNaN(pageNumber) ? 0 : pageNumber;
    this.fetch();
    this.fetchOrderHistory();
    this.loading = false;
    this.initializing = false;
  },
  methods: {
    refresh() {
      this.refreshing = true;
      /* eslint-disable */
      window.confirm('确定要刷新?') && this.fetch();
      /* eslint-enable */
    },
    saveEdit() {
      this.saving = true;
      this.$http
        .put(`/vendor/${this.$route.params.id}`, this.item)
        .then((response) => {
          this.item = response.data;
          this.$store.commit('updateVendor', response.data);
          bus.$emit('success', '修改成功');
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        })
        .finally(() => {
          this.saving = false;
        });
    },
    fetch() {
      this.loading = true;
      this.$http
        .get(`/vendor/${this.$route.params.id}`)
        .then((response) => {
          this.item = response.data;
          if (this.refreshing) {
            bus.$emit('success', '成功刷新');
            this.refreshing = false;
          }
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        })
        .finally(() => {
          this.loading = false;
          document.title = `供应商 - ${this.item.name}`;
        });
    },
    fetchOrderHistory() {
      const limit = this.pagination.rowsPerPage;
      let offset = (this.pagination.page - 1) * limit;
      if (offset < 0) offset = 0;
      this.loading = true;
      this.$http
        .get(`/po/vendor/${this.$route.params.id}?limit=${limit}&offset=${offset}`)
        .then((response) => {
          this.orderHistory = response.data.docs;
          this.pagination.totalDocs = response.data.totalDocs;
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    paginationChangeHandler(pageNumber) {
      if (this.pagination.page === pageNumber) return;
      this.pagination.page = pageNumber;
      this.fetchOrderHistory();
    },
    editModeChange() {
      const path = `${this.$route.path}?emode=${this.editMode}`;
      this.$router.replace(path);
    },
    updateRoute() {
      if (this.initializing) return;
      const path = `${this.$route.path}?emode=${this.editMode}&tab=${this.tab}`;
      this.$router.replace(path).catch((err) => {});
    },
    goToImageTab() {
      this.tab = '3';
    },
    formatDate(v) {
      if (!v) return '';
      const d = v.substr(0, 10).split('-');
      return `${d[1]} / ${d[2]} / ${d[0].substr(2)}`;
    },
  },
  watch: {
    tab() {
      if (this.initializing) return;
      this.updateRoute();
    },
  },
};
</script>
