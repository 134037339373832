<template>
  <v-autocomplete
    v-model="selected"
    :items="items"
    :loading="isLoading"
    :search-input.sync="search"
    clearable
    hide-details
    hide-no-data
    prepend-inner-icon="mdi-database-search"
    item-text="name"
    item-value="_id"
    return-object
    label="搜索供应商"
    :rules="[(v) => !!v || '不能为空']"
    solo
    v-on:keyup.enter="enterEvent"
  >
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title>
          输入<strong>供应商名字</strong>进行搜索
        </v-list-item-title>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
// import bus from '@/eventBus';

export default {
  props: {
    default: { type: String, default: '' },
  },
  data() {
    return {
      isLoading: false,
      selected: null,
      search: null,
    };
  },
  watch: {
    selected(val) {
      let payload = null;
      if (val) payload = val._id;
      this.$emit('update:input', payload);
    },
    default(val) {
      this.updateInput(val);
    },
  },
  computed: {
    items() {
      return this.$store.getters.vendorList;
    },
  },
  created() {
    this.$store.dispatch('initializeVendors', this);
    this.updateInput(this.default);
  },
  methods: {
    updateInput(val) {
      for (let i = 0; i < this.items.length; i += 1) {
        const item = this.items[i];
        if (item._id === val) {
          this.selected = item;
          break;
        }
      }
    },
  },
};
</script>
