import PurchaseOrders from '@/views/PurchaseOrder/PurchaseOrders.vue';
import ViewEditPurchaseOrder from '@/views/PurchaseOrder/ViewEditPurchaseOrder.vue';

const routes = [
  {
    path: '/po',
    name: 'PurchaseOrders',
    component: PurchaseOrders,
    meta: { title: '订单' },
  },
  { path: '/po/:id', name: 'ViewEditPurchaseOrder', component: ViewEditPurchaseOrder },
];

export default routes;
