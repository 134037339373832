<template>
  <div>
    <v-data-table
      :headers="getHeaders"
      :items="items"
      sort-by="sku"
      class="elevation-1"
      disable-pagination
      hide-default-footer
    >
      <template v-slot:top>
        <v-toolbar flat color="blue-grey lighten-2 ">
          <v-toolbar-title>产品</v-toolbar-title>
          <v-divider class="mx-4" inset vertical />
          <v-spacer />
          <v-switch
            v-model="marginMode"
            label="报价模式"
            v-if="isAdmin()"
            class="mr-5 mb-n4"
          />
          <v-btn
            v-if="!readOnly"
            fab
            small
            color="blue darken-2"
            dark
            @click="resetAndOpenDiaglog"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-dialog v-model="dialog" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-form
                    v-model="valid"
                    id="orderItemForm"
                    @submit.prevent="save"
                  >
                    <v-row v-if="!isEditMode">
                      <v-col cols="12" align="center" justify="center">
                        <ProductSearchAutoComplete :input.sync="editedItem" />
                      </v-col>
                    </v-row>
                    <v-row justify="center" align="center">
                      <v-col cols="6">
                        <v-text-field
                          v-model.number="editFields.price"
                          label="价格"
                          name="price"
                          type="number"
                          outlined
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model.number="editFields.numCase"
                          label="箱数"
                          name="numCase"
                          type="number"
                          step="1"
                          :rules="rule.positiveInteger"
                          outlined
                        />
                      </v-col>
                    </v-row>
                    <v-row justify="center" align="center" class="mt-n5">
                      <v-col cols="6">
                        <v-text-field
                          v-model.number="editFields.fee"
                          label="额外费用"
                          name="fee"
                          type="number"
                          step="1"
                          outlined
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model.number="editFields.poNum"
                          label="PO号码"
                          name="poNum"
                          type="number"
                          step="1"
                          :rules="rule.integer"
                          outlined
                        />
                      </v-col>
                    </v-row>
                    <v-row justify="center" align="center" class="mt-n5">
                      <v-col cols="6">
                        <v-text-field
                          v-model.number="editFields.vat"
                          label="退税点数"
                          name="vat"
                          type="number"
                          suffix="%"
                          :rules="rule.positiveFloat"
                          outlined
                        />
                      </v-col>
                      <v-col cols="6">
                        <!-- <v-text-field
                          v-model.number="editFields.vat"
                          label=""
                          name="vat"
                          type="number"
                          suffix="%"
                          :rules="rule.positiveFloat"
                          outlined
                        /> -->
                      <v-select
                              v-model="editFields.taxReturnCalcType"
                              :items="taxReturnCalcType"
                              item-text="name"
                              item-value="value"
                              outlined
                              no-data-text="无数据"
                              label="退税计算方式"
                              :background-color="textInputBgColor"
                            />
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model.number="editFields.customTax"
                          label="关税"
                          name="vat"
                          type="number"
                          suffix="%"
                          :rules="rule.positiveFloat"
                          outlined
                        />
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="blue darken-1" text @click="close"> 取消 </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  type="submit"
                  form="orderItemForm"
                  :disabled="!canSave"
                >
                  保存
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="marginDialog" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="headline">设置</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-form
                    v-model="marginValid"
                    id="marginForm"
                    @submit.prevent="saveMargin"
                  >
                    <v-row justify="center" align="center">
                      <v-col cols="6">
                        <v-text-field
                          v-model.number="editMarginFields.flatMargin"
                          label="固定利润"
                          name="price"
                          prefix="$"
                          :rules="rule.positiveFloat"
                          type="number"
                          outlined
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model.number="editMarginFields.percentMargin"
                          label="%利润"
                          name="price"
                          :rules="rule.positiveFloat"
                          suffix="%"
                          type="number"
                          outlined
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model.number="editMarginFields.marginMultiplier"
                          label="倍数"
                          name="marginMultiplier"
                          :rules="rule.positiveInteger"
                          type="number"
                          outlined
                        />
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="blue darken-1"
                  text
                  @click="closeEditMarginDialog"
                >
                  取消
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  type="submit"
                  form="marginForm"
                  :disabled="!marginValid"
                >
                  保存
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.sku`]="{ item }">
        <a :href="getProductLink(item)">{{ item.sku }} </a>
      </template>
      <template v-slot:[`item.price`]="{ item }">
        ¥ {{ item.price }}
        <span
          :class="[
            priceGoneUp(item.price, item.previousPrice)
              ? 'red--text'
              : 'green--text',
          ]"
        >
          {{ displayPriceChange(item.price, item.previousPrice) }}
        </span>
      </template>
      <template v-slot:[`item.actualPrice`]="{ item }">
        ¥ {{ getActualPrice(item) }}
      </template>
      <template v-slot:[`item.taxReturnCalcType`]="{ item }">
        <span v-if="item.vat >0">
          <span v-if="item.taxReturnCalcType ===0">X</span>
          <span v-else>/</span>
        </span>
      </template>
      <template v-slot:[`item.mainImage`]="{ item }">
        <a :href="`/api/file/image/${item.mainImage}`">
          <v-img
            v-if="!!item.mainImage"
            :src="'/api/file/image/thumbnail/' + item.mainImage"
            lazy-src="/img/placeholder.png"
            max-height="150"
            max-width="150"
            contain
          />
        </a>
      </template>
      <template v-slot:[`item.subtotal`]="{ item }">
        ¥
        {{
          numberWithComma(
            (item.numCase * item.qtyPerCase * item.price + item.fee),2
          )
        }}
      </template>
      <template v-slot:[`item.weight`]="{ item }">
        {{ item.weight.toFixed(2) }} kg
      </template>
      <template v-slot:[`item.cost`]="{ item }">
        $ {{ getItemPriceUSD(item) }}
        <span
          :class="[
            priceGoneUp(getItemPriceUSD(item), item.previousPriceUSD)
              ? 'red--text'
              : 'green--text',
          ]"
        >
          {{ displayPriceChange(getItemPriceUSD(item), item.previousPriceUSD) }}
        </span>
      </template>
      <template v-slot:[`item.fee`]="{ item }"> ¥ {{ item.fee }} </template>
      <template v-slot:[`item.vat`]="{ item }"> {{ item.vat }}% </template>
      <template v-slot:[`item.volume`]="{ item }">
        <span :class="[itemVolume(item) > 0 ? '' : 'red--text']">
          {{ itemVolume(item) }}
        </span>
      </template>
      <template v-slot:[`item.postvat`]="{ item }"
        ><!--税后汇总-->
        {{ itemTotalAfterVAT(item) }}
      </template>
      <template v-slot:[`item.vatProfit`]="{ item }"
        >
        <span v-if="item.vat >0">
            ¥{{ taxReturnProfit(item) }}
        </span>
      </template>
      <template v-slot:[`item.totalWeight`]="{ item }">
        {{ (item.numCase * item.weight).toFixed(2) }} kg
      </template>
      <template v-slot:[`item.customTax`]="{ item }">
        {{ item.customTax }} %
      </template>
      <template v-slot:[`item.flatMargin`]="{ item }">
        $ {{ item.flatMargin }}
      </template>
      <template v-slot:[`item.percentMargin`]="{ item }">
        {{ item.percentMargin }} %
      </template>
      <template v-slot:[`item.flatMarginPrice`]="{ item }">
        $ {{ getFlatMarginPrice(item) }}
        <span class="blue--text">
          {{ getPriceChange(getFlatMarginPrice(item), getItemPriceUSD(item)) }}
        </span>
      </template>
      <template v-slot:[`item.percentMarginPrice`]="{ item }">
        $ {{ getPercentMarginPrice(item) }}
        <span class="blue--text">
          {{
            getPriceChange(getPercentMarginPrice(item), getItemPriceUSD(item))
          }}
        </span>
      </template>
      <template v-slot:[`item.totalVolume`]="{ item }">
        {{
          (
            (item.numCase * item.length * item.width * item.height) /
            1000000
          ).toFixed(2)
        }}
        m<sup>3</sup>
      </template>
      <template v-if="!readOnly" v-slot:[`item.action`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:[`item.margin`]="{ item }">
        <v-icon small class="mr-2" @click="editMargin(item)"> mdi-eye </v-icon>
      </template>
      <template v-slot:[`body.append`]="{ headers }">
        <tr v-if="!readOnly" class="blue-grey lighten-4">
          <td :colspan="headers.length" align="center">
            <v-btn
              fab
              small
              color="blue darken-2"
              dark
              @click="resetAndOpenDiaglog"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
      <template v-slot:no-data> 请添加产品 </template>
    </v-data-table>
  </div>
</template>

<script>
import ProductSearchAutoComplete from '@/components/ProductSearchAutoComplete.vue';
import rule from '@/library/rule';
import bus from '@/eventBus';
import { Formatter } from '@/library/utility';

export default {
  components: { ProductSearchAutoComplete },
  props: {
    items: Array,
    readOnly: Boolean,
    exchangeRate: Number,
    shippingRate: Number,
    taxReturn: Number,
    taxReturnFee: Number,
  },
  data: () => ({
    marginMode: false,
    valid: false,
    dialog: false,
    marginDialog: false,
    marginValid: false,
    rule,
    pricePrefix: '￥',
    headers: [
      { text: '产品序号', align: 'center', value: 'sku' },
      { text: '名字', value: 'name' },
      { text: '图片', value: 'mainImage', sortable: false },
      { text: '箱数', value: 'numCase' },
      { text: '装箱', value: 'qtyPerCase' },
      { text: '单价', value: 'price' },
      { text: '额外费用', value: 'fee', sortable: false },
      { text: '体积', value: 'volume' },
      { text: '产品汇总', value: 'subtotal' },
      { text: '关税', value: 'customTax', sortable: false },
      { text: '成本', value: 'cost', sortable: false },
      { text: 'PO号码', value: 'poNum', sortable: false },
      { text: '', value: 'action', sortable: false },
    ],
    marginHeaders: [
      { text: '产品序号', align: 'center', value: 'sku' },
      { text: '名字', value: 'name' },
      { text: '图片', value: 'mainImage', sortable: false },
      { text: '箱数', value: 'numCase' },
      { text: '装箱', value: 'qtyPerCase' },
      { text: '单价', value: 'price' },
      { text: '体积', value: 'volume' },
      { text: '关税', value: 'customTax', sortable: false },
      { text: '利润', value: 'flatMargin', sortable: false },
      { text: '%利润', value: 'percentMargin', sortable: false },
      { text: '倍数', value: 'marginMultiplier', sortable: false },
      { text: '成本', value: 'cost', sortable: false },
      { text: '定价', value: 'flatMarginPrice', sortable: false },
      { text: '%定价', value: 'percentMarginPrice', sortable: false },
      { text: '', value: 'margin', sortable: false },
    ],
    taxAdditiveHeaders: [
      { text: '退税利润',value: 'vatProfit' },
      { text: '退税计算方式',value: 'taxReturnCalcType' },
      { text: '税后汇总', value: 'postvat' },
      { text: '退税',value: 'vat' },
    ],
    summaryHeaders: [
      { text: '总箱数', align: 'center', value: 'totalNumCase' },
      { text: '总价格', value: 'totalPrice' },
      { text: '总重量', value: 'totalWeight' },
      { text: '体积', value: 'totalVolume' },
    ],
    taxReturnCalcType:
      [{ value: 0,name: 'x' },
      { value: 1, name: '/' }],
    editedIndex: -1,
    editedMarginIndex: -1,
    editedItem: null,
    editFields: {
      fee: 0,
      price: 0,
      numCase: 0,
      poNum: 0,
      vat: 0,
      customTax: 0,
      taxReturnCalcType: 0,
      
    },
    editMarginFields: {
      flatMargin: 0,
      percentMargin: 0,
      marginMultiplier: 1,
    },
    editedMarginProductId: undefined,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? '增加' : '修改';
    },
    canSave() {
      if (this.editFields.numCase < 1) return false;
      if (!this.editedItem) return false;
      const validNumCase = Number.isInteger(this.editFields.numCase);
      const validpoNum = Number.isInteger(this.editFields.poNum);
      return validNumCase && validpoNum;
    },
    isEditMode() {
      return this.editedIndex >= 0;
    },
    getHeaders() {
      if (this.marginMode) {
        return this.marginHeaders;
      }

      // tax additive headers will only be added if at least one of the
      // items has tax return
      for (let i = 0; i < this.items.length; i += 1) {
        const { vat } = this.items[i];
        if (Math.abs(vat - 0) > 0.001) {
          const headerSize = this.headers.length;
          const tempHeaders = JSON.parse(JSON.stringify(this.headers));
          for (let j = 0; j < this.taxAdditiveHeaders.length; j += 1) {
            tempHeaders.splice(headerSize - 1, 0, this.taxAdditiveHeaders[j]);
          }
          return tempHeaders;
        }
      }
      return this.headers;
    },
  },

  watch: {
    dialog(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.close();
    },
    editedItem(val) {
      if (val) {
        if (this.editedIndex === -1) {
          this.$http
            .get(`/product/${val}`)
            .then((response) => {
              const { data } = response;
              this.editFields.price = data.price;
              this.editFields.customTax = data.customTax;
            })
            .catch((error) => {
              bus.$emit('error', this.$error.getMsg(error));
            });
        }
      }
    },
  },
  created() {},
  methods: {
    // transfer item data to edit dialog
    editItem(item) {
      this.editedIndex = -1;
      // this.editedIndex = this.items.indexOf(item);
      for (let i = 0; i < this.items.length; i += 1) {
        if (item._id === this.items[i]._id) {
          this.editedIndex = i;
        }
      }
      this.editedItem = { ...item };
      this.editFields.numCase = this.editedItem.numCase;
      this.editFields.poNum = this.editedItem.poNum;
      this.editFields.price = this.editedItem.price;
      this.editFields.fee = this.editedItem.fee;
      this.editFields.vat = this.editedItem.vat;
      this.editFields.customTax = this.editedItem.customTax;
      this.editFields.taxReturnCalcType = this.editedItem.taxReturnCalcType;
      this.dialog = true;
    },

    deleteItem(item) {
      /* eslint-disable */
      window.confirm('确定要删除此行?') && this.$emit('delete-item', item);
      /* eslint-enable */
    },

    close() {
      this.dialog = false;
    },
    resetAndOpenDiaglog() {
      this.editedItem = null;
      this.editFields.numCase = 0;
      this.editFields.fee = 0;
      this.editFields.vat = 0;
      this.editFields.poNum = 0;
      this.editFields.price = 0;
      this.editFields.customTax = 0;
      this.editFields.taxReturnCalcType = 0;
      this.editedIndex = -1;
      this.dialog = true;
    },
    closeEditMarginDialog() {
      this.marginDialog = false;
      this.editedMarginProductId = undefined;
    },
    editMargin(item) {
      this.editedMarginProductId = item.product;
      this.editMarginFields.flatMargin = item.flatMargin;
      this.editMarginFields.percentMargin = item.percentMargin;
      this.editMarginFields.marginMultiplier = item.marginMultiplier;
      this.marginDialog = true;
    },
    saveMargin() {
      if (!this.editedMarginProductId) return;
      this.$http
        .put(`/product/${this.editedMarginProductId}`, this.editMarginFields)
        .then((response) => {
          this.item = response.data;
          this.$emit('update-margin', { id: this.editedMarginProductId, ...this.editMarginFields });
          bus.$emit('success', '修改成功');
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        })
        .finally(() => {
          this.marginDialog = false;
        });
    },
    getItemPriceUSD(item) {
      const volumeInCubicMeter = (item.length * item.width * item.height) / 1000000;
      const itemPrice = item.price / this.exchangeRate;
      const tax = itemPrice * (item.customTax / 100);
      const shippingCost = (volumeInCubicMeter * this.shippingRate) / item.qtyPerCase;
      const otherfee = item.fee / item.numCase / this.exchangeRate / item.qtyPerCase;
      let finalPrice = itemPrice + shippingCost + otherfee + tax;
      if (Number.isNaN(finalPrice)) return 0;
      const multiplier = this.marginMode ? item.marginMultiplier : 1;
      finalPrice *= multiplier;
      return Number.parseFloat(finalPrice.toFixed(3));
    },
    getFlatMarginPrice(item) {
      const base = this.getItemPriceUSD(item);
      if (Math.abs(base) < 0.001) return 0;
      const marginCost = item.flatMargin / item.qtyPerCase;
      const finalPrice = base + marginCost * item.marginMultiplier;
      // finalPrice *= item.marginMultiplier;
      return Number.parseFloat(finalPrice.toFixed(3));
    },
    getPercentMarginPrice(item) {
      const base = this.getItemPriceUSD(item);
      if (Math.abs(base) < 0.001) return 0;
      const margin = item.price * (item.percentMargin / 100);
      const marginCost = margin / this.exchangeRate;
      const finalPrice = base + marginCost * item.marginMultiplier;
      return Number.parseFloat(finalPrice.toFixed(3));
    },
    // called when item is edited/created based on editedIndex
    save() {
      if (this.editedIndex > -1) {
        // trigger edit event
        const editedOrderItem = JSON.parse(JSON.stringify(this.editedItem));
        editedOrderItem.numCase = this.editFields.numCase;
        editedOrderItem.poNum = this.editFields.poNum;
        editedOrderItem.price = this.editFields.price;
        editedOrderItem.fee = this.editFields.fee;
        editedOrderItem.vat = this.editFields.vat;
        editedOrderItem.customTax = this.editFields.customTax;
        editedOrderItem.taxReturnCalcType = this.editFields.taxReturnCalcType;
        this.$emit('edit-item', {
          index: this.editedIndex,
          item: editedOrderItem,
        });
      } else {
        // trigger add event
        const orderItem = { product: this.editedItem };
        orderItem.numCase = this.editFields.numCase;
        orderItem.poNum = this.editFields.poNum;
        orderItem.price = this.editFields.price;
        orderItem.fee = this.editFields.fee;
        orderItem.vat = this.editFields.vat;
        orderItem.customTax = this.editFields.customTax;
        orderItem.taxReturnCalcType = this.editFields.taxReturnCalcType;
        this.$emit('add-item', orderItem);
      }
      this.close();
    },
    isAdmin() {
      return this.$user.data.isAdmin;
    },
    getActualPrice(item) {
      const addition = item.fee / item.numCase / item.qtyPerCase;
      const priceWithFee = item.price + addition;
      return Number.parseFloat(priceWithFee.toFixed(2));
    },
    // does not include item additonal fee
    itemTotalAfterVAT(item) {
      if (item.vat < Number.EPSILON) return '不开票';
      let subtotal = item.numCase * item.qtyPerCase * item.price;
      if (item.taxReturnCalcType === 0)
        subtotal *= 1 + item.vat / 100;
      else
        subtotal =subtotal /(1 - item.vat / 100);
      subtotal = this.numberWithComma(subtotal,3)
      return `¥ ${subtotal}`;
    },
    getProductLink(item) {
      const id = item.product ? item.product : item._id;
      return `/product/${id}`;
    },
    displayPriceChange(current, previous) {
      if (!previous) return '';
      const c = Number.parseFloat(current);
      const p = Number.parseFloat(previous);
      const diff = c - p;
      if (Number.isNaN(diff)) return '';
      if (Math.abs(diff) < 0.01) return '';
      const symbol = diff < 0.0001 ? '▼' : '▲';
      return `(${symbol} ${Number.parseFloat(diff.toFixed(3))})`;
    },
    getPriceChange(current, previous) {
      if (!previous) return '';
      const c = Number.parseFloat(current);
      const p = Number.parseFloat(previous);
      const diff = c - p;
      if (Number.isNaN(diff)) return '';
      if (Math.abs(diff) < 0.01) return '';
      return `(${Number.parseFloat(diff.toFixed(3))})`;
    },
    priceGoneUp(current, previous) {
      if (!previous) return false;
      const diff = Number.parseFloat((current - previous).toFixed(2));
      if (diff < 0.001) return false;
      return true;
    },
    itemVolume(item) {
      const { length, width, height } = item;
      const v = (length * width * height) / 1000000;
      return Number.parseFloat(v.toFixed(2));
    },
    taxReturnProfit(item) {
      let productSubtotal = item.numCase * item.qtyPerCase * item.price;
      let productSubtotalWithTax = productSubtotal 
      if (item.taxReturnCalcType === 0) {
        productSubtotalWithTax *= (1 + item.vat / 100);
      } else {
        productSubtotalWithTax /= (1 - item.vat / 100);
      }
      let v1 = 1 + (this.taxReturn) / 100;
      let v2 = (this.taxReturn - this.taxReturnFee)/100;
      let taxReturnAmt = productSubtotalWithTax / v1 * (v2);
      let amount = productSubtotal - productSubtotalWithTax + taxReturnAmt;
      
      
      return this.numberWithComma(amount,2);
    },
    ...Formatter,
  },
};
</script>
