import Utility from '@/views/Utility/Utility.vue';
import UnlinkedProducts from '@/views/Utility/UnlinkedProducts.vue';

const routes = [
  {
    path: '/utility',
    name: 'Utility',
    component: Utility,
    meta: { title: '工具' },
  },
  {
    path: '/utility/unlinked-products',
    name: 'UnlinkedProducts',
    component: UnlinkedProducts,
    meta: { title: '未关联产品' },
  },
];

export default routes;
