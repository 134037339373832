<template>
  <div>
    <v-card>
      <v-card-text>
        <v-card-actions>
          <v-file-input
            v-model="file"
            label="新图片"
            dense
            accept="image/png, image/jpeg, image/bmp"
            prepend-icon="mdi-file-image"
          />
          <v-btn color="primary" @click="upload()" :disabled="isEmptyFile">
            <v-icon>mdi-cloud-upload</v-icon>上传
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
    <v-spacer />
    <v-data-table
      :headers="headers"
      :items="item.images"
      hide-default-footer
      disable-pagination
      class="elevation-1 mt-4"
    >
      <template v-slot:no-data> 无图片 </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-icon large @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <a :href="`/api/file/image/${item.path}`">
          <v-img
            :src="`/api/file/image/thumbnail/${item.path}`"
            max-height="350"
            max-width="350"
            contain
          />
        </a>
      </template>
      <template v-slot:[`item.size`]="{ item }">
        {{ (item.size / 1000).toFixed(2) }} KB
      </template>
    </v-data-table>
  </div>
</template>

<script>
import bus from '@/eventBus';

export default {
  props: {
    item: Object,
  },
  data: () => ({
    file: null,
    uploading: false,
    headers: [
      { text: '名字', value: 'name' },
      { text: '大小', value: 'size' },
      // { text: '上传时间', value: 'addTime' },
      { text: '操作', value: 'action', sortable: false },
    ],
  }),
  created() {},
  methods: {
    upload() {
      this.uploading = true;
      const formData = new FormData();
      formData.append('file', this.file);
      this.$http
        .post(`${this.$route.path}/image`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          // this.item = res.data;
          this.$emit('update-list', res.data);
          bus.$emit('success', '文件上传成功!');
        })
        .catch((err) => {
          bus.$emit('error', `上传失败-${err}`);
        })
        .finally(() => {
          this.uploading = false;
        });
    },
    deleteItem(item) {
      const imageName = encodeURIComponent(item.path);
      /* eslint-disable */
      if (!window.confirm('确定要删除此图片?')) return;
      /* eslint-enable */
      this.$http
        .delete(`${this.$route.path}/image/${imageName}`)
        .then((res) => {
          // const position = this.item.images.indexOf(item);
          // this.item.images.splice(position, 1);
          this.$emit('update-list', res.data);
          bus.$emit('success', '成功删除图片!');
        })
        .catch((err) => {
          bus.$emit('error', `删除失败-${err}`);
        });
    },
  },
  computed: {
    isEmptyFile() {
      return !this.file;
    },
  },
};
</script>
