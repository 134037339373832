<template>
  <div>
    <v-card flat>
      <v-card-title>
        产品
        <v-spacer />
        <router-link to="/product/new">
          <v-btn color="primary"> <v-icon>mdi-plus</v-icon>新产品 </v-btn>
        </router-link>
      </v-card-title>
    </v-card>

    <v-card>
      <v-card-title>
        搜索
        <v-spacer />
        <v-row class="mt-1"
          ><v-col cols="8" md="4">
            <v-select
              v-model="state"
              :items="states"
              label="滤选"
              item-text="text"
              item-value="value"
              outlined
              dense
            ></v-select></v-col
        ></v-row>
        <ProductSearchAutoComplete class="ml-3" :input.sync="editedItem" />
      </v-card-title>
    </v-card>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      hide-default-footer
      :items-per-page="pagination.rowsPerPage"
      class="elevation-1"
    >
      <template v-slot:[`item.sku`]="{ item }">
        <a :href="'/product/' + item._id">{{ item.sku }}</a>
      </template>
      <template v-slot:[`item.state`]="{ item }">
          <v-switch
              v-model="item.state"
              color="red"
              class="mr-5 mb-n4"
              @change="changeProductState(item)"
            />
        </template>
      <template v-slot:[`item.price`]="{ item }"> ¥ {{ item.price }} </template>
      <template v-slot:[`item.qtyPerCase`]="{ item }">
        {{ item.qtyPerCase }} {{ item.unit }}
      </template>
      <template v-slot:[`item.mainImage`]="{ item }">
        <v-img
          v-if="!!item.mainImage"
          :src="'/api/file/image/thumbnail/' + item.mainImage"
          lazy-src="/img/placeholder.png"
          max-height="100"
          max-width="100"
          contain
        />
      </template>
    </v-data-table>

    <v-pagination
      :value="pagination.page"
      :length="pages"
      :total-visible="12"
      circle
      @input="paginationChangeHandler"
    />
  </div>
</template>

<script>
import bus from '@/eventBus';
import ProductSearchAutoComplete from '@/components/ProductSearchAutoComplete.vue';

export default {
  components: { ProductSearchAutoComplete },
  data() {
    return {
      items: [],
      state: 0,
      states: [
        { text: '所有', value: -1 },
        { text: '活络', value: 0 },
        { text: '停产', value: 1 },
      ],
      editedItem: null,
      loading: false,
      pagination: { totalItems: 0, rowsPerPage: 20, page: 1 },
      headers: [
        { text: 'SKU', align: 'center', value: 'sku' },
        { text: '图片', value: 'mainImage', sortable: false },
        { text: '名字', value: 'name', sortable: false },
        { text: '单价', value: 'price' },
        { text: '装箱', value: 'qtyPerCase', sortable: false },
        // { text: '停产', value: 'state', sortable: false },
        { text: '欠收', value: 'openPO', sortable: false },
        { text: '未出', value: 'inStock', sortable: false },
        { text: '路上', value: 'enRoute', sortable: false },
        { text: '库存', value: 'projectedQty', sortable: false },
      ],
    };
  },
  computed: {
    pages() {
      if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null) {
        return 0;
      }

      return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage);
    },
  },
  created() {
    this.state = parseInt(this.$route.query.state, 10) || this.defaultState;
    const pageNumber = parseInt(this.$route.query.page, 10);
    this.pagination.page = Number.isNaN(pageNumber) ? 0 : pageNumber;
    this.fetchProducts();
  },
  methods: {
    fetchProducts() {
      this.loading = true;
      this.state = parseInt(this.$route.query.state, 10) || 0;
      const limit = this.pagination.rowsPerPage;
      let offset = (this.pagination.page - 1) * limit;
      if (offset < 0) offset = 0;

      this.$http
        .get(
          `/product/company/${this.$route.params.id}?limit=${limit}&offset=${offset}&state=${this.state}`,
        )
        .then((response) => {
          this.items = response.data.docs;
          this.pagination.totalItems = response.data.totalDocs;
          // console.log(this.items[0])
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        });
      this.loading = false;
    },

    paginationChangeHandler(pageNumber) {
      if (this.pagination.page === pageNumber) return;
      this.pagination.page = pageNumber;
      this.$router.replace(
        `/product/company/${this.$route.params.id}?page=${pageNumber}&state=${this.state}`,
      );
      this.fetchProducts();
    },
    updateRoute() {
      this.$router
        .replace({
          path: this.$route.path,
          query: {
            page: this.pagination.page,
            state: this.state,
          },
        })
        .catch((err) => {}); // don't care about duplicate navigation error here
    },
    changeProductState(item) {
      const state = item.state ? 1 : 0;
      this.$http
        .put(`/product/${item._id}`, { state })
        .then((response) => {
          this.item = response.data;
          bus.$emit('success', '修改成功');
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        })
        .finally(() => {
        });
    },

  },
  watch: {
    editedItem(val) {
      this.$router.push(`/product/${this.editedItem}`);
    },
    state(val) {
      this.pagination.page = 0;
      this.updateRoute();
      this.fetchProducts();
    },
  },
};
</script>

<style scoed>
tr:nth-of-type(even) {
  background-color: rgba(92, 83, 206, 0.05);
}
</style>
