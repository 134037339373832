<template>
  <div>
    <v-card flat>
      <v-tabs v-model="tab">
        <v-tab href="#1">资料</v-tab>
        <v-tab href="#2">附件({{ order.summary.attachments }})</v-tab>
      </v-tabs>
      <v-card-title>
        集装箱 - {{ this.order.summary.num }} （{{
          this.order.summary.totalNumCase
        }}
        CS）
        <v-tooltip bottom v-if="$user.data.isAdmin" class="ml-2">
          <template v-slot:activator="{ on }">
            <v-btn
              text
              icon
              x-large
              @click="setPOReceived(true)"
              v-if="!order.summary.doReceived"
              v-on="on"
            >
              <v-icon color="red"> mdi-form-select </v-icon>
            </v-btn>
            <v-btn
              text
              icon
              x-large
              @click="setPOReceived(false)"
              v-on="on"
              v-else
            >
              <v-icon color="green"> mdi-form-select </v-icon>
            </v-btn>
          </template>
          <span v-if="!order.summary.doReceived">Delivery Order设为已收</span>
          <span v-else>Delivery Order设为未收</span>
        </v-tooltip>
        <v-tooltip bottom v-if="$user.data.isAdmin" class="ml-2">
          <template v-slot:activator="{ on }">
            <v-btn
              text
              icon
              x-large
              @click="setDelivered(true)"
              v-if="order.summary.state === 0"
              v-on="on"
            >
              <v-icon color="red"> mdi-truck </v-icon>
            </v-btn>
            <v-btn
              text
              icon
              x-large
              @click="setDelivered(false)"
              v-on="on"
              v-else
            >
              <v-icon color="green"> mdi-package-variant-closed </v-icon>
            </v-btn>
          </template>
          <span v-if="order.summary.state === 0">设为已收</span>
          <span v-else>设为未收</span>
        </v-tooltip>
        <!-- cpsc status, always on, admin only -->
        <v-tooltip bottom v-if="$user.data.isAdmin" class="ml-2">
          <template v-slot:activator="{ on }">
            <v-btn
              text
              icon
              x-large
              @click="setCpscReleased(true)"
              v-if="!order.summary.cpscReleased"
              v-on="on"
            >
              <v-icon color="orange"> mdi-alert </v-icon>
            </v-btn>
            <v-btn
              text
              icon
              x-large
              @click="setCpscReleased(false)"
              v-on="on"
              v-else
            >
              <v-icon color="green"> mdi-check </v-icon>
            </v-btn>
          </template>
          <span v-if="!order.summary.cpscReleased">设为cpsc已释放</span>
          <span v-else>设为cpsc未释放</span>
        </v-tooltip>
        <v-tooltip bottom class="ml-2">
          <template v-slot:activator="{ on }">
            <v-btn text icon x-large @click="changeLockStatus" v-on="on">
              <v-icon v-if="order.summary.locked" color="red">
                mdi-lock
              </v-icon>
              <v-icon v-else color="green"> mdi-lock-open-variant </v-icon>
            </v-btn>
          </template>
          <span>封锁状态下将无法修改资料</span>
        </v-tooltip>
        <v-btn text icon x-large @click="printDialog = true">
          <v-icon> mdi-printer </v-icon>
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="editMode && !order.summary.locked && tab == '1'"
          class="mr-6"
          color="primary"
          type="submit"
          form="form"
          :disabled="!valid"
          :loading="saving"
        >
          <v-icon>mdi-content-save</v-icon>保存
        </v-btn>
        <v-switch
          v-if="!order.summary.locked && tab == '1'"
          v-model="editMode"
          label="修改模式"
          class="mr-5"
          @change="updateRoute"
        />
      </v-card-title>
      <v-divider class="mt-n5" />
    </v-card>
    <v-tabs-items v-model="tab">
      <v-tab-item value="1">
        <v-container>
          <v-form
            v-model="valid"
            id="form"
            form="orderForm"
            ref="orderForm"
            @submit.prevent="saveEdit"
          >
            <v-row class="mt-1">
              <v-col cols="12" md="6" class="mb-n6">
                <v-text-field
                  v-model.trim="order.summary.name"
                  :readonly="!editMode"
                  label="名称"
                  :rules="rule.notEmpty"
                  name="name"
                  type="text"
                  outlined
                  :background-color="textInputBgColor"
                />
              </v-col>
              <v-col cols="6" md="3" class="mb-n6">
                <v-text-field
                  v-model.trim="order.summary.cid"
                  :readonly="!editMode"
                  label="集装箱序号*"
                  :rules="rule.notEmpty"
                  name="cid"
                  type="text"
                  outlined
                  :background-color="textInputBgColor"
                />
              </v-col>
              <v-col cols="6" md="3" class="mb-n6">
                <v-text-field
                  v-model.trim="order.summary.seal"
                  :readonly="!editMode"
                  label="封条"
                  :rules="rule.notEmpty"
                  name="seal"
                  type="text"
                  outlined
                  :background-color="textInputBgColor"
                />
              </v-col>
              <v-col cols="12" md="4" class="mb-n6">
                <v-text-field
                  v-model.trim="order.summary.shippingMethod"
                  :readonly="!editMode"
                  label="运输公司/方式"
                  name="shippingMethod"
                  type="text"
                  :rules="[
                    (v) => v.trim().length <= 100 || '运输方式不能多于100字符',
                  ]"
                  outlined
                  :background-color="textInputBgColor"
                />
              </v-col>

              <v-col cols="6" md="4" class="mb-n4">
                <DatePicker
                  label="装柜日期"
                  :clearable="editMode"
                  :date="order.summary.shipDate"
                  :bgColor="textInputBgColor"
                  @update="(v) => (order.summary.shipDate = v)"
                  required
                />
              </v-col>
              <v-col cols="6" md="4" class="mb-n4">
                <DatePicker
                  label="到港日期"
                  :date="order.summary.arrivalDate"
                  :bgColor="textInputBgColor"
                  :clearable="editMode"
                  @update="(v) => (order.summary.arrivalDate = v)"
                />
              </v-col>
              <v-col cols="4" md="2" class="mb-n6">
                <v-text-field
                  v-model.number="order.summary.shipping"
                  label="内陆费"
                  name="shipping"
                  type="number"
                  step="0.01"
                  prefix="¥"
                  :rules="rule.positiveFloat"
                  :readonly="!editMode"
                  outlined
                  :background-color="textInputBgColor"
                />
              </v-col>
              <v-col cols="4" md="2" class="mb-n6">
                <v-text-field
                  v-model.number="order.summary.shipping2"
                  label="海运费"
                  name="shipping2"
                  type="number"
                  step="0.01"
                  prefix="¥"
                  :rules="rule.positiveFloat"
                  :readonly="!editMode"
                  outlined
                  :background-color="textInputBgColor"
                />
              </v-col>
              <v-col cols="4" md="2" class="mb-n6">
                <v-text-field
                  v-model.number="order.summary.otherfee"
                  label="其他费用"
                  name="otherfee"
                  type="number"
                  step="0.01"
                  prefix="¥"
                  :readonly="!editMode"
                  outlined
                  :background-color="textInputBgColor"
                />
              </v-col>
              <v-col cols="4" md="2" class="mb-n6">
                <v-text-field
                  v-model.number="order.summary.shippingRate"
                  label="运费($/平方米)"
                  name="otherfee"
                  type="number"
                  step="0.01"
                  prefix="$"
                  :rules="rule.positiveFloat"
                  :readonly="!editMode"
                  outlined
                  :background-color="textInputBgColor"
                />
              </v-col>
              <v-col cols="4" md="2" class="mb-n6">
                <v-text-field
                  v-model.number="order.summary.exchangeRate"
                  label="汇率"
                  name="exchangeRate"
                  type="number"
                  step="0.01"
                  :rules="rule.positiveFloat"
                  :readonly="!editMode"
                  outlined
                  :background-color="textInputBgColor"
                />
              </v-col>
              <v-col cols="4" md="2" class="mb-n6">
                <v-text-field
                  v-model.number="order.summary.taxReturn"
                  label="政府退税点数"
                  suffix="%"
                  name="taxReturn"
                  type="number"
                  :rules="rule.positiveFloat"
                  :readonly="!editMode"
                  outlined
                  :background-color="textInputBgColor"
                />
              </v-col>
              <v-col cols="4" md="2" class="mb-n6">
                <v-text-field
                  v-model.number="order.summary.taxReturnFee"
                  label="退税手续费点数"
                  name="exchangeRate"
                  type="number"
                  :rules="rule.positiveFloat"
                  suffix="%"
                  :readonly="!editMode"
                  outlined
                  :background-color="textInputBgColor"
                />
              </v-col>
              <v-col cols="4" md="2" class="mb-n6">
                <v-text-field
                  v-model.number="order.summary.refContainerUSDPrice"
                  label="美金海运费"
                  name="exchangeRate"
                  type="number"
                  prefix="$"
                  :rules="rule.positiveFloat"
                  :readonly="!editMode"
                  outlined
                  :background-color="textInputBgColor"
                />
              </v-col>
              <v-col
                cols="6"
                md="4"
                class="mb-n4"
                v-if="order.summary.state === 1"
              >
                <DatePicker
                  label="进仓日期"
                  :date="order.summary.receivedDate"
                  :clearable="editMode"
                  :bgColor="textInputBgColor"
                  @update="(v) => (order.summary.receivedDate = v)"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="order.summary.note"
                  name="note"
                  outlined
                  label="备注"
                  rows="3"
                  auto-grow
                  :rules="[
                    (v) => v.trim().length <= 2000 || '备注不能多于2000字符',
                  ]"
                  :readonly="!editMode"
                  :background-color="textInputBgColor"
                  prepend-inner-icon="mdi-note"
                />
              </v-col>
            </v-row>
          </v-form>
          <div class="mb-4" v-if="order.summary.doReceived">
            DO收到日期:
            {{ formattedDOReceivedDate2(order.summary.doReceivedDate) }}
          </div>
          <OrderItemTable
            :items.sync="order.orderItems"
            :exchangeRate="order.summary.exchangeRate"
            :shippingRate="order.summary.shippingRate"
            :read-only="!editMode"
            :taxReturn="order.summary.taxReturn"
            :taxReturnFee="order.summary.taxReturnFee"
            @add-item="addItem"
            @edit-item="editItem"
            @delete-item="deleteItem"
            @update-margin="updateMargin"
          />
          <OrderSummary :order="order.summary" :orderItems="order.orderItems" />
        </v-container>
      </v-tab-item>
      <v-tab-item value="2">
        <v-container>
          <Attachments />
        </v-container>
      </v-tab-item>
    </v-tabs-items>

    <v-dialog v-model="printDialog" max-width="200px">
      <v-card>
        <v-card-title>
          <span class="headline">打印</span>
        </v-card-title>
        <v-card-text>
          <v-radio-group v-model="printValue" mandatory>
            <v-radio
              :key="r.label"
              v-for="r in printRadioSelections"
              :label="r.label"
              :value="r.value"
            >
            </v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="printDialog = false">
            取消
          </v-btn>
          <v-btn color="blue darken-1" text @click="print"> 打印 </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deliveryOrderDialog" max-width="500px">
      <v-card>
        <v-card-title class="mb-5">
          <span class="headline">DO接收</span>
        </v-card-title>
        <v-card-text>
          <v-form v-model="validDODate" @submit.prevent="">
            <v-row class="mt-n6">
              <v-col>
                <DatePicker
                  label="收到DO日期"
                  :date="editedDOReceivedDate"
                  @update="(v) => (editedDOReceivedDate = v)"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="deliveryOrderDialog = false"
          >
            取消
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :disabled="!validDODate"
            @click="updatePOReceivedDate"
          >
            保存
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
  </div>
</template>

<script>
import bus from '@/eventBus';
import OrderItemTable from '@/components/Order/OrderItemTable.vue';
import Attachments from '@/components/Attachments.vue';
import config from '@/plugins/config';
import OrderSummary from '@/components/Order/OrderSummary.vue';
import DatePicker from '@/components/DatePicker.vue';
import rule from '@/library/rule';

export default {
  components: {
    OrderItemTable,
    Attachments,
    OrderSummary,
    DatePicker,
  },
  data: () => ({
    initializing: true,
    tab: 1,
    editMode: false,

    validDODate: true,
    editedDOReceivedDate: undefined,
    deliveryOrderDialog: false,

    valid: false,
    printValue: '',
    loading: false,
    saving: false,
    companyItems: [],
    rule,
    menu: false, // ship date datepicker menu
    menu2: false, // arrival dated atepicker  menu
    printDialog: false,
    refreshing: false,
    printRadioSelections: [
      {
        label: '完整资料',
        value: 'f=sku,name,numCase,img,price,qtyPerCase,fee,priceUSD,vat,total,poNum&s2=1',
      },
      {
        label: '资料',
        value: 'f=sku,name,numCase,img,price,qtyPerCase,fee,priceUSD,total,poNum&s=1',
      },
      {
        label: '卸货',
        value: 'f=sku,name,numCase,img,qtyPerCase,loc,OMSInstockCase,loc,newloc',
      },
      {
        label: '资料（体积/重量）',
        value: 'f=sku,name,numCase,img,upc,dimension,weight,poNum,qtyPerCase',
      },
    ],
    order: {
      summary: {
        cid: '',
        name: '',
        shippingMethod: '',
        cpscReleased: false,
        subtotal: 0,
        totalWeight: 0,
        locked: false,
        shipDate: undefined,
        arrivalDate: undefined,
        shipping: 0,
        custom: 0,
        _id: 0,
        company: undefined,
        totalVolume: 0,
        totalNumCase: 0,
        numOfProducts: 0,
        shippingRate: 1,
        exchangeRate: 1,
        taxReturn: 0,
        taxReturnFee: 0,
        note: '',
        num: 0,
        doReceived: false,
        doReceivedDate: '',
        createdDate: undefined,
        __v: 0,
      },
      orderItems: [],
    },
    rules: {
      shippingMethod: [(v) => v.trim().length < 100 || '运输方式需少于100字符'],
    },
  }),
  computed: {
    textInputBgColor() {
      return this.editMode ? config.colors.editedInputBgColor : 'white';
    },
    formattedDOReceivedDate() {
      if (!this.order.summary.doReceivedDate) return '';
      let d = this.order.summary.doReceivedDate.substr(0, 10);
      d = new Date(d);
      const month = d.getMonth() + 1;
      return `${month}/${d.getDate() + 1}/${d.getFullYear()}`;
    },
  },
  created() {
    this.fetch();
    this.editMode = this.$route.query.emode === 'true';
    this.tab = this.$route.query.tab;
    this.initializing = false;
  },
  // mounted() {
  //   setTimeout(() => {
  //     this.$refs.orderForm.validate();
  //   }, 2000);
  // },
  methods: {
    refresh() {
      this.refreshing = true;
      /* eslint-disable */
      window.confirm('确定要刷新?') && this.fetch();
      /* eslint-enable */
    },
    saveEdit() {
      this.saving = true;
      this.$http
        .put(`/order/${this.$route.params.id}`, this.order.summary, {
          headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => {
          bus.$emit('success', '修改成功');
          this.order.summary = response.data;
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        })
        .finally(() => {
          this.saving = false;
        });
    },
    // called when an add-item event is emitted by OrderItemTable component
    addItem(data) {
      this.loading = true;
      this.$http
        .post(`/order/${this.$route.params.id}`, {
          product: data.product,
          quantity: data.numCase,
          poNum: data.poNum,
          price: data.price,
          fee: data.fee,
          vat: data.vat,
          customTax: data.customTax,
          taxReturnCalcType: data.taxReturnCalcType
        })
        .then((response) => {
          this.order.orderItems.push(response.data);
          bus.$emit('success', '成功添加');
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    editItem(data) {
      const { item } = data;
      this.loading = true;
      this.$http
        .put(`/order/${this.$route.params.id}/item/${item._id}`, {
          quantity: item.numCase,
          poNum: item.poNum,
          price: item.price,
          fee: item.fee,
          vat: item.vat,
          customTax: item.customTax,
          taxReturnCalcType: item.taxReturnCalcType,
        })
        .then((response) => {
          const updatedOrderItem = response.data;
          const i = this.getOrderItemIndex(updatedOrderItem._id);
          this.order.orderItems.splice(i, 1);
          this.order.orderItems.push(updatedOrderItem);
          bus.$emit('success', '修改成功');
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteItem(data) {
      this.loading = true;
      this.$http
        .delete(`/order/${this.$route.params.id}/item/${data._id}`)
        .then((response) => {
          const i = this.getOrderItemIndex(response.data._id);
          this.order.orderItems.splice(i, 1);
          bus.$emit('success', '删除成功');
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateMargin(data) {
      // {{id: productid, flatMargin, percentMargin}}
      for (let i = 0; i < this.order.orderItems.length; i += 1) {
        if (data.id === this.order.orderItems[i].product) {
          this.order.orderItems[i].flatMargin = data.flatMargin;
          this.order.orderItems[i].percentMargin = data.percentMargin;
          this.order.orderItems[i].marginMultiplier = data.marginMultiplier;
          return;
        }
      }
    },
    fetch() {
      this.loading = true;
      this.$http
        .get(`/order/${this.$route.params.id}`)
        .then((response) => {
          this.order = response.data;
          if (this.refreshing) {
            bus.$emit('success', '成功刷新');
            this.refreshing = false;
          }
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        })
        .finally(() => {
          this.loading = false;
          document.title = `集装箱${this.order.summary.num} - ${this.order.summary.name}`;
        });
    },
    changeLockStatus() {
      if (!this.$user.data.isAdmin) {
        return;
      }
      const isLocked = this.order.summary.locked;
      const baseUrl = `/order/${this.$route.params.id}/`;
      const ext = isLocked ? 'unlock' : 'lock';
      const url = baseUrl + ext;
      this.$http
        .put(url)
        .then((response) => {
          bus.$emit('success', response.data.message);
          this.order.summary.locked = !isLocked;
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        });
    },
    isAdmin() {
      return this.$user.data.isAdmin;
    },
    updateRoute() {
      if (this.initializing) return;
      const path = `${this.$route.path}?emode=${this.editMode}&tab=${this.tab}`;
      this.$router.replace(path).catch((err) => {});
    },
    print() {
      const w = window.open(
        `${this.$route.path}/print?${this.printValue}`,
        '_blank',
        'height=700,width=900',
      );
      w.focus();
      this.printDialog = false;
    },
    getOrderItemIndex(id) {
      let index = -1;
      for (let i = 0; i < this.order.orderItems.length; i += 1) {
        if (this.order.orderItems[i]._id === id) index = i;
      }
      return index;
    },
    getDay(date) {
      return Number.parseInt(date.split('-')[2], 10);
    },
    setDelivered(isDelivered) {
      let url = `/order/${this.$route.params.id}/`;
      url += isDelivered ? 'setdeliver' : 'unsetdeliver';
      this.$http
        .put(url)
        .then((response) => {
          this.order.summary = response.data;
          bus.$emit('success', '修改成功');
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        })
        .finally(() => {});
    },
    setPOReceived(status) {
      if (status) {
        this.openDOReceivalForm();
      } else {
        const url = `/order/${this.$route.params.id}/unReceiveDO`;
        this.$http
          .put(url)
          .then((response) => {
            this.deliveryOrderDialog = false;
            bus.$emit('success', response.data.message);
            this.order.summary.doReceived = status;
            this.order.summary.doReceivedDate = response.data.doReceivedDate;
          })
          .catch((error) => {
            bus.$emit('error', this.$error.getMsg(error));
          })
          .finally(() => {});
      }
    },
    openDOReceivalForm() {
      this.deliveryOrderDialog = true;
      const d = new Date();
      const year = d.getFullYear();
      let month = parseInt(d.getMonth(), 10) + 1;
      if (month < 10) month = `0${month.toString()}`;
      const date = d.getDate();
      const dateString = `${year}-${month}-${date}`;
      this.editedDOReceivedDate = dateString;
    },
    updatePOReceivedDate() {
      const url = `/order/${this.$route.params.id}/receiveDO`;
      this.$http
        .put(url, { date: this.editedDOReceivedDate })
        .then((response) => {
          this.deliveryOrderDialog = false;
          bus.$emit('success', response.data.message);
          this.order.summary.doReceived = true;
          this.order.summary.doReceivedDate = response.data.doReceivedDate;
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        })
        .finally(() => {});
    },
    setCpscReleased(toRelease) {
      let url = `/order/${this.$route.params.id}/`;
      url += toRelease ? 'cpscRelease' : 'cpscUnRelease';
      this.$http
        .put(url)
        .then((response) => {
          bus.$emit('success', response.data.message);
          this.order.summary.cpscReleased = toRelease;
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        })
        .finally(() => {});
    },
    formattedDOReceivedDate2(input) {
      if (!input) return '';
      let d = input.substr(0, 10);
      d = new Date(d);
      const month = d.getMonth() + 1;
      return `${month}/${d.getDate() + 1}/${d.getFullYear()}`;
    },
  },
};
</script>
