<template>
  <div>
    <v-card flat>
      <v-card-title>
        供应商
        <v-spacer />
        <router-link to="/vendor/new">
          <v-btn color="primary"> <v-icon>mdi-plus</v-icon>新供应商 </v-btn>
        </router-link>
      </v-card-title>
    </v-card>

    <v-card>
      <v-card-title>
        搜索
        <v-spacer />
        <VendorSearchAutoComplete class="ml-3" :input.sync="searchOutput" />
      </v-card-title>
    </v-card>

    <v-data-table
      :headers="getHeaders()"
      :items="items"
      :loading="loading"
      hide-default-footer
      :items-per-page="pagination.rowsPerPage"
      class="elevation-1"
    >
      <template v-slot:[`item.name`]="{ item }">
        <a :href="'/vendor/' + item._id">{{ item.name }}</a>
      </template>
    </v-data-table>
    <v-pagination
      :value="pagination.page"
      :length="pages"
      :total-visible="12"
      circle
      @input="paginationChangeHandler"
    />
  </div>
</template>

<script>
import bus from '@/eventBus';
import VendorSearchAutoComplete from '@/components/VendorSearchAutoComplete.vue';

export default {
  components: { VendorSearchAutoComplete },
  data() {
    return {
      items: [],
      searchOutput: null,
      loading: false,
      pagination: { totalItems: 0, rowsPerPage: 25, page: 1 },
      headers: [
        {
          text: '名字',
          value: 'name',
          align: 'center',
          sortable: false,
        },
        {
          text: '公司',
          value: 'company.name',
          sortable: false,
          adminOnly: true,
        },
      ],
    };
  },
  computed: {
    pages() {
      if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null) {
        return 0;
      }

      return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage);
    },
  },
  created() {
    const pageNumber = parseInt(this.$route.query.page, 10);
    this.pagination.page = Number.isNaN(pageNumber) ? 0 : pageNumber;
    this.fetch();
  },
  methods: {
    fetch() {
      this.loading = true;
      const limit = this.pagination.rowsPerPage;
      let offset = (this.pagination.page - 1) * limit;
      if (offset < 0) offset = 0;
      const link = `/vendor?limit=${limit}&offset=${offset}`;
      this.$http
        .get(link)
        .then((response) => {
          this.items = response.data.docs;
          this.pagination.totalItems = response.data.totalDocs;
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        })
        .finally(() => {
          this.loading = false;
        });
    },

    paginationChangeHandler(pageNumber) {
      if (this.pagination.page === pageNumber) return;
      this.pagination.page = pageNumber;
      this.$router.push(`/vendor?page=${pageNumber}`);
      this.fetch();
    },

    getHeaders() {
      const { isAdmin } = this.$user.data;
      if (isAdmin) return this.headers;
      const headers = [];
      this.headers.forEach((e) => {
        if (!e.adminOnly) {
          headers.push(e);
        }
      });
      return headers;
    },
  },
  watch: {
    searchOutput(val) {
      this.$router.push(`/vendor/${val}`);
    },
  },
};
</script>

<style scoed>
tr:nth-of-type(even) {
  background-color: rgba(92, 83, 206, 0.05);
}
</style>
