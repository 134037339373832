<template>
  <div>
    <v-card flat>
      <v-card-title>
        创建散货单
        <v-spacer />
        <v-btn
          color="primary"
          type="submit"
          form="createForm"
          :disabled="!valid"
          :loading="loading"
        >
          创建
        </v-btn>
      </v-card-title>
    </v-card>
    <v-container>
      <v-divider class="mb-5 mt-n2" />
      <v-form
        v-model="valid"
        id="createForm"
        form="createForm"
        ref="createForm"
        @submit.prevent="createShipment()"
      >
        <v-row>
          <v-col cols="12" md="6" class="mb-n6">
            <v-text-field
              v-model.trim="shipment.name"
              label="名称"
              name="name"
              :rules="rule.notEmpty"
              type="text"
              outlined
            />
          </v-col>
          <v-col cols="6" md="3" class="mb-n6">
            <v-text-field
              v-model.trim="shipment.shippingMethod"
              label="运输公司/方式"
              :rules="rule.notEmpty"
              name="cid"
              type="text"
              outlined
            />
          </v-col>
          <v-col cols="6" md="3" class="mb-n6">
            <v-text-field
              v-model.trim="shipment.tracking"
              label="跟踪码"
              name="seal"
              :rules="rule.notEmpty"
              type="text"
              outlined
            />
          </v-col>
          <v-col cols="6" sm="4" class="mb-n4">
            <DatePicker
              label="装货日期"
              :date="shipment.shipDate"
              @update="(v) => (shipment.shipDate = v)"
            />
          </v-col>

          <v-col cols="6" sm="4" class="mb-n4">
            <DatePicker
              disabled
              label="预计货到日期"
              :date="shipment.arrivalDate"
              @update="(v) => (shipment.arrivalDate = v)"
            />
          </v-col>

          <v-col cols="4" md="2" class="mb-n6">
            <v-text-field
              v-model.number="shipment.shipping"
              label="运费"
              name="shipping"
              type="number"
              step="0.01"
              prefix="¥"
              :rules="rule.positiveFloat"
              outlined
            />
          </v-col>
          <!-- <v-col cols="4" md="2" class="mb-n6">
            <v-text-field
              v-model.number="shipment.shipping2"
              label="运费"
              name="shipping2"
              type="number"
              step="0.01"
              prefix="¥"
              :rules="rule.positiveFloat"
              outlined
            />
          </v-col> -->
          <v-col cols="4" md="2" class="mb-n6">
            <v-text-field
              v-model.number="shipment.otherfee"
              label="其他费用"
              name="otherfee"
              type="number"
              step="0.01"
              :rules="rule.positiveFloat"
              prefix="¥"
              outlined
            />
          </v-col>
          <v-col cols="4" md="2" class="mb-n6">
            <v-text-field
              v-model.number="shipment.shippingRate"
              label="运费/换算单位"
              name="otherfee"
              type="number"
              prefix="¥"
              :rules="rule.positiveFloat"
              step="0.01"
              outlined
            />
          </v-col>
          <v-col cols="4" md="2" class="mb-n6">
            <v-text-field
              v-model.number="shipment.exchangeRate"
              label="汇率"
              name="exchangeRate"
              type="number"
              step="0.01"
              :rules="rule.positiveFloat"
              outlined
            />
          </v-col>
        </v-row>
        <v-row class="mb-n7">
          <v-col cols="12">
            <v-textarea
              v-model="shipment.note"
              name="note"
              outlined
              label="备注"
              rows="3"
              auto-grow
              :rules="rule.note"
              prepend-inner-icon="mdi-note"
            />
          </v-col>
        </v-row>
        <v-row class="mb-n5">
          <v-col  v-if="$user.data.isAdmin">
            <CompanySelection
                    :value.sync="shipment.company"
                    :rules="rule.company" />
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import bus from '@/eventBus';
import CompanySelection from '@/components/CompanySelection.vue';
import DatePicker from '@/components/DatePicker.vue';
import rule from '@/library/rule';

export default {
  components: { CompanySelection, DatePicker },
  data: () => ({
    valid: false,
    loading: false,
    companyItems: [],
    rule,
    arrivalDateFormatted: '',
    shipment: {
      cid: '',
      name: '',
      shippingMethod: '',
      shipping: 0,
      shipping2: 0,
      tax: 0,
      otherfee: 0,
      shipDate: '',
      arrivalDate: '',
      shippingRate: 0,
      exchangeRate: 0,
      taxReturn: 0,
      taxReturnFee: 0,
      volumeConversionRate: 6000,
      note: '',
    },
  }),

  computed: {
    computedDateFormatted() {
      if (!this.shipment.shipDate) return null;
      return this.formatDate(this.shipment.shipDate);
    },
  },

  watch: {
    // eslint-disable-next-line func-names
    'shipment.shipDate': function (val) {
      this.dateFormatted = this.formatDate(val);
    },
    // eslint-disable-next-line func-names
    'shipment.arrivalDate': function (val) {
      this.dateFormatted2 = this.formatDate(val);
    },
  },
  mounted() {
    this.$refs.createForm.validate();
  },
  methods: {
    createShipment() {
      const shipmentData = this.shipment;
      this.loading = true;
      this.$http
        .post('/shipment', shipmentData, {
          headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => {
          bus.$emit('success', '创造成功');
          this.$router.push(`/shipment/${response.data._id}`);
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        });
      this.loading = false;
    },
    formatDate(date) {
      if (!date) return undefined;
      const [year, month, day] = date.split('-');
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return undefined;

      const [month, day, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    getDay(date) {
      return Number.parseInt(date.split('-')[2], 10);
    },
  },
};
</script>
