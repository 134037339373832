import Orders from '@/views/Order/Orders.vue';
import CreateOrder from '@/views/Order/CreateOrder.vue';
import ViewEditOrder from '@/views/Order/ViewEditOrder.vue';
import PrintOrder from '@/views/Order/PrintOrder.vue';

const routes = [
  {
    path: '/order', name: 'Orders', component: Orders, meta: { title: '集装箱' },
  },
  {
    path: '/order/new', name: 'CreateOrder', component: CreateOrder, meta: { title: '新集装箱' },
  },
  {
    path: '/order/:id/print', name: 'PrintOrder', component: PrintOrder, meta: { title: '打印集装箱' },
  },
  { path: '/order/:id', name: 'ViewEditOrder', component: ViewEditOrder },
];

export default routes;
