<template>
  <span>
    <v-tooltip bottom class="ml-2">
      <template v-slot:activator="{ on }">
        <v-icon v-if="!!!doReceived" color="red" class="mr-1" v-on="on">
          mdi-form-select
        </v-icon>
      </template>
      Delivery Order未收到
    </v-tooltip>
    <v-tooltip bottom v-if="$user.data.isAdmin" class="ml-2">
      <template v-slot:activator="{ on }">
        <v-icon v-if="!released" color="orange" class="mr-1" v-on="on">
          mdi-alert
        </v-icon>
      </template>
      CPSC未清关
    </v-tooltip>
    <v-icon color="green" v-if="state === 0" class="mr-1"> mdi-truck </v-icon>
    <v-icon v-if="locked"> mdi-lock </v-icon>
  </span>
</template>
<style>
</style>

<script>
export default {
  props: {
    locked: Boolean,
    state: Number,
    released: Boolean,
    doReceived: Boolean,
  },
  data: () => ({}),
  created() {},
  methods: {},
  computed: {},
};
</script>
