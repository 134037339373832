<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      class="elevation-1"
      disable-pagination
      hide-default-footer
    >
      <template v-slot:top>
        <v-toolbar flat color="grey darken-1 mt-4">
          <v-toolbar-title>附件</v-toolbar-title>
          <v-divider class="mx-4" inset vertical />
          <v-spacer />
          <input
            id="uploadInputElement"
            type="file"
            ref="file"
            :name="uploadFieldName"
            @change="filesChange($event.target.name, $event.target.files)"
            hidden
          />
          <v-btn
            small
            color="primary"
            dark
            @click="triggerUploadEvent"
            :disabled="uploading"
          >
            {{ uploadButtonText }}
            <v-icon right dark> mdi-cloud-upload </v-icon>
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:[`item.size`]="{ item }">
        {{ (item.size / 1000000).toFixed(2) }} MB
      </template>
      <template v-slot:[`item.addTime`]="{ item }">
        {{ formatTime(item.addTime) }}
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <a :href="'/api/file/download/' + item._id" :download="item.name">
          <v-icon small class="mr-4"> mdi-download </v-icon>
        </a>
        <v-icon small @click="deleteFile(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data> 无附件 </template>
    </v-data-table>
  </div>
</template>
<style>
a {
  text-decoration: none;
}
</style>

<script>
import bus from '@/eventBus';

export default {
  props: {
    readOnly: Boolean,
  },
  data: () => ({
    headers: [
      { text: '名字', value: 'name' },
      { text: '大小', value: 'size' },
      // { text: '上传时间', value: 'addTime' },
      { text: '操作', value: 'action', sortable: false },
    ],
    editedIndex: -1,
    editedItem: null,
    items: [],
    uploadElement: null,
    uploadFieldName: 'file',
    uploadedFiles: [],
    uploadError: null,
    currentStatus: null,
    uploading: false,
  }),
  created() {
    this.uploadElement = document.getElementById('uploadInputElement');
    this.fetchFiles();
  },
  methods: {
    filesChange(name, files) {
      this.uploading = true;
      const formData = new FormData();
      formData.append('file', files[0]);
      this.$http
        .post(`${this.$route.path}/file`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          this.items.push(res.data);
          bus.$emit('success', '文件上传成功!');
        })
        .catch((err) => {
          bus.$emit('error', `上传失败-${err}`);
        })
        .finally(() => {
          this.uploading = false;
        });
    },
    deleteFile(file) {
      /* eslint-disable */
      if (!window.confirm('确定要删除此文件?')) return;
      /* eslint-enable */
      this.$http
        .delete(`${this.$route.path}/file/${file._id}`)
        .then((res) => {
          const position = this.items.indexOf(file);
          this.items.splice(position, 1);
          bus.$emit('success', '成功删除文件!');
        })
        .catch((err) => {
          bus.$emit('error', `删除失败-${this.$error.getMsg(err)}`);
        });
    },
    triggerUploadEvent() {
      document.getElementById('uploadInputElement').click();
    },
    fetchFiles() {
      this.$http.get(`/file/parent/${this.$route.params.id}`).then((response) => {
        this.items = response.data;
      });
    },
    formatTime(time) {
      const d = new Date(time);
      const month = d.getMonth() + 1;
      const date = d.getDate();
      const year = d.getFullYear();
      const s = `${month} / ${date} / ${year}`;
      return s;
    },
  },
  computed: {
    uploadButtonText() {
      return this.uploading ? '上传文件中' : '上传文件';
    },
  },
};
</script>
