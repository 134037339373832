<template>
  <div>
    <v-card flat>
      <v-tabs v-model="tab">
        <v-tab href="#1">资料</v-tab>
        <v-tab href="#2">附件({{ shipment.summary.attachments }})</v-tab>
      </v-tabs>
      <v-card-title>
        散货单 - {{ this.shipment.summary.num }} （{{
          this.shipment.summary.totalNumCase
        }}
        CS）
        <v-tooltip bottom v-if="$user.data.isAdmin" class="ml-2">
          <template v-slot:activator="{ on }">
            <v-btn
              text
              icon
              x-large
              @click="setDelivered(true)"
              v-if="shipment.summary.state === 0"
              v-on="on"
            >
              <v-icon color="red"> mdi-truck </v-icon>
            </v-btn>
            <v-btn
              text
              icon
              x-large
              @click="setDelivered(false)"
              v-on="on"
              v-else
            >
              <v-icon color="green"> mdi-package-variant-closed </v-icon>
            </v-btn>
          </template>
          <span v-if="shipment.summary.state === 0">设为已收</span>
          <span v-else>设为未收</span>
        </v-tooltip>
        <v-tooltip bottom class="ml-2">
          <template v-slot:activator="{ on }">
            <v-btn text icon x-large @click="changeLockStatus" v-on="on">
              <v-icon v-if="shipment.summary.locked" color="red">
                mdi-lock
              </v-icon>
              <v-icon v-else color="green"> mdi-lock-open-variant </v-icon>
            </v-btn>
          </template>
          <span>封锁状态下将无法修改资料</span>
        </v-tooltip>
        <v-btn text icon x-large @click="printDialog = true">
          <v-icon> mdi-printer </v-icon>
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="editMode && !shipment.summary.locked && tab == '1'"
          class="mr-6"
          color="primary"
          type="submit"
          form="form"
          :disabled="!valid"
          :loading="saving"
        >
          <v-icon>mdi-content-save</v-icon>保存
        </v-btn>
        <v-switch
          v-if="!shipment.summary.locked && tab == '1'"
          v-model="editMode"
          label="修改模式"
          class="mr-5"
          @change="updateRoute"
        />
      </v-card-title>
      <v-divider class="mt-n5" />
    </v-card>
    <v-tabs-items v-model="tab">
      <v-tab-item value="1">
        <v-container>
          <v-form
            v-model="valid"
            id="form"
            form="shipmentForm"
            ref="shipmentForm"
            @submit.prevent="saveEdit"
          >
            <v-row class="mt-1">
              <v-col cols="12" md="6" class="mb-n6">
                <v-text-field
                  v-model.trim="shipment.summary.name"
                  :readonly="!editMode"
                  label="名称"
                  :rules="rule.notEmpty"
                  name="name"
                  type="text"
                  outlined
                  :background-color="textInputBgColor"
                />
              </v-col>
              <v-col cols="6" md="3" class="mb-n6">
                <v-text-field
                  v-model.trim="shipment.summary.shippingMethod"
                  :readonly="!editMode"
                  label="运输公司/方式"
                  :rules="rule.notEmpty"
                  name="cid"
                  type="text"
                  outlined
                  :background-color="textInputBgColor"
                />
              </v-col>
              <v-col cols="6" md="3" class="mb-n6">
                <v-text-field
                  v-model.trim="shipment.summary.tracking"
                  :readonly="!editMode"
                  label="跟踪码"
                  :rules="rule.notEmpty"
                  name="seal"
                  type="text"
                  outlined
                  :background-color="textInputBgColor"
                />
              </v-col>
              <v-col cols="6" md="4" class="mb-n4">
                <DatePicker
                  label="装货日期"
                  :clearable="editMode"
                  :date="shipment.summary.shipDate"
                  :bgColor="textInputBgColor"
                  @update="(v) => (shipment.summary.shipDate = v)"
                  required
                />
              </v-col>
              <v-col cols="6" md="4" class="mb-n4">
                <DatePicker
                  label="预计货到日期"
                  :date="shipment.summary.arrivalDate"
                  :bgColor="textInputBgColor"
                  :clearable="editMode"
                  @update="(v) => (shipment.summary.arrivalDate = v)"
                />
              </v-col>
              <v-col cols="4" md="2" class="mb-n6">
                <v-text-field
                  v-model.number="shipment.summary.shipping"
                  label="运费"
                  name="shipping"
                  type="number"
                  step="0.01"
                  prefix="¥"
                  :rules="rule.positiveFloat"
                  :readonly="!editMode"
                  outlined
                  :background-color="textInputBgColor"
                />
              </v-col>
              <v-col cols="4" md="2" class="mb-n6">
                <v-text-field
                  v-model.number="shipment.summary.otherfee"
                  label="其他费用"
                  name="otherfee"
                  type="number"
                  step="0.01"
                  prefix="¥"
                  :readonly="!editMode"
                  outlined
                  :background-color="textInputBgColor"
                />
              </v-col>
              <v-col cols="4" md="2" class="mb-n6">
                <v-text-field
                  v-model.number="shipment.summary.shippingRate"
                  label="运费(￥/体积单位)"
                  name="otherfee"
                  type="number"
                  step="0.01"
                  prefix="¥"
                  :rules="rule.positiveFloat"
                  :readonly="!editMode"
                  outlined
                  :background-color="textInputBgColor"
                />
              </v-col>
              <v-col cols="4" md="2" class="mb-n6">
                <v-text-field
                  v-model.number="shipment.summary.exchangeRate"
                  label="汇率"
                  name="exchangeRate"
                  type="number"
                  step="0.01"
                  :rules="rule.positiveFloat"
                  :readonly="!editMode"
                  outlined
                  :background-color="textInputBgColor"
                />
              </v-col>
              <v-col class="mb-n6">
                运算体积： 体积cm<sup>3</sup> / {{shipment.summary.volumeConversionRate}}
              </v-col>
              <v-col
                cols="6"
                md="4"
                class="mb-n4"
                v-if="shipment.summary.state === 1"
              >
                <DatePicker
                  label="进仓日期"
                  :date="shipment.summary.receivedDate"
                  :clearable="editMode"
                  :bgColor="textInputBgColor"
                  @update="(v) => (shipment.summary.receivedDate = v)"
                />
              </v-col>
              <!-- <v-col
                cols="6"
                md="4"
                class="mb-n4"
              >体积单位公式 ： 体积cm<sup>3</sup>/{{shipment.summary.volumeConversionRate}} </v-col> -->
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="shipment.summary.note"
                  name="note"
                  outlined
                  label="备注"
                  rows="3"
                  auto-grow
                  :rules="[
                    (v) => v.trim().length <= 2000 || '备注不能多于2000字符',
                  ]"
                  :readonly="!editMode"
                  :background-color="textInputBgColor"
                  prepend-inner-icon="mdi-note"
                />
              </v-col>
            </v-row>
          </v-form>
          <div class="mb-4" v-if="shipment.summary.doReceived">
            DO收到日期:
            {{ formattedDOReceivedDate2(shipment.summary.doReceivedDate) }}
          </div>
          <ShipmentItemTable
            :items.sync="shipment.shipmentItems"
            :exchangeRate="shipment.summary.exchangeRate"
            :shippingRate="shipment.summary.shippingRate"
            :volumeConversionRate="shipment.summary.volumeConversionRate"
            :read-only="!editMode"
            @add-item="addItem"
            @edit-item="editItem"
            @delete-item="deleteItem"
            @update-margin="updateMargin"
          />
          <!-- <ShipmentSummary :shipment="shipment.summary"
          :shipmentItems="shipment.shipmentItems" /> -->
        </v-container>
      </v-tab-item>
      <v-tab-item value="2">
        <v-container>
          <Attachments />
        </v-container>
      </v-tab-item>
    </v-tabs-items>

    <v-dialog v-model="printDialog" max-width="200px">
      <v-card>
        <v-card-title>
          <span class="headline">打印</span>
        </v-card-title>
        <v-card-text>
          <v-radio-group v-model="printValue" mandatory>
            <v-radio
              :key="r.label"
              v-for="r in printRadioSelections"
              :label="r.label"
              :value="r.value"
            >
            </v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="printDialog = false">
            取消
          </v-btn>
          <v-btn color="blue darken-1" text @click="print"> 打印 </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
  </div>
</template>

<script>
import bus from '@/eventBus';
import Attachments from '@/components/Attachments.vue';
import config from '@/plugins/config';
import DatePicker from '@/components/DatePicker.vue';
import rule from '@/library/rule';
// import ShipmentSummary from '@/components/Shipment/ShipmentSummary.vue';
import ShipmentItemTable from '@/components/Shipment/ShipmentItemTable.vue';

export default {
  components: {
    ShipmentItemTable,
    Attachments,
    // ShipmentSummary,
    DatePicker,
  },
  data: () => ({
    initializing: true,
    tab: 1,
    editMode: false,

    validDODate: true,
    editedDOReceivedDate: undefined,
    // deliveryShipmentDialog: false,

    valid: false,
    printValue: '',
    loading: false,
    saving: false,
    companyItems: [],
    rule,
    menu: false, // ship date datepicker menu
    menu2: false, // arrival dated atepicker  menu
    printDialog: false,
    refreshing: false,
    printRadioSelections: [
      {
        label: '完整资料',
        value: 'f=sku,name,numCase,img,price,qtyPerCase,fee,priceUSD,vat,total,poNum&s2=1',
      },
      {
        label: '资料',
        value: 'f=sku,name,numCase,img,price,qtyPerCase,fee,priceUSD,total,poNum&s=1',
      },
      {
        label: '卸货',
        value: 'f=sku,name,numCase,img,qtyPerCase,loc,OMSInstockCase,loc,newloc',
      },
      {
        label: '资料（体积/重量）',
        value: 'f=sku,name,numCase,img,upc,dimension,weight,poNum,qtyPerCase',
      },
    ],
    shipment: {
      summary: {
        cid: '',
        name: '',
        shippingMethod: '',
        cpscReleased: false,
        subtotal: 0,
        totalWeight: 0,
        locked: false,
        shipDate: undefined,
        arrivalDate: undefined,
        shipping: 0,
        custom: 0,
        _id: 0,
        company: undefined,
        totalVolume: 0,
        totalNumCase: 0,
        numOfProducts: 0,
        shippingRate: 1,
        exchangeRate: 1,
        volumeConversionRate: 1,
        note: '',
        num: 0,
        createdDate: undefined,
        __v: 0,
      },
      shipmentItems: [],
    },
    rules: {
      shippingMethod: [(v) => v.trim().length < 100 || '运输方式需少于100字符'],
    },
  }),
  computed: {
    textInputBgColor() {
      return this.editMode ? config.colors.editedInputBgColor : 'white';
    },
    formattedDOReceivedDate() {
      if (!this.shipment.summary.doReceivedDate) return '';
      let d = this.shipment.summary.doReceivedDate.substr(0, 10);
      d = new Date(d);
      const month = d.getMonth() + 1;
      return `${month}/${d.getDate() + 1}/${d.getFullYear()}`;
    },
  },
  created() {
    this.fetch();
    this.editMode = this.$route.query.emode === 'true';
    this.tab = this.$route.query.tab;
    this.initializing = false;
  },
  // mounted() {
  //   setTimeout(() => {
  //     this.$refs.shipmentForm.validate();
  //   }, 2000);
  // },
  methods: {
    refresh() {
      this.refreshing = true;
      /* eslint-disable */
      window.confirm('确定要刷新?') && this.fetch();
      /* eslint-enable */
    },
    saveEdit() {
      this.saving = true;
      this.$http
        .put(`/shipment/${this.$route.params.id}`, this.shipment.summary, {
          headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => {
          bus.$emit('success', '修改成功');
          this.shipment.summary = response.data;
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        })
        .finally(() => {
          this.saving = false;
        });
    },
    // called when an add-item event is emitted by ShipmentItemTable component
    addItem(data) {
      this.loading = true;
      this.$http
        .post(`/shipment/${this.$route.params.id}`, {
          product: data.product,
          quantity: data.numCase,
          poNum: data.poNum,
          freightType: data.freightType,
          price: data.price,
          fee: data.fee,
          vat: data.vat,
          customTax: data.customTax,
        })
        .then((response) => {
          this.shipment.shipmentItems.push(response.data);
          bus.$emit('success', '成功添加');
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    editItem(data) {
      const { item } = data;
      this.loading = true;
      this.$http
        .put(`/shipment/${this.$route.params.id}/item/${item._id}`, {
          quantity: item.numCase,
          poNum: item.poNum,
          price: item.price,
          fee: item.fee,
          vat: item.vat,
          customTax: item.customTax,
          freightType: item.freightType
        })
        .then((response) => {
          const updatedShipmentItem = response.data;
          const i = this.getShipmentItemIndex(updatedShipmentItem._id);
          this.shipment.shipmentItems.splice(i, 1);
          this.shipment.shipmentItems.push(updatedShipmentItem);
          bus.$emit('success', '修改成功');
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteItem(data) {
      this.loading = true;
      this.$http
        .delete(`/shipment/${this.$route.params.id}/item/${data._id}`)
        .then((response) => {
          const i = this.getShipmentItemIndex(response.data._id);
          this.shipment.shipmentItems.splice(i, 1);
          bus.$emit('success', '删除成功');
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateMargin(data) {
      // {{id: productid, flatMargin, percentMargin}}
      for (let i = 0; i < this.shipment.shipmentItems.length; i += 1) {
        if (data.id === this.shipment.shipmentItems[i].product) {
          this.shipment.shipmentItems[i].flatMargin = data.flatMargin;
          this.shipment.shipmentItems[i].percentMargin = data.percentMargin;
          this.shipment.shipmentItems[i].marginMultiplier = data.marginMultiplier;
          return;
        }
      }
    },
    fetch() {
      this.loading = true;
      this.$http
        .get(`/shipment/${this.$route.params.id}`)
        .then((response) => {
          this.shipment = response.data;
          if (this.refreshing) {
            bus.$emit('success', '成功刷新');
            this.refreshing = false;
          }
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        })
        .finally(() => {
          this.loading = false;
          document.title = `集装箱${this.shipment.summary.num} - ${this.shipment.summary.name}`;
        });
    },
    changeLockStatus() {
      if (!this.$user.data.isAdmin) {
        return;
      }
      const isLocked = this.shipment.summary.locked;
      const baseUrl = `/shipment/${this.$route.params.id}/`;
      const ext = isLocked ? 'unlock' : 'lock';
      const url = baseUrl + ext;
      this.$http
        .put(url)
        .then((response) => {
          bus.$emit('success', response.data.message);
          this.shipment.summary.locked = !isLocked;
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        });
    },
    isAdmin() {
      return this.$user.data.isAdmin;
    },
    updateRoute() {
      if (this.initializing) return;
      const path = `${this.$route.path}?emode=${this.editMode}&tab=${this.tab}`;
      this.$router.replace(path).catch((err) => {});
    },
    print() {
      const w = window.open(
        `${this.$route.path}/print?${this.printValue}`,
        '_blank',
        'height=700,width=900',
      );
      w.focus();
      this.printDialog = false;
    },
    getShipmentItemIndex(id) {
      let index = -1;
      for (let i = 0; i < this.shipment.shipmentItems.length; i += 1) {
        if (this.shipment.shipmentItems[i]._id === id) index = i;
      }
      return index;
    },
    getDay(date) {
      return Number.parseInt(date.split('-')[2], 10);
    },
    setDelivered(isDelivered) {
      let url = `/shipment/${this.$route.params.id}/`;
      url += isDelivered ? 'setdeliver' : 'unsetdeliver';
      this.$http
        .put(url)
        .then((response) => {
          this.shipment.summary = response.data;
          bus.$emit('success', '修改成功');
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        })
        .finally(() => {});
    },
  },
};
</script>
