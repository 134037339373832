<template>
  <v-card class="mt-5">
    <v-card-text>
      <v-row class="h3">
        <v-col cols="6" sm="4">
          <strong>总箱数:</strong> {{ numberWithComma(totalNumCase) }}
        </v-col>
        <v-col cols="6" sm="4">
          <strong>总重量: </strong>{{ numberWithComma(totalWeight,2) }} kg
        </v-col>
        <v-col cols="6" sm="4">
          <strong>总体积:</strong>
          {{ numberWithComma((totalVolume / 1000000),2) }} m
          <sup>3</sup>
        </v-col>
      </v-row>
      <v-row class="h3 mt-n4">
        <v-col cols="6" sm="3">
          <strong>开票产品成本:</strong> 
          ¥ {{ numberWithComma(vatProductTotalCost,2) }}
        </v-col>
        <v-col cols="6" sm="3">
          <strong>开票金额:</strong> 
          ¥ {{ numberWithComma(postVATTotal,2) }}
        </v-col>
        <v-col cols="6" sm="3">
          <strong>退税金额:</strong> 
          ¥ {{ numberWithComma(taxReturnAmount(),2) }}
        </v-col>
        <v-col cols="6" sm="3">
          <strong>退税净利润:</strong> 
          ¥ {{ numberWithComma(taxProfit(),2) }}
        </v-col>
      </v-row>
      <v-row class="h3 mt-n4">
        <v-col cols="6" sm="3">
          <strong>不开票产品成本:</strong> 
          ¥ {{ numberWithComma(noVatProductTotalCost,2) }}
        </v-col>
        <v-col cols="6" sm="3">
          <strong>所有产品成本:</strong> ¥ {{ numberWithComma(productTotalCost,2) }}
        </v-col>
        <v-col cols="6" sm="3">
          <strong>产品+税:</strong> ¥ {{ numberWithComma(productTotalCostWithTax,2) }}
        </v-col>
        <v-col cols="6" sm="3">
          <strong>总费用:</strong>
          ¥ {{ numberWithComma(totalCost(productTotalCostWithTax,2)) }}
        </v-col>
      </v-row>
      新数额 - 一些数额可能相差0.01  - 待修：
      <div> 开票产品成本￥{{ order.vatProductCost }}  -  开票金额￥{{ order.vatProductAfterTaxCost }}</div>
      <div> 退税金额￥{{ order.taxReturnAmt }}  -  退税净利润￥{{ order.taxReturnProfit }} </div>
      <div>不开票产品成本 ￥{{ order.noVatProductCost }}</div>

    </v-card-text>
  </v-card>
</template>
<style>
</style>
<script>
import { Formatter } from '@/library/utility';
//TODO: computation is done server side, remove all logics that are no longer needed
export default {
  props: {
    order: Object,
    orderItems: Array,
  },
  data: () => ({}),

  computed: {
    totalNumCase() {
      let total = 0;
      this.orderItems.forEach((e) => {
        total += e.numCase;
      });
      return total;
    },
    productTotalCost() {  // all product cost , doesn't include tax
      let total = 0;
      for (let i = 0; i < this.orderItems.length; i++) {
        let e = this.orderItems[i];
        let subtotal = e.numCase * e.qtyPerCase * e.price;
        total += subtotal + e.fee;
      }
      return total;
    },
    productTotalCostWithTax() { // all product cost + prepaid tax
      let total = 0;
      for (let i = 0; i < this.orderItems.length; i++){
        let e = this.orderItems[i];
        let subtotal = e.numCase * e.qtyPerCase * e.price;
        if (e.vat < Number.EPSILON) {
          total += subtotal + e.fee;
          continue;
        }
        if (e.taxReturnCalcType === 0) 
          subtotal = subtotal * (1 + e.vat / 100)
        else 
          subtotal = subtotal / (1 - e.vat / 100);
        total += subtotal + e.fee;
      }
      return total;
    },
    totalWeight() {
      let total = 0;
      this.orderItems.forEach((e) => {
        total += e.numCase * e.weight;
      });
      return Number.parseFloat(total.toFixed(3));
    },
    totalVolume() {
      let total = 0;
      this.orderItems.forEach((e) => {
        total += e.numCase * e.length * e.width * e.height;
      });
      return Number.parseFloat(total.toFixed(3));
    },
    vatProductTotalCost() {
      let total = 0; // 开票产品成本
      this.orderItems.forEach((e) => {
        if (e.vat > Number.EPSILON) {
          total += e.numCase * e.qtyPerCase * e.price;
        }
      });
      return Number.parseFloat(total.toFixed(2));
    },
    noVatProductTotalCost() {
      let total = 0; // 不开票产品成本
      this.orderItems.forEach((e) => {
        if (e.vat < Number.EPSILON) {
          total += e.numCase * e.qtyPerCase * e.price;
        }
      });
      return Number.parseFloat(total.toFixed(2));
    },
    postVATTotal() {
      // 开票金额
      let total = 0;
      this.orderItems.forEach((e) => {
        if (e.vat > Number.EPSILON) {
          if(e.taxReturnCalcType === 0)
            total += e.numCase * e.qtyPerCase * e.price * (1 + e.vat / 100);
          else
            total += e.numCase * e.qtyPerCase * e.price / (1 - e.vat / 100);
        }
      });
      return Number.parseFloat(total.toFixed(2));
    },
  },

  watch: {
    dialog(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.close();
    },
    editedItem(val) {
      if (val) {
        this.editFields.price = val.price;
      }
    },
  },
  methods: {
    ...Formatter,
    taxReturnAmount() { //退税金额
      if (this.order.taxReturn <= Number.EPSILON) return 0;

      const v1 = (1 + this.order.taxReturn / 100).toFixed(3);
      const v2 = ((this.order.taxReturn - this.order.taxReturnFee) / 100).toFixed(3);
      let amount = this.postVATTotal / v1;
      amount *= v2;
      return amount.toFixed(3);
    },
    taxProfit() { //退税净利润
      if (this.order.taxReturnFee > this.order.taxReturn) return 0;
      const taxReturnAmount = this.taxReturnAmount();
      if (taxReturnAmount < Number.EPSILON) return 0;
      // computed property is returning string, need float
      const vatProductTotalCost = Number.parseFloat(this.vatProductTotalCost);
      const amount = taxReturnAmount - this.postVATTotal + vatProductTotalCost;
      return amount.toFixed(3);
    },
    // excluding shipping cost (shipping2)
    totalCost(orderItemsTotalCost) {
      let total = Number.parseFloat(orderItemsTotalCost);
      total += this.order.shipping + this.order.shipping2 + this.order.otherfee;
      return total.toFixed(3);
    },
  },
};
</script>
