// not used yet, need refactor
export default {
  company: [(v) => !!v || '公司不能为空'],
  integer: [
    (v) => Number.isInteger(parseFloat(v)) || '必须为整数',
  ],
  note: [(v) => v.trim().length <= 2000 || '备注不能多于2000字符'],
  notEmpty: [(value) => {
    if (!value) return '不能为空';
    let v = value;
    if (typeof value === 'number') v = value.toString();
    return v.trim().length > 0 || '不能为空';
  }],
  sku: [
    (value) => {
      const pattern = /^[a-zA-Z0-9 *()\-_]+$/;
      return pattern.test(value) || 'SKU不能为空或含非法字符';
    },
  ],
  positiveInteger: [
    (v) => Number.isInteger(parseFloat(v)) || '必须为整数',
    (v) => parseInt(v, 10) > 0 || '必须>0',
  ],
  positiveFloat: [
    (v) => {
      const pattern = /([0-9]*[.])?[0-9]+/;
      return pattern.test(v) || '需为数字';
    },
    (v) => parseFloat(v, 10) >= 0 || '必须>=0',
  ],
  unit: [
    (v) => {
      const unitLength = v.toString().trim().length;
      if (unitLength === 0) return '单位不能为空';
      return unitLength <= 5 || '单位需少于5个字符';
    },
  ],
  upc: [
    (value) => {
      const pattern = /^$|^[0-9]{12,13}$/;
      return pattern.test(value) || 'UPC为12~13位数字';
    },
  ],
  file: [(value) => !value || value.size < 2000000 || '图片大小必须小于 2 MB!'],
  username: [
    (value) => value.trim().length >= 4 || '用户名长度至少为4位',
    (value) => {
      const n = value.charCodeAt(0);
      return (
        (n >= 65 && n <= 90)
              || (n >= 97 && n <= 122)
              || '用户名开头必须为字母'
      );
    },
    (value) => {
      const pattern = /^[a-zA-Z][a-zA-Z0-9]+$/;
      return pattern.test(value) || '用户名含非法字符';
    },
  ],
  email: [
    (value) => {
      const pattern = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/;
      return pattern.test(value) || '无效邮箱!';
    },
  ],
  password: [
    (value) => {
      const pattern = /^(?=.*[A-Za-z])(?=.*\d)[\x20-\x7E]{8,100}$/;
      return (
        pattern.test(value)
              || '密码需要含至少1个字母和1个数字， 长度最少8位'
      );
    },
  ],

};
