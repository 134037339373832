<template>
  <div>
    <v-card flat>
      <v-card-title>
        新供应商
        <v-spacer />
        <v-btn
          color="primary"
          type="submit"
          form="createForm"
          :disabled="!valid"
        >
          创建
        </v-btn>
      </v-card-title>
    </v-card>
    <v-container>
      <v-divider />

      <v-form v-model="valid" id="createForm" @submit.prevent="createVendor()">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="item.name"
              label="名字"
              name="name"
              type="text"
              :rules="rule.name"
              outlined
            />
          </v-col>
        </v-row>
        <v-row class="mt-n7">
          <v-col md="6">
            <v-text-field
              v-model.trim="item.address"
              label="地址"
              name="address"
              outlined
          /></v-col>
          <v-col md="6">
            <v-text-field
              v-model.trim="item.address2"
              label="地址2"
              name="address2"
              outlined
          /></v-col>
        </v-row>
        <v-row class="mt-n7">
          <v-col md="6">
            <v-text-field
              v-model.trim="item.email"
              label="邮箱"
              name="email"
              prepend-inner-icon="mdi-email"
              outlined
          /></v-col>
          <v-col md="6">
            <v-text-field
              v-model.trim="item.wechat"
              label="微信"
              name="wechat"
              outlined
          /></v-col>
        </v-row>
        <v-row class="mt-n7">
          <v-col cols="6" md="4">
            <v-text-field
              v-model.trim="item.contactName"
              label="联系人"
              name="contact"
              outlined
          /></v-col>
          <v-col cols="6" md="4">
            <v-text-field
              v-model.trim="item.phone"
              label="电话"
              name="phone"
              outlined
          /></v-col>
          <v-col cols="6" md="4">
            <v-text-field
              v-model.trim="item.phone2"
              label="电话2"
              name="phone2"
              outlined
          /></v-col>
        </v-row>
        <v-row class="mt-n7">
          <v-col>
            <v-textarea
              v-model="item.note"
              :rules="[(v) => v.length <= 2000 || '备注不能超过2000字符']"
              name="note"
              counter
              outlined
              label="备注"
              auto-grow
              prepend-inner-icon="mdi-note"
            />
          </v-col>
        </v-row>
        <v-row v-if="$user.data.isAdmin"><v-col>
        <CompanySelection
                    :value.sync="item.company"
                    :rules="rule.company" /></v-col></v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import CompanySelection from '@/components/CompanySelection.vue';
import bus from '@/eventBus';

import rule from '@/library/rule';

export default {
  components: { CompanySelection },
  data: () => ({
    valid: false,
    loading: false,
    rule,
    companyItems: [],
    item: {
      name: '',
      address: '',
      address2: '',
      contactName: '',
      phone: '',
      phone2: '',
      note: '',
      email: '',
      wechat: '',
    },
  }),
  methods: {
    createVendor() {
      this.$http
        .post('/vendor', this.item)
        .then((response) => {
          bus.$emit('success', '创造成功');
          this.$store.commit('addVendor', response.data);
          this.$router.push(`/vendor/${response.data._id}`);
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        });
    },
  },
};
</script>
