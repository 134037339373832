<template>
  <v-app>
    <v-snackbar v-model="snackbar" :timeout="4000" :color="snackbarColor">
      {{ snackbarText }}
      <v-btn color="white" text @click="snackbar = false"> x </v-btn>
    </v-snackbar>
    <Navbar class="d-print-none" v-if="show_nav" />
    <v-main v-cloak>
      <router-view
        @error="displayErrorMsg"
        @info="displayInfoMsg"
        @success="displaySuccessMsg"
      />
    </v-main>

    <v-snackbar
      bottom
      right
      :value="updateExists"
      :timeout="-1"
      color="primary"
    >
      An update is available
      <v-btn text @click="refreshApp"> 更新 </v-btn>
    </v-snackbar>
  </v-app>
</template>

<style>
[v-cloak] > * {
  display: none;
}
[v-cloak]::before {
  content: "loading...";
}

@media print {
  .v-main {
    padding: 0 !important;
  }
}
.pointer {
  cursor: pointer;
}
</style>

<script>
import Navbar from '@/components/Navbar.vue';
import bus from '@/eventBus';
import update from './mixins/update';

export default {
  name: 'App',

  components: {
    Navbar,
  },
  mixins: [update],
  data: () => ({
    snackbar: false,
    snackbarText: '',
    snackbarColor: 'success',
  }),
  computed: {
    show_nav() {
      return this.$route.path !== '/login';
    },
  },
  created() {
    bus.$on('error', this.displayErrorMsg);
    bus.$on('info', this.displayInfoMsg);
    bus.$on('success', this.displaySuccessMsg);
  },
  methods: {
    displayErrorMsg(msg) {
      this.snackbarColor = 'error';
      this.snackbar = true;
      this.snackbarText = msg;
    },
    displayInfoMsg(msg) {
      this.snackbarColor = 'info';
      this.snackbar = true;
      this.snackbarText = msg;
    },
    displaySuccessMsg(msg) {
      this.snackbarColor = 'success';
      this.snackbar = true;
      this.snackbarText = msg;
    },
  },
};
</script>
