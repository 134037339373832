<template>
  <v-dialog v-model="dialog" max-width="300px">
    <v-card>
      <v-card-title> 确认删除 </v-card-title>
      <br />
      <v-card-text style="text-align: center">
        <span class="red--text">此操作将无法逆转</span><br />
        请输入 <br />
        <strong style="font-size: 120%" class="blue--text">{{ text }} </strong
        ><br />
        确定删除
        <v-text-field
          v-model="deleteConfirmInput"
          outlined
          class="mt-4"
          dense
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="dialog = false"> 取消 </v-btn>
        <v-btn
          color="red darken-1"
          :disabled="!isMatchingConfirmValue"
          text
          @click="$emit('delete')"
        >
          删除
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style>
</style>

<script>
import bus from '@/eventBus';

export default {
  props: {
    text: { type: [String, Number], default: '确认' },
  },
  data: () => ({
    deleteConfirmInput: '',
    dialog: false,
  }),
  created() {
    this.dialog = false;
    bus.$on('confirm-delete', this.display);
  },
  methods: {
    display() {
      this.dialog = true;
    },
  },
  computed: {
    isMatchingConfirmValue() {
      let cmpText = this.text;
      if (typeof (this.text) === 'number') {
        cmpText = this.text.toString();
      }
      cmpText = cmpText.trim();
      return cmpText === this.deleteConfirmInput.trim();
    },
  },
};
</script>
