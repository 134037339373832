<template>
  <v-container fluid>
    <v-combobox
      v-model="model"
      :filter="filter"
      :hide-no-data="!search"
      :items="items"
      :search-input.sync="search"
      hide-selected
      label="分类"
      multiple
      small-chips
      outlined
      @focus="fetchPossibleCategories"
      @change="createCategory"
      deletable-chips
    >
      <template v-slot:no-data>
        <v-list-item v-if="categoriesInitiated">
          <span class="subheading">创造新分类</span>
          <v-chip :color="`${colors[nonce - 1]} lighten-3`" label small>
            {{ search }}
          </v-chip>
        </v-list-item>
        <v-list-item v-else>
          <span class="subheading">加载中!</span>
        </v-list-item>
      </template>
      <template v-slot:selection="{ attrs, item, selected }">
        <v-chip
          v-bind="attrs"
          :color="`${item.color} lighten-3`"
          :input-value="selected"
          label
          small
        >
          <span class="pr-2">
            {{ item.text }}
          </span>
          <v-icon small @click="deleteCategoryMapping(item)"> $delete </v-icon>
        </v-chip>
      </template>
      <template v-slot:item="{ item }">
        <v-chip :color="`${item.color} lighten-3`" dark label small>
          {{ item.text }}
        </v-chip>
        <v-spacer></v-spacer>
        <!-- <v-list-item-action @click.stop>
          <v-btn
            icon
            @click.stop.prevent="edit(index, item)"
          >
            <v-icon>{{ editing !== item ? 'mdi-pencil' : 'mdi-check' }}</v-icon>
          </v-btn>
        </v-list-item-action> -->
      </template>
    </v-combobox>
  </v-container>
</template>

<script>
import bus from '@/eventBus';

export default {
  data: () => ({
    activator: null,
    attach: null,
    categoriesInitiated: false,
    colors: ['green', 'purple', 'indigo', 'cyan', 'teal', 'orange'],
    editing: null,
    editingIndex: -1,
    items: [{ header: '搜索或创造新分类' }],
    nonce: 1,
    model: [],
    search: null,
  }),

  created() {
    this.fetchCategoryMappings();
  },

  methods: {
    fetchCategoryMappings() {
      this.$http
        .get(`/category/products/${this.$route.params.id}`)
        .then((response) => {
          for (let i = 0; i < response.data.length; i += 1) {
            const e = response.data[i];
            e._id = e.category._id;
            e.text = e.category.name;
            e.color = this.colors[i % this.colors.length];
            this.items.push(e);
            this.model.push(e);
          }
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        });
    },
    fetchPossibleCategories() {
      if (this.categoriesInitiated) return;
      this.$http
        .get(`/category/products/all/${this.$route.params.id}`)
        .then((response) => {
          this.categoriesInitiated = true;
          this.items = [];
          this.items.push({ header: '搜索或创造新分类' });
          for (let i = 0; i < response.data.length; i += 1) {
            const category = response.data[i];
            category.text = category.name;
            category.color = this.colors[i % this.colors.length];
            this.items.push(response.data[i]);
          }
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        });
    },
    edit(index, item) {
      if (!this.editing) {
        this.editing = item;
        this.editingIndex = index;
      } else {
        this.editing = null;
        this.editingIndex = -1;
      }
    },
    createCategory(v) {
      const element = v[v.length - 1];
      if (typeof element === 'string') {
        this.createCategoryAndMapping(element);
      } else {
        this.addCategoryMapping(element);
      }
    },
    createCategoryAndMapping(name) {
      const productId = this.$route.params.id;
      this.$http
        .post('/category/productMap', { productId, name })
        .then((response) => {
          this.model[this.model.length - 1] = response.data;
          const modelItem = this.model[this.model.length - 1];
          modelItem.text = modelItem.name;
          modelItem.color = this.colors[this.model.length % this.colors.length];
          this.items.push(modelItem);
        })
        .catch((err) => {
          bus.$emit('error', this.$error.getMsg(err));
          this.model.pop();
          this.items.pop();
        });
    },
    addCategoryMapping(v) {
      if (!v._id) {
        this.model.pop();
        bus.$emit('error', '删除失败, 分类不存在');
        return;
      }
      const categoryId = v._id;
      this.$http
        .post(`/category/${categoryId}/products/${this.$route.params.id}`)
        .then((response) => {
          bus.$emit('success', '添加成功');
        })
        .catch((err) => {
          this.model.pop();
          this.items.pop();
          bus.$emit('error', this.$error.getMsg(err));
        })
        .finally(() => {
          this.saving = false;
        });
    },
    deleteCategoryMapping(v) {
      console.log(v);
      if (!window.confirm(`确定要把产品移出 ${v.text} 分类?`)) return;
      const categoryId = v._id;
      this.$http
        .delete(`/category/${categoryId}/products/${this.$route.params.id}`)
        .then((response) => {
          bus.$emit('success', '删除成功');
          const index = this.model.indexOf(v);
          this.model.splice(index, 1);
        })
        .catch((err) => {
          bus.$emit('error', this.$error.getMsg(err));
        })
        .finally(() => {
          this.saving = false;
        });
    },
    filter(item, queryText, itemText) {
      if (item.header) return false;
      const hasValue = (val) => (val != null ? val : '');
      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) > -1;
    },
  },
};
</script>
