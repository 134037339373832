import LazyLoad from '@/router/lazyload';

const routes = [
  {
    path: '/company', name: 'Companies', component: LazyLoad('Company/Companies'), meta: { title: '公司' },
  },
  {
    path: '/company/new', name: 'CreateCompany', component: LazyLoad('Company/CreateCompany'), meta: { title: '新公司' },
  },
  { path: '/company/:id', name: 'ViewEditCompany', component: LazyLoad('Company/ViewEditCompany') },
];

export default routes;
