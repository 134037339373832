<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    min-width="auto"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="localDateFormatted"
        :label="label"
        dense
        persistent-hint
        prepend-inner-icon="mdi-calendar"
        :readonly="readonly"
        :clearable="clearable"
        hint="MM/DD/YYYY"
        @blur="localDate = parseDate(localDateFormatted)"
        outlined
        v-on="on"
        :rules="rule"
        :background-color="bgColor"
      ></v-text-field>
    </template>
    <v-date-picker locale="zh-cn" :day-format="getDay" v-model="localDate">
    </v-date-picker>
  </v-menu>
</template>
<style>
</style>
<script>
/* example, returned value will be in iso format
        :date="editFields.endDate"
        @update='(v)=>editFields.endDate=v'/>
      */
export default {
  props: {
    clearable: { type: Boolean, default: true },
    label: { type: String, default: '' },
    bgColor: { type: String, default: 'white' },
    date: { type: String, default: undefined },
    required: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
  },
  data: (vm) => ({
    menu: false,
    modal: false,
    localDate: new Date().toISOString().substr(0, 10),
    localDateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
  }),
  created() {
    this.localDate = this.date;
  },
  methods: {
    getDay(date) {
      return Number.parseInt(date.split('-')[2], 10);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
  },
  watch: {
    localDate(val) {
      this.localDateFormatted = this.formatDate(this.localDate);
      this.$emit('update', this.localDate);
    },
    date(val) {
      this.localDate = val;
    },
  },
  computed: {
    rule() {
      const rules = [];
      if (this.required) {
        rules.push((v) => !!v || '不能为空');
      }
      rules.push((v) => {
        if (!v) return true;
        const pattern = /^$|[01]\d\/[0-3]\d\/\d{4}/;
        return pattern.test(v) || 'MM/DD/YYYY';
      });
      return rules;
    },
  },
};
</script>
