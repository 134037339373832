<template>
  <v-select
    v-model="value"
    :items="items"
    item-text="name"
    item-value="_id"
    outlined
    :rules="rule"
    no-data-text="无数据"
    prepend-inner-icon="mdi-domain"
    label="公司"
  />
</template>

<script>
export default {
  props: ['rule'],
  data: () => ({
    value: undefined,
    items: [],
  }),
  created() {
    this.fetchCompanySelectionList();
  },
  methods: {
    async fetchCompanySelectionList() {
      try {
        const res = await this.$http.get('company');
        this.items = res.data.docs;
        if (this.items.length === 1) {
          this.value = this.items[0]._id;
        }
      } catch (error) {
        this.$emit('error', this.$error.getMsg(error));
      }
    },
  },
  watch: {
    value(val, prev) {
      this.$emit('update:value', val);
    },
  },
};
</script>
