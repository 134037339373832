<template>
  <v-autocomplete
    v-model="model"
    :items="items"
    :loading="isLoading"
    :search-input.sync="search"
    clearable
    hide-details
    hide-no-data
    prepend-inner-icon="mdi-database-search"
    item-text="sku"
    item-value="_id"
    label="搜索产品"
    solo
  >
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title>
          输入<strong>货号</strong>或<strong>名字</strong>进行搜索
        </v-list-item-title>
      </v-list-item>
    </template>
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title v-text="item.sku" />
        <v-list-item-subtitle v-text="item.name" />
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import bus from '@/eventBus';

export default {
  data() {
    return {
      isLoading: false,
      model: null,
      search: null,
      items: [],
      modelChanged: false,
    };
  },
  methods: {
    clearEntries() {
      this.items = [];
    },
    fetchEntriesDebounced() {
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.fetchEntries();
      }, 500); /* 500ms throttle */
    },
    fetchEntries() {
      this.$http
        .get(`/product/search?q=${this.search}`)
        .then((response) => {
          const list = response.data;
          list.forEach((e) => {
            e.combined = `${e.sku} - ${e.name}`;
          });
          this.items = list;
        })
        .catch((err) => {
          bus.$emit('error', this.$error.getMsg(err));
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  watch: {
    model(val) {
      this.modelChanged = true;
      this.$emit('update:input', val);
    },
    search(val) {
      // don't perform search if model changed last tick
      if (this.modelChanged) {
        this.modelChanged = false;
        return;
      }
      // this.clearEntries();
      // only search when search string length is >2
      if (!this.search || this.search.length < 2) return;
      this.isLoading = true;
      this.fetchEntriesDebounced();
    },
  },
};
</script>
