<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      hide-default-footer
      :items-per-page="pagination.rowsPerPage"
      class="elevation-1 mt-5"
    >
      <template v-slot:top>
        <v-toolbar flat color="blue-grey lighten-2 ">
          <v-toolbar-title>订单历史</v-toolbar-title>
        </v-toolbar>
      </template>
      <template v-slot:no-data> 无订单记录 </template>
      <template v-slot:[`item.price`]="{ item }"> ¥ {{ item.price }} </template>
      <template v-slot:[`item.qtyRemain`]="{ item }">
        <span
          :class="item.qty > item.qtyReceived ? 'red--text' : 'green--text'"
        >
          {{ item.qty - item.qtyReceived }}
        </span>
        / {{ item.qty }}
      </template>
      <template v-slot:[`item.num`]="{ item }">
        <a :href="`/po/` + item._id"> {{ item.num }}</a>
      </template>
      <template v-slot:[`item.startDate`]="{ item }">
        {{ formatDate(item.startDate) }}
      </template>
      <template v-slot:[`item.endDate`]="{ item }">
        {{ formatDate(item.endDate) }}
      </template>
      <template v-slot:[`item.vendor.name`]="{ item }">
        <div v-if="!item.vendor"></div>
        <a v-else :href="`/vendor/` + item.vendor._id">
          {{ item.vendor.name }}</a
        >
      </template>
    </v-data-table>

    <v-pagination
      :value="pagination.page"
      :length="pages"
      :total-visible="12"
      circle
      @input="paginationChangeHandler"
    />
  </div>
</template>
<style>
</style>

<script>
import bus from '@/eventBus';

export default {
  data: () => ({
    items: [],
    pagination: { totalDocs: 0, rowsPerPage: 20, page: 1 },
    headers: [
      { text: '单号', value: 'num' },
      { text: '价格', value: 'price', sortable: false },
      { text: '欠收', value: 'qtyRemain', sortable: false },
      { text: '签订日期', value: 'startDate' },
      { text: '到期日期', value: 'endDate' },
      { text: 'PO号码', value: 'poNum', sortable: false },
      { text: '供应商名', value: 'vendor.name', sortable: false },
      { text: '备注', value: 'note', sortable: false },
    ],
  }),
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      const limit = this.pagination.rowsPerPage;
      let offset = (this.pagination.page - 1) * limit;
      if (offset < 0) offset = 0;
      this.loading = true;
      this.$http
        .get(`/po/product/${this.$route.params.id}?limit=${limit}&offset=${offset}`)
        .then((response) => {
          this.items = response.data.docs;
          this.pagination.totalDocs = response.data.totalDocs;
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    paginationChangeHandler(pageNumber) {
      if (this.pagination.page === pageNumber) return;
      this.pagination.page = pageNumber;
      this.fetch();
    },
    formatDate(v) {
      if (!v) return '';
      const d = v.substr(0, 10).split('-');
      return `${d[1]} / ${d[2]} / ${d[0].substr(2)}`;
    },
  },
  computed: {
    pages() {
      if (this.pagination.rowsPerPage == null || this.pagination.totalDocs == null) {
        return 0;
      }
      return Math.ceil(this.pagination.totalDocs / this.pagination.rowsPerPage);
    },
  },
};
</script>
