<template>
  <div>
    <v-text-field
      hide-details
      prepend-icon="mdi-magnify"
      single-line
      v-model.trim="search"
      clearable
      outlined
    ></v-text-field>
    <v-divider class="my-4" />
    <v-data-table :headers="headers" :items="items">
      <template v-slot:[`item.action`]="{ item }">
        <v-btn color="green" @click="linkProduct(item.id)"> 连接 </v-btn>
      </template>
      <template v-slot:[`item.price`]="{ item }">
        $ {{ item.whole_prs }} / {{ item.unit_nm }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import bus from '@/eventBus';

export default {
  data() {
    return {
      isLoading: false,
      search: '',
      items: [],
      headers: [
        { text: 'sku', align: 'center', value: 'sku' },
        { text: '名字', value: 'descrip' },
        { text: '价格', value: 'price' },
        { text: '', value: 'action' },
      ],
    };
  },
  methods: {
    clearEntries() {
      this.items = [];
    },
    fetchEntriesDebounced() {
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.fetchEntries();
      }, 500); /* 500ms throttle */
    },
    fetchEntries() {
      this.$http
        .get(`/product/search/oms?q=${this.search}`)
        .then((response) => {
          this.items = response.data;
        })
        .catch((err) => {
          bus.$emit('error', this.$error.getMsg(err));
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    linkProduct(sqlId) {
      this.$emit('link', sqlId);
    },
  },
  watch: {
    search(val) {
      this.clearEntries();
      if (!this.search) return;
      this.isLoading = true;
      this.fetchEntriesDebounced();
    },
  },
};
</script>
