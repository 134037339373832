import Vendors from '@/views/Vendor/Vendors.vue';
import CreateVendor from '@/views/Vendor/CreateVendor.vue';
import ViewEditVendor from '@/views/Vendor/ViewEditVendor.vue';

const routes = [
  {
    path: '/vendor',
    name: 'Vendors',
    component: Vendors,
    meta: { title: '供应商' },
  },
  {
    path: '/vendor/new',
    name: 'CreateVendor',
    component: CreateVendor,
    meta: { title: '新供应商' },
  },
  { path: '/vendor/:id', name: 'ViewEditVendor', component: ViewEditVendor },
];

export default routes;
