<template>
  <div>
    <v-card flat>
      <v-card-title> 订单 </v-card-title>
    </v-card>
    <v-card>
      <v-card-title>
        搜索
        <v-spacer />
        <v-row class="mt-1"
          ><v-col cols="8" md="4">
            <v-select
              v-model="state"
              :items="states"
              label="滤选"
              item-text="text"
              item-value="value"
              outlined
              dense
            ></v-select></v-col
        ></v-row>
        <ProductSearchAutoComplete class="ml-3" :input.sync="editedItem" />
      </v-card-title>
    </v-card>
    <v-data-table
      :headers="getHeaders()"
      :items="items"
      :loading="loading"
      hide-default-footer
      :items-per-page="pagination.rowsPerPage"
      :sort-desc.sync="sortDesc"
      :sort-by.sync="sortBy"
      @update:sort-by="sortTable"
      @update:sort-desc="sortTable"
      class="elevation-1"
    >
      <template v-slot:[`item.num`]="{ item }">
        <a :href="'/po/' + item._id">{{ item.num }}</a>
      </template>
      <template v-slot:[`item.qtyRemain`]="{ item }">
        <span
          :class="item.qty > item.qtyReceived ? 'red--text' : 'green--text'"
        >
          {{ item.qty - item.qtyReceived }}
        </span>
        / {{ item.qty }}
      </template>
      <template v-slot:[`item.startDate`]="{ item }">
        {{ formatDate(item.startDate) }}
      </template>
      <template v-slot:[`item.daysSinceStart`]="{ item }">
        <span
          :class="[
            daysSince(item.startDate) > 60 ? 'red--text' : 'green--text',
          ]"
        >
          {{ daysSince(item.startDate) }}</span
        >
      </template>
      <template v-slot:[`item.endDate`]="{ item }">
        {{ formatDate(item.endDate) }}
      </template>
      <template v-slot:[`item.daysSinceEnd`]="{ item }">
        <span
          :class="[daysSince(item.endDate) > 0 ? 'red--text' : 'green--text']"
        >
          {{ daysSince(item.endDate) }}</span
        >
      </template>
      <template v-slot:[`item.stockInfo`]="{ item }">
        <span :class="[item.product.openPO > 0 ? 'green--text' : 'red--text']">
          {{ item.product.openPO }}</span
        >
        -
        {{ item.product.inStock }}
        -
        <span :class="[item.product.enRoute > 0 ? 'green--text' : 'red--text']">
          {{ item.product.enRoute }}</span
        >
      </template>
      <template v-slot:[`item.product.sku`]="{ item }">
        <a :href="`/product/` + item.product._id">
          {{ item.product.sku }}
        </a>
      </template>
      <template v-slot:[`item.vendor`]="{ item }">
        <a :href="`/vendor/` + item.vendor._id">
          {{ item.vendor.name }}
        </a>
      </template>
      <template v-slot:[`item.mainImage`]="{ item }">
        <v-img
          v-if="!!item.product.mainImage"
          :src="'/api/file/image/thumbnail/' + item.product.mainImage"
          lazy-src="/img/placeholder.png"
          max-height="100"
          max-width="100"
          contain
        />
      </template>
    </v-data-table>
    <v-pagination
      :value="pagination.page"
      :length="pages"
      :total-visible="12"
      circle
      @input="paginationChangeHandler"
    />
  </div>
</template>

<script>
import bus from '@/eventBus';
import ProductSearchAutoComplete from '@/components/ProductSearchAutoComplete.vue';

export default {
  components: { ProductSearchAutoComplete },
  data() {
    return {
      items: [],
      sortDesc: true,
      sortBy: '',
      state: 0,
      states: [
        { text: '所有', value: -1 },
        { text: '未交', value: 0 },
        { text: '已交', value: 1 },
      ],
      editedItem: null,
      productFilter: '',
      loading: false,
      pagination: { totalItems: 0, rowsPerPage: 25, page: 1 },
      headers: [
        { text: '号码', align: 'center', value: 'num' },
        { text: '产品序号', value: 'product.sku', sortable: false },
        { text: '图片', value: 'mainImage', sortable: false },
        { text: '产品名字', value: 'product.name', sortable: false },
        { text: '装箱', value: 'qtyPerCase', sortable: false },
        { text: '欠收件数',value: 'qtyRemain',sortable: false },
        { text: '价格', value: 'price', sortable: false },
        { text: '下单日期', value: 'startDate' },
        { text: '下单日数', value: 'daysSinceStart', sortable: false },
        { text: '交货日期', value: 'endDate' },
        { text: '越期日数', value: 'daysSinceEnd', sortable: false },
        { text: '订单/未出/路上', value: 'stockInfo', sortable: false },
        { text: '预测库存', value: 'product.projectedQty', sortable: false },
        { text: 'PO号码', value: 'poNum', sortable: false },
        { text: '供应商名字', value: 'vendor', sortable: false },
        { text: '公司', value: 'company.name', adminOnly: true },
      ],
    };
  },
  computed: {
    pages() {
      if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null) {
        return 0;
      }

      return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage);
    },
  },
  created() {
    const pageNumber = parseInt(this.$route.query.page, 10);
    this.state = parseInt(this.$route.query.state, 10) || 0;
    this.sortBy = this.$route.query.sortBy;
    this.sortDesc = this.$route.query.sortDesc === 'true';
    this.pagination.page = Number.isNaN(pageNumber) ? 0 : pageNumber;
    this.productFilter = this.$route.query.s;
    this.fetch();
  },
  methods: {
    fetch() {
      this.loading = true;
      this.items = [];
      const limit = this.pagination.rowsPerPage;
      let offset = (this.pagination.page - 1) * limit;
      if (offset < 0) offset = 0;
      if (this.productFilter) this.fetchFiltered(limit, offset);
      else this.fetchUnfilterd(limit, offset);
    },
    fetchUnfilterd(limit, offset) {
      let link = `/po?limit=${limit}&offset=${offset}&state=${this.state}`;
      if (this.sortBy) {
        link += `&sortBy=${this.sortBy}&sortDesc=${!!this.sortDesc}`;
      }
      this.$http
        .get(link)
        .then((response) => {
          this.items = response.data.docs;
          this.pagination.totalItems = response.data.totalDocs;
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // fetch by product id
    fetchFiltered(limit, offset) {
      const link = `/po/product/${this.productFilter}?limit=${limit}&offset=${offset}`;
      this.$http
        .get(link)
        .then((response) => {
          this.items = response.data.docs;
          this.pagination.totalItems = response.data.totalDocs;
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    sortTable() {
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.fetch();
      }, 500); /* 500ms throttle */
    },
    paginationChangeHandler(pageNumber) {
      if (this.pagination.page === pageNumber) return;
      this.pagination.page = pageNumber;
      this.updateRoute();
      this.fetch();
    },
    updateRoute() {
      this.$router
        .replace({
          path: '/po',
          query: {
            page: this.pagination.page,
            filter: this.productFilter,
            state: this.state,
            sortBy: this.sortBy,
            sortDesc: this.sortDesc,
          },
        })
        .catch((err) => {}); // don't care about duplicate navigation error here
    },
    getHeaders() {
      const { isAdmin } = this.$user.data;
      if (isAdmin) return this.headers;
      const headers = [];
      this.headers.forEach((e) => {
        if (!e.adminOnly) {
          headers.push(e);
        }
      });
      return headers;
    },
    formatDate(v) {
      if (!v) return '';
      const d = v.substr(0, 10).split('-');
      return `${d[1]} / ${d[2]} / ${d[0].substr(2)}`;
    },
    daysSince(date) {
      const now = Date.now();
      const diff = now - new Date(date);
      const diffDays = diff / (1000 * 3600 * 24);
      return Math.floor(diffDays);
    },
  },
  watch: {
    editedItem(val) {
      if (this.editedItem) {
        this.productFilter = this.editedItem;
      } else {
        this.productFilter = '';
      }
      this.updateRoute();
      this.pagination.page = 0;
      this.fetch();
    },
    state(val) {
      this.pagination.page = 0;
      this.updateRoute();
      this.fetch();
    },
    sortBy(val) {
      this.pagination.page = 0;
      this.updateRoute();
    },
    sortDesc(val) {
      this.pagination.page = 0;
      this.updateRoute();
    },
  },
};
</script>

<style scoed>
tr:nth-of-type(even) {
  background-color: rgba(92, 83, 206, 0.05);
}
</style>
