<template>
  <div>
    <v-card flat>
      <v-tabs v-model="tab">
        <v-tab href="#1">资料</v-tab>
        <v-tab href="#2">附件({{ item.attachments }})</v-tab>
        <v-tab href="#3">图片({{ item.images.length }})</v-tab>
        <v-tab href="#4">订单/供应商</v-tab>
        <v-tab href="#5" v-if="$user.data.isAdmin">OMS</v-tab>
      </v-tabs>
      <v-card-title class="mb-n5">
        产品 - {{ item.sku }}

        <v-menu offset-y v-if="showAdditionalMenu">
          <template v-slot:activator="{ on, attrs }">
            <v-icon dense v-bind="attrs" v-on="on" class="ml-3"
              >mdi-dots-horizontal-circle-outline</v-icon
            >
          </template>
          <v-list>
            <v-list-item class="pointer">
              <v-list-item-title @click="unlinkProduct"
                >取消OMS连接</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
        <v-spacer />
        <v-btn
          v-if="editMode && tab == '1'"
          color="primary"
          class="mr-6"
          :loading="saving"
          type="submit"
          form="editProductForm"
          :disabled="!valid"
        >
          <v-icon>mdi-content-save</v-icon>保存
        </v-btn>
        <v-switch
          v-if="showEditToggle"
          v-model="editMode"
          label="修改模式"
          class="mr-5"
          @change="updateRoute"
        />
      </v-card-title>
      <v-divider class="mb-3" />
      <v-tabs-items v-model="tab">
        <v-tab-item value="1">
          <v-container>
            <div>
              <v-form
                id="editProductForm"
                v-model="valid"
                form="editProductForm"
                @submit.prevent="saveEdit()"
              >
                <v-row dense>
                  <v-col cols="12" md="4">
                    <v-row cols="12">
                      <v-col cols="12">
                        <v-card height="270">
                          <v-card-text>图片</v-card-text>
                          <div v-if="item.mainImage !== ''">
                            <a :href="`/api/file/image/${item.mainImage}`">
                              <v-img
                                :src="`/api/file/image/thumbnail/${item.mainImage}`"
                                max-height="200"
                                contain
                              />
                            </a>
                          </div>
                          <div v-else>
                            <v-card-actions>
                              <v-card-text>无图片</v-card-text>
                              <v-btn
                                color="deep-purple lighten-2"
                                text
                                @click="goToImageTab()"
                              >
                                上传图片
                              </v-btn>
                            </v-card-actions>
                          </div>
                        </v-card>
                      </v-col>
                      <v-col>
                        <CategoryComboBox />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col>
                    <v-row>
                      <v-col cols="12">
                        <v-row>
                          <v-col cols="4" class="mb-n7">
                            <v-select
                              v-model="item.state"
                              :items="productStates"
                              item-text="name"
                              item-value="value"
                              outlined
                              no-data-text="无数据"
                              label="状态"
                              :readonly="!editMode"
                              :background-color="textInputBgColor"
                            />
                          </v-col>
                          <v-col cols="8" class="mb-n7">
                            <v-text-field
                              v-model.trim="item.sku"
                              label="产品序号"
                              name="sku"
                              type="text"
                              :rules="rule.sku"
                              :readonly="!editMode"
                              outlined
                              :background-color="textInputBgColor"
                          /></v-col>
                          <v-col cols="6" class="mb-n7">
                            <v-text-field
                              v-model.number="item.price"
                              label="产品单价"
                              name="price"
                              type="number"
                              step="0.01"
                              prefix="¥"
                              :readonly="!editMode"
                              outlined
                              :background-color="textInputBgColor"
                            />
                          </v-col>
                          <v-col cols="6" sm="3" class="mb-n7">
                            <v-text-field
                              v-model.trim="item.qtyPerCase"
                              label="装箱量"
                              name="qtyPerCase"
                              type="number"
                              :rules="rule.integer"
                              :readonly="!editMode"
                              outlined
                              :background-color="textInputBgColor"
                            />
                          </v-col>
                          <v-col cols="6" sm="3" class="mb-n7">
                            <v-text-field
                              v-model.number="item.unit"
                              label="单位"
                              name="unit"
                              :readonly="!editMode"
                              outlined
                              :rules="rule.unit"
                              :background-color="textInputBgColor"
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="6" class="mb-n6">
                            <v-text-field
                              v-model="item.name"
                              label="产品名字/中文名"
                              name="name"
                              type="text"
                              :rules="rule.notEmpty"
                              :readonly="!editMode"
                              outlined
                              :background-color="textInputBgColor"
                            />
                          </v-col>
                          <v-col cols="6" class="mb-n6">
                            <v-text-field
                              v-model.trim="item.upc"
                              label="UPC"
                              name="upc"
                              :rules="rule.upc"
                              :readonly="!editMode"
                              outlined
                              :background-color="textInputBgColor"
                              prepend-inner-icon="mdi-barcode"
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="4" sm="3" class="mb-n6">
                            <v-text-field
                              v-model.number="item.length"
                              label="长度"
                              name="length"
                              type="number"
                              step="0.01"
                              suffix="cm"
                              :readonly="!editMode"
                              outlined
                              :background-color="textInputBgColor"
                            />
                          </v-col>
                          <v-col cols="4" sm="3" class="mb-n6">
                            <v-text-field
                              v-model.number="item.width"
                              label="宽度"
                              name="width"
                              type="number"
                              step="0.01"
                              suffix="cm"
                              :readonly="!editMode"
                              outlined
                              :background-color="textInputBgColor"
                            />
                          </v-col>
                          <v-col cols="4" sm="3" class="mb-n6">
                            <v-text-field
                              v-model.number="item.height"
                              label="高度"
                              name="height"
                              type="number"
                              step="0.01"
                              suffix="cm"
                              :readonly="!editMode"
                              outlined
                              :background-color="textInputBgColor"
                            />
                          </v-col>
                          <v-col cols="4" sm="3" class="mb-n6">
                            <v-text-field
                              v-model.number="item.weight"
                              label="重量"
                              name="weight"
                              type="number"
                              step="0.01"
                              suffix="kg"
                              :rules="rule.positiveFloat"
                              :readonly="!editMode"
                              outlined
                              :background-color="textInputBgColor"
                            />
                          </v-col>
                          <v-col cols="3" class="mb-n6">
                            <v-text-field
                              v-model.number="item.customTax"
                              label="关税"
                              name="height"
                              type="number"
                              step="0.01"
                              suffix="%"
                              :readonly="!editMode"
                              outlined
                              :rules="rule.positiveFloat"
                              :background-color="textInputBgColor"
                            />
                          </v-col>
                          <v-col cols="6" md="4">
                            体积：{{ getVolume }} m<sup>3</sup> /
                            {{ getVolumeSF }} f<sup>3</sup>
                          </v-col>
                          <v-col col="4" lg="3">
                            库存:
                            <span class="font-weight-bold">{{
                              item.projectedQty
                            }}</span>
                          </v-col>
                        </v-row>
                      </v-col></v-row
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="mt-6">
                    <v-row class="mt-n4">
                      <v-col cols="4">
                        <span class="text-subtitle-1">欠收: </span>
                        <span class="text-h6 font-weight-bold">
                          {{ item.openPO }}
                        </span></v-col
                      >
                      <v-col cols="4">
                        <span class="text-subtitle-1">未出: </span>
                        <span class="text-h6 font-weight-bold">
                          {{ item.inStock }}
                        </span></v-col
                      >
                      <v-col cols="4">
                        <span class="text-subtitle-1">路上: </span>
                        <span class="text-h6 font-weight-bold">
                          {{ item.enRoute }}
                        </span></v-col
                      >
                    </v-row></v-col
                  >
                  <v-col cols="12" md="6">
                    <v-textarea
                      v-model="item.note"
                      name="note"
                      outlined
                      :background-color="textInputBgColor"
                      label="备注"
                      rows="3"
                      :readonly="!editMode"
                      auto-grow
                    />
                  </v-col>
                </v-row>
              </v-form>

              <v-data-table
                :headers="headers"
                :items="orderHistory"
                :loading="orderHistoryLoading"
                hide-default-footer
                :items-per-page="pagination.rowsPerPage"
                class="elevation-1 mt-2"
              >
                <template v-slot:top>
                  <v-toolbar flat color="blue-grey lighten-2 ">
                    <v-toolbar-title>出货历史</v-toolbar-title>
                  </v-toolbar>
                </template>
                <template v-slot:no-data> 无单子 </template>
                <template v-slot:[`item.price`]="{ item }">
                  ¥ {{ item.price }}
                </template>
                <template v-slot:[`item.num`]="{ item }">
                  <a :href="`/order/` + item.order._id">
                    {{ item.order.num }}</a
                  >
                </template>
                <template v-slot:[`item.shippingRate`]="{ item }">
                  ${{ item.order.shippingRate }}
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <OrderStatusIcon :state="item.order.state" />
                </template>
              </v-data-table>

              <v-pagination
                :value="pagination.page"
                :length="pages"
                :total-visible="12"
                circle
                @input="paginationChangeHandler"
              />
            </div>
          </v-container>
        </v-tab-item>
        <v-tab-item value="2">
          <v-container> <Attachments /> </v-container
        ></v-tab-item>
        <v-tab-item value="3">
          <v-container
            ><ImageTable :item="item" @update-list="updateImageList"
          /></v-container>
        </v-tab-item>
        <v-tab-item value="4">
          <v-container>
            <VendorProductList :editMode="editMode" :product="item" />
            <v-lazy>
              <ProductPOList />
            </v-lazy>
          </v-container>
        </v-tab-item>
        <v-tab-item value="5">
          <v-lazy>
            <Oms :id="item.sqlId" @link="linkProduct" />
          </v-lazy>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import config from '@/plugins/config';
import bus from '@/eventBus';
import ImageTable from '@/components/ImageTable.vue';
import Attachments from '@/components/Attachments.vue';
import VendorProductList from '@/components/VendorProductList.vue';
import ProductPOList from '@/components/Product/ProductPOList.vue';

import CategoryComboBox from '@/components/Product/CategoryComboBox.vue';
import Oms from '@/components/Product/OmsData.vue';
import OrderStatusIcon from '@/components/Order/OrderStatusIcon.vue';
import rule from '@/library/rule';
import { DateParser } from '@/library/utility';

export default {
  components: {
    ImageTable,
    Attachments,
    VendorProductList,
    ProductPOList,
    OrderStatusIcon,
    CategoryComboBox,
    Oms,
  },
  data: () => ({
    productStates: [
      { value: 0, name: '活跃' },
      { value: 1, name: '停产' },
      { value: 2, name: '特殊' },
    ],
    initializing: true,
    tab: '',
    editMode: false,
    saving: false,
    valid: false,
    loading: false,
    refreshing: false,
    file: null,
    rule,
    item: {
      _id: '',
      sku: '',
      name: '',
      upc: '',
      price: 0,
      length: 0,
      width: 0,
      height: 0,
      weight: 0,
      qtyPerCase: 0,
      enRoute: 0,
      instock: 0,
      openPO: 0,
      margin: 0,
      note: '',
      unit: 'EA',
      mainImage: '',
      images: [],
      customTax: 0,
      sqlId: -1,
      state: 0,
    },
    pagination: { totalDocs: 0, rowsPerPage: 20, page: 1 },
    headers: [
      { text: '单号', value: 'num' },
      { text: '集装箱序号', value: 'order.cid' },
      { text: '价格', value: 'price' },
      { text: '箱数', value: 'numCase' },
      { text: '出货日期', value: 'order.shipDate', sortable: false },
      { text: '进仓日期', value: 'order.receivedDate', sortable: false },
      { text: 'PO号码', value: 'poNum', sortable: false },
      { text: '运费/m', value: 'shippingRate', sortable: false },
      {
        text: '状态',
        value: 'status',
        sortable: false,
        align: 'center',
      },
    ],
    imageHeaders: [
      { text: '图片', value: 'src', sortable: false },
      { text: '', value: 'action', sortable: false },
    ],
    orderHistory: [],
    orderHistoryLoading: false,
  }),
  computed: {
    textInputBgColor() {
      return this.editMode ? config.colors.editedInputBgColor : 'white';
    },
    pages() {
      if (this.pagination.rowsPerPage == null || this.pagination.totalDocs == null) {
        return 0;
      }
      return Math.ceil(this.pagination.totalDocs / this.pagination.rowsPerPage);
    },
    showEditToggle() {
      return this.tab === '1' || this.tab === '4';
    },
    getVolume() {
      return ((this.item.length * this.item.width * this.item.height) / 1000000).toFixed(3);
    },
    getVolumeSF() {
      const v = (this.item.length * this.item.width * this.item.height) / 28317;
      return v.toFixed(3);
    },
    showAdditionalMenu() {
      if (!(this.tab === '5')) return false;
      if (!this.$user.data.isAdmin) return false;
      if (!this.item.sqlId) return false;
      if (this.item.sqlId <= 0) return false;
      return true;
    },
  },
  created() {
    const pageNumber = parseInt(this.$route.query.page, 10);
    this.editMode = this.$route.query.emode === 'true';
    this.tab = this.$route.query.tab;
    this.pagination.page = Number.isNaN(pageNumber) ? 0 : pageNumber;
    this.fetch();
    this.fetchOrderHistory();
    this.loading = false;
    this.initializing = false;
  },
  methods: {
    refresh() {
      this.refreshing = true;
      /* eslint-disable */
      window.confirm('确定要刷新?') && this.fetch();
      /* eslint-enable */
    },
    saveEdit() {
      this.saving = true;
      this.$http
        .put(`/product/${this.$route.params.id}`, this.item)
        .then((response) => {
          this.item = response.data;
          bus.$emit('success', '修改成功');
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        })
        .finally(() => {
          this.saving = false;
        });
    },
    fetch() {
      this.loading = true;
      this.$http
        .get(`/product/${this.$route.params.id}`)
        .then((response) => {
          this.item = response.data;
          if (this.refreshing) {
            bus.$emit('success', '成功刷新');
            this.refreshing = false;
          }
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        })
        .finally(() => {
          this.loading = false;
          document.title = `产品${this.item.sku} - ${this.item.name}`;
        });
    },
    fetchOrderHistory() {
      const limit = this.pagination.rowsPerPage;
      let offset = (this.pagination.page - 1) * limit;
      if (offset < 0) offset = 0;
      this.loading = true;
      this.$http
        .get(`/order/product/${this.$route.params.id}?limit=${limit}&offset=${offset}`)
        .then((response) => {
          const parsedResponse = response.data.docs.map((elm) => {
            const e = elm;
            e.order.shipDate = DateParser.ISO8601ToMMDDYY(e.order.shipDate);
            e.order.receivedDate = DateParser.ISO8601ToMMDDYY(e.order.receivedDate);
            return e;
          });
          this.orderHistory = parsedResponse;
          this.pagination.totalDocs = response.data.totalDocs;
        })
        .catch((error) => {
          bus.$emit('error', this.$error.getMsg(error));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    paginationChangeHandler(pageNumber) {
      if (this.pagination.page === pageNumber) return;
      this.pagination.page = pageNumber;
      this.$router.push(`/product/${this.$route.params.id}?page=${pageNumber}`);
      this.fetchOrderHistory();
    },
    editModeChange() {
      const path = `${this.$route.path}?emode=${this.editMode}`;
      this.$router.replace(path);
    },
    updateRoute() {
      if (this.initializing) return;
      const path = `${this.$route.path}?emode=${this.editMode}&tab=${this.tab}`;
      this.$router.replace(path).catch((err) => {});
    },
    updateImageList(productData) {
      this.item.mainImage = productData.mainImage;
      this.item.images = productData.images;
    },
    goToImageTab() {
      this.tab = '3';
    },
    unlinkProduct() {
      const mongoId = this.$route.params.id;
      this.$http
        .put(`/product/${mongoId}/unlink`)
        .then((response) => {
          this.item.sqlId = 0;
        })
        .catch((err) => {
          bus.$emit('error', this.$error.getMsg(err));
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    linkProduct(sqlId) {
      const mongoId = this.$route.params.id;
      this.$http
        .put(`/product/${mongoId}/link?sqlId=${sqlId}`)
        .then((response) => {
          this.item.sqlId = sqlId;
        })
        .catch((err) => {
          bus.$emit('error', this.$error.getMsg(err));
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  watch: {
    tab(val) {
      this.updateRoute();
    },
  },
};
</script>
