import Vue from 'vue';
import VueRouter from 'vue-router';
import UserStore from '@/store/UserStore';
import Login from '@/views/Login.vue';
import CategoryRoutes from '@/router/routes/Category';
import CompanyRoutes from '@/router/routes/Company';
import OrderRoutes from '@/router/routes/Order';
import ProductRoutes from '@/router/routes/Product';
import ShipmentRoutes from '@/router/routes/Shipment';
import UserRoutes from '@/router/routes/User';
import VendorRoutes from '@/router/routes/Vendor';
import PurchaseOrderRoutes from '@/router/routes/PurchaseOrder';
import UtilityRoutes from '@/router/routes/Utility';

import LazyLoad from '@/router/lazyload';

Vue.use(VueRouter);
const DEFAULT_TITLE = 'APP';

const routes = [
  {
    path: '/',
    name: 'home',
    component: LazyLoad('Home'),
    meta: { title: '主页' },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { title: '登录' },
  },

  {
    path: '/setting', name: 'Setting', component: LazyLoad('Setting'), meta: { title: '设置' },
  },
];

const paths = [CategoryRoutes, CompanyRoutes, OrderRoutes, ProductRoutes, ShipmentRoutes,
  UserRoutes, VendorRoutes, PurchaseOrderRoutes, UtilityRoutes];

function addToRoute(modules) {
  modules.forEach((module) => {
    module.forEach((e) => {
      routes.push(e);
    });
  });
}
addToRoute(paths);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.path !== '/login') {
    if (!UserStore.isAuthenticated()) {
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export default router;
